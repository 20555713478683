import MultiPress from "common/MultiPress.jsx";
import Styles from '../_.module.sass'

export default function LoanItem({ loan, showDelete, ...props }) {
    // Savings version

    const { name="", completed, total, metadata="", buckets } = loan;
    
    // const [ linkedbucket=null ] = buckets
    const linkedbucket = buckets && buckets.length 
        ? buckets.sort((a,b) => new Date(b.updated) - new Date(a.updated))[0]
        : null

    const progress = Math.round((completed / total) * 100)

    // if(metadata && metadata.includes('is-envelope-challenge')){
        // console.log(buckets.map(a => {
        //     return {
        //         amount: a.total,
        //         created: moment(a.created).format('hh:mm'),
        //         updated: moment(a.updated).format('hh:mm')
        //     }
        // }))
        // console.log(linkedbucket.total)
    // }

    const days_paid = linkedbucket && linkedbucket.history ? linkedbucket.history.length : null
    const days_paid_element = days_paid!==null ? (<p>{days_paid} Day{days_paid !== 1 ? "s" : ""} Completed</p>) : <></>

    return (
        <MultiPress
            className={`${Styles.loan}${progress >= 100 ? ' '+Styles.completed : ''}`}
            to={linkedbucket ? "/bucket/" + linkedbucket.id : null}
            onLongPress={showDelete}
        >
            <h1>{ name }</h1>
            {
                metadata.includes("is-envelope-challenge") 
                    ? days_paid_element
                    : <p>{progress}% Completed</p>
            }
            <h2>${parseFloat(completed).toFixed(2).toLocaleString()} Saved</h2>
        </MultiPress>
    )
}
