import { useContext, useState } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { LoanContext } from '../ViewLoan.jsx'
import { faLandmark, faPercent, faDollarSign } from '@fortawesome/free-solid-svg-icons'

import NumbersOnlyInput from "common/NumbersOnlyInput"

import { AmoritizationCalculator } from 'utility/tools.js'

function listenForKeyDown({ key, target }){
    if(['Enter', 'Esc', 'Escape'].includes(key)){ target.blur() }
}

export default function LoanSettings({ closeSettings, ...props }){

    const [loan, setLoan] = useContext(LoanContext)
    const [state, setState] = useState({ ...loan })

    console.log({
        start_months: loan.start_months,
        state        
    })

    const { id } = loan;

    const regular_payment = loan.Nexium.getMonthlyPayment()

    function save(){
        console.log("Saving Loan Data...")
        console.log(state)

        let { loan_owner, created, updated, name, total, interest, term, term_display, start_months, start_years, paymentHistory } = state

        total = parseFloat(total)
        // const { completed } = this

        const Nexium = new AmoritizationCalculator(state);
        const monthly_payment = Nexium.getMonthlyPayment()

        console.log({ monthly_payment })

        setLoan({
            ...loan,
            Nexium,
            id,
            loan_owner,
            created,
            updated,
            name,
            total,
            // completed,
            calculator_data: {
                input: monthly_payment
            },
            interest,
            term,
            term_display,
            start_months,
            start_years,
            paymentHistory,
        })
    }

    return (
        <section>
            <div className="fixedEditColumn mx-auto">

                <div className="block">
                    <button
                        className="delete is-large"
                        onClick={() => { save(); closeSettings() }}
                    ></button>
                    {/* <button className="button is-danger is-outlined" type="button" onClick={() => { setState({ page:"delete" }); }}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </span>
                        <span>Delete Loan</span>
                    </button> */}
                </div>

                <div className="field">
                    <label className="label has-text-centered">Loan Name</label>
                    <div className="control has-icons-left">
                        <input
                            value={state.name}
                            className="input is-primary"
                            type="text"
                            placeholder="Loan Name"
                            onKeyDown={listenForKeyDown}
                            onChange={({ target:{ value } }) => {
                                setState({ ...state, name: value })
                            }}
                            onBlur={() => {
                                save()
                            }}
                        />
                        <span className="icon is-left">
                            <FontAwesomeIcon icon={faLandmark} />
                        </span>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <label className="label has-text-centered has-text-left-mobile">Yearly Interest Rate</label>
                            <p className="control is-expanded has-icons-left">
                                <NumbersOnlyInput
                                    className="input is-primary"
                                    placeholder="Yearly Interest Rate"
                                    value={state.interest}
                                    onKeyDown={(value, key, event) => {
                                        if (key === "Enter" || key === "Escape") {
                                            event.target.blur()
                                        }
                                    }}
                                    onChange={(value) => {
                                        setState({ ...state, interest: value })
                                    }}
                                    onBlur={(e) => {
                                        let input = regular_payment
                                        setState(
                                            {
                                                ...state,
                                                calculator_data: {
                                                    ...state.calculator_data,
                                                    tens: 0,
                                                    calc: 0,
                                                    input,
                                                },
                                            }
                                        )
                                        save()
                                    }}
                                    maxLength={64}
                                />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faPercent} />
                                </span>
                            </p>
                        </div>
                        <div className="field">
                            <label className="label has-text-centered has-text-left-mobile">Goal Total</label>
                            <div className="control is-expanded has-icons-left">
                                <NumbersOnlyInput
                                    // autoFocus={true}
                                    value={state.total}
                                    onChange={(value) => {
                                        setState({ ...state, total: value })
                                    }}
                                    placeholder="Loan Total"
                                    onKeyDown={(value, key, event) => {
                                        if (key === "Enter" || key === "Escape") {
                                            event.target.blur()
                                        }
                                    }}
                                    onBlur={() => {
                                        let input = regular_payment
                                        setState(
                                            {
                                                ...state,
                                                calculator_data: {
                                                    ...state.calculator_data,
                                                    tens: 0,
                                                    calc: 0,
                                                    input,
                                                },
                                            }
                                        )
                                        save()
                                    }}
                                />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="columns is-gapless mb-2 is-hidden-mobile">
                    <div className="column">
                        <label className="label has-text-centered">Loan Term</label>
                    </div>
                    <div className="column">
                        <label className="label has-text-centered">Loan Started</label>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-body">
                        {/* Loan Term Inputs */}
                        <label className="label has-text-centered is-hidden-tablet">Loan Term</label>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <NumbersOnlyInput
                                    placeholder="Term"
                                    value={state.term}
                                    onKeyDown={(e, key, event) => {
                                        if (["Enter", "Escape"].includes(key)) {
                                            event.target.blur()
                                        }
                                    }}
                                    onChange={(value) => {
                                        setState({ ...state, term: value })
                                    }}
                                    onBlur={() => {
                                        save()
                                    }}
                                />
                            </div>
                            <div className="control">
                                <div className="select is-primary">
                                    <select
                                        value={state.term_display}
                                        onChange={({ target }) => {
                                            setState({ ...state, term_display: target.value })
                                            save()
                                        }}
                                    >
                                        <option value="years">Years</option>
                                        <option value="months">Months</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Loan Started Inputs */}
                        <label className="label has-text-centered is-hidden-tablet">Loan Started</label>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <NumbersOnlyInput
                                    placeholder="Term"
                                    value={state.start_years}
                                    onKeyDown={(e, key, event) => {
                                        if (["Enter", "Escape"].includes(key)) {
                                            event.target.blur()
                                            save()
                                        }
                                    }}
                                    onChange={(value) => {
                                        setState({ ...state, start_years: value })
                                    }}
                                    onBlur={() => {
                                        save()
                                    }}
                                />
                            </div>
                            <div className="control">
                                <div className="select is-primary">
                                    <select
                                        value={state.start_months}
                                        onChange={({ target }) => {
                                            setState({ ...state, start_months: target.value })
                                            save()
                                        }}
                                    >
                                        <option value="january"> January </option>
                                        <option value="february"> February </option>
                                        <option value="march"> March </option>
                                        <option value="april"> April </option>
                                        <option value="may"> May </option>
                                        <option value="june"> June </option>
                                        <option value="july"> July </option>
                                        <option value="august"> August </option>
                                        <option value="september"> September </option>
                                        <option value="october"> October </option>
                                        <option value="november"> November </option>
                                        <option value="december"> December </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}