import { useContext, useState } from 'react';
import { LoanContext } from "../ViewLoan"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';

// import DatePicker from "react-datepicker";
import moment from 'moment'

import Styles from '../_.module.sass'
// import { useRef } from 'react';

export default function PayoffEarlyChallenge({ ...props }){

    const [loan] = useContext(LoanContext)
    const { payoff_challenge=null } = loan;
    // const [showModal, setShowModal] = useState()

    // The user might not have opted in for the payoff challenge!
    if(!payoff_challenge){
        return <></>
    }

    let add = Math.floor(moment.duration(moment().diff(payoff_challenge)).asMonths()) + 1
    if(add < 0){ add = 0 }
    let total = ( (add * add) + add) / 2 // https://math.stackexchange.com/a/593320

    return (
        <div className="container is-max-desktop">
        <label className="label has-text-centered">Pay Off Your House Early Challenge</label>
            <div className="block">
                <h1 className="title has-text-centered is-size-2 is-size-4-mobile">Add To Your Next Payment: <span className="has-text-primary">${add}</span></h1>
            </div>
            {/* <div className="block box is-clickable" onClick={() => setShowModal(true)}> */}
            <div className="block box">
                <div className="block columns has-text-centered">
                    <div className="column">
                        <h3 className="title is-size-2">{ add }</h3>
                        <h2 className="subtitle">Months Active</h2>
                    </div>
                    <div className="column">
                        <h3 className="title is-size-2">${total}</h3>
                        <h2 className="subtitle">Amount Extra Paid</h2>
                    </div>
                    <div className="column">
                        <h3 className="title is-size-2">--</h3>
                        <h2 className="subtitle">Interest Saved</h2>
                    </div>
                </div>
            </div>
            {/* { showModal
                ? <DatePickerModal finish={(date) => {
                    if(date){
                        setLoan({ ...loan, payoff_challenge: date.toISOString() })
                    }
                    setShowModal(false);
                }}/>
                : <></>
            } */}
        </div>
    )

}

export function PayoffEarlyChallengePrompt({ setPage, ...props }){

    const [loan] = useContext(LoanContext)

    // This challenge is only available for those with a Home Loan!
    if(loan.type !== 'Home loan' || loan.payoff_challenge){
        return <></>
    }

    return <div className="container is-max-desktop">
        <div className="notification is-primary level">
            <p>Want to join the challenge to save more on your home loan, by just adding a $1 per month?</p>
            <button className="button is-white" type="button" onClick={setPage}>
                <span className="icon">
                    <FontAwesomeIcon icon={faPlus}/>
                </span>
                <span>Join The Challenge!</span>
            </button>
        </div>
    </div>
}

export function PayoffEarlyChallengeStart({ finish, ...props }){

    const [loan, setLoan] = useContext(LoanContext)
    // const [showModal, setShowModal] = useState()
    const [showVerify, setShowVerify] = useState(false)

    function startNow(){
        setLoan({ ...loan, payoff_challenge: new Date().toISOString() })
        finish()
    }

    function startAtLoanStart(){
        // setShowModal(true)
        const date = getLoanStart()

        setLoan({ ...loan, payoff_challenge: date.toISOString() })

        finish()
    }

    function getLoanStart(){
        let {
            start_months:month=moment().format('MMMM'),
            start_years:year=moment().format('YYYY')
        } = loan

        month = month.toLowerCase()

        const monthMap = { 
            'january'  : 0,
            'february' : 1,
            'march'    : 2,
            'april'    : 3,
            'may'      : 4,
            'june'     : 5,
            'july'     : 6,
            'august'   : 7,
            'september': 8,
            'october'  : 9,
            'november' : 10,
            'december' : 11,
        }
        
        const date = moment({
            day: 1,
            month: monthMap[month],
            year: parseInt(year)
        })

        return date
    }

    if(showVerify){

        let add = Math.floor(moment.duration(moment().diff(getLoanStart())).asMonths()) + 1
        if(add < 0){ add = 0 }
        let total = ( (add * add) + add) / 2 // https://math.stackexchange.com/a/593320

        return <div className={"container mt-6 " + Styles.miniContainer}>
            <h1 className="title is-size-2 is-size-6-mobile has-text-centered">You need to add ${total} to your loan to catch up.</h1>
            <div className="block buttons is-centered">
                <button className="button is-light is-fullwidth" type="button" onClick={() => { setShowVerify(false) }}>
                    <span>Nevermind</span>
                </button>
                <button className="button is-primary is-fullwidth" type="button" onClick={startAtLoanStart}>
                    <span>Continue</span>
                </button>
            </div>
        </div>
    }

    return <div className="container is-max-desktop">
        <button className="block button is-primary is-outlined" type="button" onClick={finish}>
            <span className="icon">
                <FontAwesomeIcon icon={faArrowLeft}/>
            </span>
            <span>Go Back</span>
        </button>
        <div className="block columns">
            <div className="column">
                <div className={"box " + Styles.PayoffEarlyChallengeBigBox} onClick={startNow}>
                    <h1 className="title">Start today</h1>
                </div>
            </div>
            <div className="column">
                <div className={"box " + Styles.PayoffEarlyChallengeBigBox} onClick={() => { setShowVerify(true) }}>
                    <h1 className="title">Catch Up</h1>
                </div>
            </div>
        </div>
        {/* {showModal
            ? <DatePickerModal finish={(date) => {
                setShowModal(false);
                if(date){
                    setLoan({ ...loan, payoff_challenge: date.toISOString() }); finish();
                }
            }}/>
            : <></>
        } */}
    </div>
}

// function DatePickerModal({ finish }){

//     const pickerRef = useRef()
//     const [startDate, setStartDate] = useState(new Date())

//     return <div className="modal is-active">
//         <div className="modal-background" onClick={() => { finish(null) }}></div>
//         <div className="modal-card">
//             <header className="modal-card-head">
//                 <p className="modal-card-title">When did you want to start the challenge?</p>
//                 <button className="delete is-large" onClick={() => { finish(null) }}></button>
//             </header>
//             <section className="modal-card-body" style={{ minHeight: '320px' }}>
//                 <DatePicker
//                     ref={pickerRef}
//                     autoFocus 
//                     dateFormat="MMMM do yyyy" 
//                     className="input" 
//                     maxDate={new Date()} 
//                     selected={startDate}
//                     onChange={(date) => setStartDate(date)}
//                     open={true}
//                     monthsShown={2}
//                 />
//             </section>
//             <footer className="modal-card-foot buttons is-right">
//                 <button className="button" type="button" onClick={() => { finish(null) }}>
//                     <span>Cancel</span>
//                 </button>
//                 <button className="button is-primary" type="button" onClick={() => finish(startDate)}>
//                     <span>Select</span>
//                 </button>
//             </footer>
//         </div>
//     </div>
// }