

import React from 'react'

export default class LoginForm extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            hidden:this.props.hidden
        }
        this.onHide = this.onHide.bind(this);
    }

    onHide(){
        // I can't get the delete button to work :(
        this.setState({
            hidden:true
        })
    }

    render(){
        return <div className={'notification is-' + (this.props.color || 'black') + (this.props.hidden?' is-hidden':'')}>
            <button className="delete is-hidden"></button>
            <span>{this.props.children}</span>
        </div>
    }
}