
export default function BucketSVGComplex({ completion:step, number, milestones, onClick, name }) {
    
    step = Math.floor(step / 10)

    if (step > 10) {
        step = 10
    }

    // Manual override
    // step = 5

    return (
        <div className={"fillableBucket" + (step >= 10 ? " is-filled" : "")}>
            {!milestones.length ? <h1 className="title is-size-2 is-size-3-mobile has-text-centered has-text-info is-hidden-touch">{name}</h1> : <></>}
            <div className={"bucket-render-5 is-hidden-touch step-" + step}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1250.5 672.6">
                    <defs>
                        <linearGradient id="linear-gradient" x1="315.27" y1="160.35" x2="315.27" y2="13.98" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor={step >= 10 ? "#f4a23f" : "#049cbe"} />
                            <stop offset="1" stopColor={step >= 10 ? "#f5c269" : "#53c4cc"} />
                        </linearGradient>
                        <linearGradient id="linear-gradient-2" x1="314.3" y1="657.64" x2="314.3" y2="94.67" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-3" x1="315.42" y1="151.91" x2="315.42" y2="68.18" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-4" x1="314.25" y1="656.64" x2="314.25" y2="160.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-5" x1="314.76" y1="154.48" x2="314.76" y2="113.7" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-6" x1="313.75" y1="657.64" x2="313.75" y2="209.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-7" x1="313.75" y1="657.64" x2="313.75" y2="256.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-8" x1="313.75" y1="657.64" x2="313.75" y2="305.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-9" x1="314.25" y1="657.64" x2="314.25" y2="353.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-10" x1="314.25" y1="657.64" x2="314.25" y2="401.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-11" x1="314.25" y1="657.64" x2="314.25" y2="448.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-12" x1="314.25" y1="657.64" x2="314.25" y2="498.15" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-13" x1="314.25" y1="657.64" x2="314.25" y2="541.66" xlinkHref="#linear-gradient" />
                    </defs>
                    <ellipse onClick={onClick} className="bucket-invisible-fill" cx="315.27" cy="87.17" rx="259.32" ry="73.19" />
                    <path onClick={onClick} className="bucket-invisible-fill" d="M574.59,98.43l-52.8,496.48-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33L54,94.67c9.41,30.43,148.56,57.48,260.3,57.48s250.37-23.42,260.3-53.72Z" />
                    <g id="group-10">
                        <line id="Line" className="cls-4 is-line" x1="569.21" y1="122.19" x2="1249.24" y2="122.19" />
                        <line id="Line_Top" className="cls-4 is-line" x1="587.67" y1="74.24" x2="1249.24" y2="74.24" />
                        <text id="Value" className="cls-8 is-line" transform="translate(756.14 112.7)">
                            <tspan x="0" y="0">
                                {milestones[9] ? milestones[9].step : ""}
                            </tspan>
                        </text>
                        <text id="Date" className="cls-8 is-line" transform="translate(1250 112.70)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[9] ? milestones[9].date : ""}
                            </tspan>
                        </text>
                        <ellipse onClick={onClick} className="cls-6 bucket-fill" cx="315.27" cy="87.17" rx="259.32" ry="73.19" />
                        <path onClick={onClick} className="cls-13 bucket-fill" d="M574.59,98.43l-52.8,496.48-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33L54,94.67c9.41,30.43,148.56,57.48,260.3,57.48s250.37-23.42,260.3-53.72Z" />
                        <text className="cls-22" transform="translate(236.52 204.95)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-9">
                        <line id="Line-2" className="cls-4 is-line" x1="569.21" y1="170.14" x2="1249.24" y2="170.14" />
                        <text id="Value-2" className="cls-8 is-line" transform="translate(756.14 160.65)">
                            <tspan x="0" y="0">
                                {milestones[8] ? milestones[8].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-2" className="cls-8 is-line" transform="translate(1250 160.65)">
                            <tspan className="cls-1" x="0" y="0" textAnchor="end">
                                {milestones[8] ? milestones[8].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-11 bucket-fill" d="M582.92,117.35c-39.32-28.66-144.27-49.16-267.56-49.16S87.36,88.63,47.93,117.25l22.47-5.61,235.77,40.27,159.07-12.29,100.81-26.56,16.88,4.29Z" />
                        <path onClick={onClick} className="cls-12 bucket-fill" d="M566.04,160.46l-44.24,433.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33L62.46,160.15c9.41,30.43,140.11,57.48,251.84,57.48s241.81-26.87,251.74-57.17Z" />
                        <text className="cls-22" transform="translate(236.52 253.79)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-8">
                        <line id="Line-3" className="cls-4 is-line" x1="564.63" y1="218.06" x2="1249.24" y2="218.06" />
                        <text id="Value-3" className="cls-8 is-line" transform="translate(756.14 208.54)">
                            <tspan x="0" y="0">
                                {milestones[7] ? milestones[7].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-3" className="cls-8 is-line" transform="translate(1250 208.54)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[7] ? milestones[7].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-16 bucket-fill" d="M115.8,135.99l150,18.5,92.09-2,95.5-7.24,60.33-9.57c-50.15-13.55-120.49-21.98-198.37-21.98-78.48-.01-149.32,8.55-199.55,22.29" />
                        <path onClick={onClick} className="cls-14 bucket-fill" d="M561.04,209.46l-39.24,385.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33L66.46,209.15c9.41,30.43,136.11,57.48,247.84,57.48s236.81-26.87,246.74-57.17Z" />
                        <text className="cls-22" transform="translate(236.52 301.46)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-7">
                        <line id="Line-4" className="cls-4 is-line" x1="563.04" y1="265.97" x2="1249.24" y2="265.97" />
                        <text id="Value-4" className="cls-8 is-line" transform="translate(756.14 256.48)">
                            <tspan x="0" y="0">
                                {milestones[6] ? milestones[6].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-4" className="cls-8 is-line" transform="translate(1250 256.48)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[6] ? milestones[6].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-15 bucket-fill" d="M557.04,256.46l-35.24,338.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33-36.34-347.15c9.41,30.43,132.11,57.48,243.84,57.48s232.81-26.87,242.74-57.17Z" />
                        <text className="cls-22" transform="translate(236.52 350.13)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-6">
                        <line id="Line-5" className="cls-4 is-line" x1="557.14" y1="313.89" x2="1249.24" y2="313.89" />
                        <text id="Value-5" className="cls-8 is-line" transform="translate(756.14 304.66)">
                            <tspan x="0" y="0">
                                {milestones[5] ? milestones[5].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-5" className="cls-8 is-line" transform="translate(1250 304.66)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[5] ? milestones[5].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-9 bucket-fill" d="M552.04,305.46l-30.24,289.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33-31.34-298.15c9.41,30.43,127.11,57.48,238.84,57.48s227.81-26.87,237.74-57.17Z" />
                        <text className="cls-22" transform="translate(236.52 397.79)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-5">
                        <line id="Line-6" className="cls-4 is-line" x1="553.23" y1="361.8" x2="1249.24" y2="361.8" />
                        <text id="Value-6" className="cls-8 is-line" transform="translate(756.14 352.67)">
                            <tspan x="0" y="0">
                                {milestones[4] ? milestones[4].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-6" className="cls-8 is-line" transform="translate(1250 352.67)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[4] ? milestones[4].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-10 bucket-fill" d="M547.04,353.46l-25.24,241.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33-25.34-250.15c9.41,30.43,121.11,57.48,232.84,57.48s222.81-26.87,232.74-57.17Z" />
                        <text className="cls-22" transform="translate(236.52 446.18)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-4">
                        <line id="Line-7" className="cls-4 is-line" x1="547.27" y1="409.71" x2="1249.24" y2="409.71" />
                        <text id="Value-7" className="cls-8 is-line" transform="translate(756.14 400.57)">
                            <tspan x="0" y="0">
                                {milestones[3] ? milestones[3].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-7" className="cls-8 is-line" transform="translate(1250 400.57)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[3] ? milestones[3].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-19 bucket-fill" d="M544.04,401.46l-22.24,193.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33-22.34-202.15c9.41,30.43,118.11,57.48,229.84,57.48s219.81-26.87,229.74-57.17Z" />
                        <text className="cls-22" transform="translate(236.52 494.57)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-3">
                        <line id="Line-8" className="cls-4 is-line" x1="547.56" y1="457.63" x2="1249.24" y2="457.63" />
                        <text id="Value-8" className="cls-8 is-line" transform="translate(756.14 448.5)">
                            <tspan x="0" y="0">
                                {milestones[2] ? milestones[2].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-8" className="cls-8 is-line" transform="translate(1250 448.50)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[2] ? milestones[2].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-20 bucket-fill" d="M539.04,448.46l-17.24,146.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33-17.34-155.15c9.41,30.43,113.11,57.48,224.84,57.48s214.81-26.87,224.74-57.17Z" />
                        <text className="cls-22" transform="translate(236.52 541.96)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-2">
                        <line id="Line-9" className="cls-4 is-line" x1="540.16" y1="505.54" x2="1249.24" y2="505.54" />
                        <text id="Value-9" className="cls-8 is-line" transform="translate(756.14 496.69)">
                            <tspan x="0" y="0">
                                {milestones[1] ? milestones[1].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-9" className="cls-8 is-line" transform="translate(1250 496.69)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[1] ? milestones[1].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-18 bucket-fill" d="M527.04,498.46l-5.24,96.45-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33-5.34-105.15c9.41,30.43,101.11,56.48,212.84,56.48s202.81-25.87,212.74-56.17Z" />
                        <text className="cls-22" transform="translate(236.52 590.35)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="group-1">
                        <line id="Line-10" className="cls-4 is-line" x1="529.6" y1="553.46" x2="1249.24" y2="553.46" />
                        <text id="Value-10" className="cls-8 is-line" transform="translate(756.14 543.96)">
                            <tspan x="0" y="0">
                                {milestones[0] ? milestones[0].step : ""}
                            </tspan>
                        </text>
                        <text id="Date-10" className="cls-8 is-line" transform="translate(1250 543.96)">
                            <tspan x="0" y="0" textAnchor="end">
                                {milestones[0] ? milestones[0].date : ""}
                            </tspan>
                        </text>
                        <path onClick={onClick} className="cls-17 bucket-fill" d="M527.04,541.97l-5.24,52.95-50.31,44-157.19,18.73-153.71-16.02-53.79-38.33-5.34-61.64c9.41,30.43,101.11,62.48,212.84,62.48s202.81-31.87,212.74-62.17Z" />
                        <text className="cls-22" transform="translate(236.52 636.9)" textAnchor="middle" alignmentBaseline="middle">
                            <tspan x="0" y="0" alignmentBaseline="middle">
                                <tspan x="75" y="-10" alignmentBaseline="middle">
                                    $
                                </tspan>
                                {number}
                            </tspan>
                        </text>
                    </g>
                    <g id="fbb-rungs" onClick={onClick}>
                        <path className="cls-5" d="M46.13,141.12c0,42.13,120.07,76.29,268.17,76.29s268.17-34.16,268.17-76.29" />
                        <path className="cls-5" d="M54,191.39c0,40.9,116.54,74.05,260.3,74.05,143.76,0,260.3-33.15,260.3-74.05" />
                        <path className="cls-5" d="M64.33,242.01c0,39.27,111.92,71.11,249.97,71.11,138.06,0,249.97-31.84,249.97-71.11" />
                        <path className="cls-5" d="M67.97,291.67c0,38.7,110.29,70.08,246.33,70.08,136.04,0,246.33-31.37,246.33-70.08" />
                        <path className="cls-5" d="M75.25,341.86c0,37.56,107.03,68.01,239.05,68.01,132.02,0,239.05-30.45,239.05-68.01" />
                        <path className="cls-5" d="M81.89,391.95c0,36.52,104.05,66.12,232.41,66.12,128.35,0,232.41-29.6,232.41-66.12" />
                        <path className="cls-5" d="M89.81,442.23c0,35.27,100.51,63.86,224.49,63.86,123.98,0,224.49-28.59,224.49-63.86" />
                        <path className="cls-5" d="M100.48,492.9c0,33.59,95.73,60.83,213.81,60.83,118.09,0,213.81-27.23,213.81-60.83" />
                        <path className="cls-5" d="M106.8,541.62c0,32.6,92.9,61.68,207.5,61.68s207.5-29.07,207.5-61.68" />
                    </g>
                    <g id="fbb-outline" onClick={onClick}>
                        <ellipse className="cls-2" cx="313.26" cy="83.44" rx="267.58" ry="69.46" />
                        <path className="cls-3" d="M26.34,186.53s-59.89,84.43,41.28,158.77" />
                        <path className="cls-3" d="M598.98,175.51s59.89,84.43-41.28,158.77" />
                        <path className="cls-2" d="M45.67,83.44l50.12,468.92c5.33,48.17,39.13,86.66,82.87,94.4l.61,.11c88.75,15.69,179.22,15.66,267.96-.09h0c43.66-7.75,77.4-46.15,82.79-94.22l50.82-469.12" />
                        <path className="cls-7" d="M37.87,145.55l-9.11,1.12c-5.88,.72-10.05,6.66-9.21,13.12l7.02,54.19c.8,6.18,5.9,10.54,11.53,9.85l9.11-1.12c5.88-.72,10.05-6.66,9.21-13.12l-7.02-54.19c-.8-6.18-5.9-10.54-11.53-9.85Z" />
                        <path className="cls-7" d="M577.89,215.2l9.06,1.51c5.85,.98,11.27-3.58,11.97-10.06l5.89-54.35c.67-6.2-3.24-11.87-8.83-12.8l-9.06-1.51c-5.85-.98-11.27,3.58-11.97,10.06l-5.89,54.35c-.67,6.2,3.24,11.87,8.83,12.8Z" />
                    </g>
                    <text id="Bucket_Name" className="bucket-name" transform="translate(895 628)" textAnchor="middle">
                        <tspan x="0" y="0">
                            {name}
                        </tspan>
                    </text>
                </svg>
            </div>
            <div className="bucket-render-6 is-hidden-desktop" onClick={onClick}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 625.3 672.6" style={{ enableBackground: "new 0 0 625.3 672.6" }}>
                    <defs>
                        <linearGradient id="br6-linear-gradient" x1="315.27" y1="160.35" x2="315.27" y2="13.98" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor={step >= 10 ? "#f4a23f" : "#049cbe"} />
                            <stop offset="1" stopColor={step >= 10 ? "#f5c269" : "#53c4cc"} />
                        </linearGradient>
                    </defs>
                    <g className={"br6-fillgroup " + (step === 10 ? "" : "is-hidden")} id="br6-group-10">
                        <ellipse className="br-6-cls-0" cx="315.3" cy="87.2" rx="259.3" ry="73.2" />
                        <path d="M574.6,98.4l-52.8,496.5l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L54,94.7c9.4,30.4,148.6,57.5,260.3,57.5S564.7,128.7,574.6,98.4L574.6,98.4z" />
                        <text transform="matrix(1 0 0 1 236.5159 204.95)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 9 ? "" : "is-hidden")} id="br6-group-9">
                        <path d="M582.9,117.3 c-39.3-28.7-144.3-49.2-267.6-49.2s-228,20.4-267.4,49.1l22.5-5.6l235.8,40.3l159.1-12.3L566,113.1L582.9,117.3L582.9,117.3z" />
                        <path d="M566,160.5l-44.2,433.5l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L62.5,160.1c9.4,30.4,140.1,57.5,251.8,57.5S556.1,190.8,566,160.5L566,160.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 253.79)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 8 ? "" : "is-hidden")} id="br6-group-8">
                        <path d="M115.8,136l150,18.5l92.1-2l95.5-7.2 l60.3-9.6c-50.1-13.6-120.5-22-198.4-22C236.9,113.7,166,122.2,115.8,136" />
                        <path d="M561,209.5l-39.2,385.5l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L66.5,209.1c9.4,30.4,136.1,57.5,247.8,57.5S551.1,239.8,561,209.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 301.46)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 7 ? "" : "is-hidden")} id="br6-group-7">
                        <path d="M557,256.5l-35.2,338.5l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L70.5,256.1c9.4,30.4,132.1,57.5,243.8,57.5S547.1,286.8,557,256.5L557,256.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 350.13)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 6 ? "" : "is-hidden")} id="br6-group-6">
                        <path d="M552,305.5l-30.2,289.5l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L75.5,305.1c9.4,30.4,127.1,57.5,238.8,57.5S542.1,335.8,552,305.5L552,305.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 397.79)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 5 ? "" : "is-hidden")} id="br6-group-5">
                        <path d="M547,353.5l-25.2,241.4l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L81.5,353.1c9.4,30.4,121.1,57.5,232.8,57.5S537.1,383.7,547,353.5L547,353.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 446.18)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 4 ? "" : "is-hidden")} id="br6-group-4">
                        <path d="M544,401.5l-22.2,193.4l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L84.5,401.1c9.4,30.4,118.1,57.5,229.8,57.5S534.1,431.7,544,401.5L544,401.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 494.57)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 3 ? "" : "is-hidden")} id="br6-group-3">
                        <path d="M539,448.5l-17.2,146.4l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3L89.5,448.1c9.4,30.4,113.1,57.5,224.8,57.5S529.1,478.7,539,448.5L539,448.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 541.96)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 2 ? "" : "is-hidden")} id="br6-group-2">
                        <path d="M527,498.5l-5.2,96.4l-50.3,44 l-157.2,18.7l-153.7-16l-53.8-38.3l-5.3-105.1c9.4,30.4,101.1,56.5,212.8,56.5S517.1,528.7,527,498.5L527,498.5z" />
                        <text transform="matrix(1 0 0 1 236.5159 590.35)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g className={"br6-fillgroup " + (step === 1 ? "" : "is-hidden")} id="br6-group-1">
                        <path d="M527,542l-5.2,53l-50.3,44l-157.2,18.7 l-153.7-16l-53.8-38.3l-5.3-61.6c9.4,30.4,101.1,62.5,212.8,62.5S517.1,572.3,527,542L527,542z" />
                        <text transform="matrix(1 0 0 1 236.5159 636.9)" className="br-6-cls-2 br-6-cls-3 br-6-cls-4">
                            $123,45
                        </text>
                    </g>
                    <g id="fbb-rungs">
                        <path className="br-6-cls-16" d="M46.1,141.1c0,42.1,120.1,76.3,268.2,76.3s268.2-34.2,268.2-76.3" />
                        <path className="br-6-cls-16" d="M54,191.4c0,40.9,116.5,74.1,260.3,74.1s260.3-33.1,260.3-74.1" />
                        <path className="br-6-cls-16" d="M64.3,242c0,39.3,111.9,71.1,250,71.1c138.1,0,250-31.8,250-71.1" />
                        <path className="br-6-cls-16" d="M68,291.7c0,38.7,110.3,70.1,246.3,70.1s246.3-31.4,246.3-70.1" />
                        <path className="br-6-cls-16" d="M75.2,341.9c0,37.6,107,68,239,68s239-30.5,239-68" />
                        <path className="br-6-cls-16" d="M81.9,392c0,36.5,104,66.1,232.4,66.1c128.4,0,232.4-29.6,232.4-66.1" />
                        <path className="br-6-cls-16" d="M89.8,442.2c0,35.3,100.5,63.9,224.5,63.9s224.5-28.6,224.5-63.9" />
                        <path className="br-6-cls-16" d="M100.5,492.9c0,33.6,95.7,60.8,213.8,60.8c118.1,0,213.8-27.2,213.8-60.8" />
                        <path className="br-6-cls-16" d="M106.8,541.6c0,32.6,92.9,61.7,207.5,61.7s207.5-29.1,207.5-61.7" />
                    </g>
                    <g id="fbb-outline">
                        <path className="br-6-cls-17" d="M26.3,186.5c0,0-59.9,84.4,41.3,158.8" />
                        <path className="br-6-cls-17" d="M599,175.5c0,0,59.9,84.4-41.3,158.8" />
                        <ellipse className="br-6-cls-18" cx="313.3" cy="83.4" rx="267.6" ry="69.5" />
                        <path className="br-6-cls-18" d="M45.7,83.4l50.1,468.9c5.3,48.2,39.1,86.7,82.9,94.4l0.6,0.1c88.8,15.7,179.2,15.7,268-0.1l0,0 c43.7-7.8,77.4-46.2,82.8-94.2l50.8-469.1" />
                        <path className="br-6-cls-19" d="M37.9,145.6l-9.1,1.1c-5.9,0.7-10,6.7-9.2,13.1l7,54.2c0.8,6.2,5.9,10.5,11.5,9.9l9.1-1.1 c5.9-0.7,10-6.7,9.2-13.1l-7-54.2C48.6,149.2,43.5,144.9,37.9,145.6z" />
                        <path className="br-6-cls-19" d="M577.9,215.2l9.1,1.5c5.8,1,11.3-3.6,12-10.1l5.9-54.4c0.7-6.2-3.2-11.9-8.8-12.8l-9.1-1.5 c-5.8-1-11.3,3.6-12,10.1l-5.9,54.4C568.4,208.6,572.3,214.3,577.9,215.2z" />
                    </g>
                </svg>
            </div>
        </div>
    )
}