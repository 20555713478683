import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { LoanContext } from '../ViewLoan.jsx'

import CoachPhil from 'common/CoachPhil.jsx';

import { ReactComponent as BucketButtonIcon } from "images/icons/bucket_for_button.svg"

import moment from 'moment'
import axios from 'axios'
import CalculatorX from 'common/CalculatorX.jsx';

function formatMoneyHelper(amount) {
    const formatted = parseFloat(
        parseFloat(
            amount
        ).toFixed(2)
    ).toLocaleString()

    // Sometimes the formatted value is still xxx.y not xxx.yy so we need to add an extra 0
    if (!formatted.includes('.')) {
        return `${formatted}.00`
    }
    if (formatted.split('.')[1].length === 1) {
        return `${formatted}0`
    }
    if (formatted.split('.')[1].length === 0) {
        return `${formatted}00`
    }
    return formatted
}

export default function CalculatorWithStats({ setCalculatorData, onStatClicked, ...props }) {
    
    const navigate = useNavigate()
    const [ loan ] = useContext(LoanContext)

    let {
        id,
        name,
        calculator_data,
        calculator_data: {
            input=0,
            calc=0,
            tens=0
        },
        Nexium
    } = loan;

    if(!input){
        input = Nexium.getMonthlyPayment()
        calculator_data = { input }
    }

    const [state, setState] = useState({
        input, calc, tens
    })

    const paymentHistory = loan ? Nexium.gatherPaymentHistory() : [],
        calculations = {
            default: Nexium.getDailySchedule("default", {
                onlyReturnLast: true,
            }),
            prediction: Nexium.getDailySchedule("future", {
                onlyReturnLast: true,
                extraPayments: [...paymentHistory, { date: new Date().toISOString(), value: state.calc || 1 }],
                forecast: { extra: state.calc || 1, interval: "monthly" },
            }),
        }

    let time_saved = calculations.default[0].running_totals.days_paid - calculations.prediction[0].running_totals.days_paid

    let interest_saved = Math.abs(calculations.default[0].running_totals.interest - calculations.prediction[0].running_totals.interest)

    // Ensure to float with no isNaN && no undefined values
    interest_saved = parseFloat((isNaN(interest_saved || 0) ? 0 : interest_saved) || 0).toFixed(2)

    // Ensure interest saved can't be higher than what the original loan's interest was
    if (interest_saved >= calculations.default[0].running_totals.interest) {
        interest_saved = calculations.default[0].running_totals.interest
    }

    const chosen_bucket =
        loan.linkedBuckets && loan.linkedBuckets.length
            ? loan.linkedBuckets.sort(function (a, b) {
                  //   return new Date(a.bucket_created) - new Date(b.bucket_created)
                  return parseFloat(a.bucket_completed) - parseFloat(b.bucket_completed)
              })[0] // Newest bucket first
            : null

    return (
        <div className="container for-calculator mb-6">
            <div className="columns">
                <div className="column is-two-thirds mx-auto">
                    <CalculatorX
                        value={calculator_data}
                        onChange={(values) => {
                            setState({ ...state, ...values })
                            setCalculatorData({ ...calculator_data, ...values })
                        }}
                    />
                    { state.input
                        ? <div className="block buttons is-centered">
                            <button className="button is-primary is-fullwidth pad-bottom m-0" type="button" onClick={() => {
                                console.log("Clicked!")
                                axios
                                    .post('/api/buckets/create', {
                                        name,
                                        total: state.input,
                                        completed: state.calc,
                                        history: [{ id:'0', value: state.calc, date: new Date().toISOString() }],
                                        calculator: {
                                            input:  state.input,
                                            calc:   state.calc,
                                            output: state.output,
                                            tens:   state.tens
                                        },
                                        linked_loan: id,
                                        metadata: '',
                                    })
                                    .then(({ data:bucket }) => {
                                        if(bucket && bucket.id){
                                            navigate(`/bucket/${bucket.id}`, { replace: false, state:bucket })
                                        }
                                    })
                            }}>
                                <span className="icon">
                                    <BucketButtonIcon />
                                </span>
                                <span>New Bucket</span>
                            </button>
                        </div>
                        : <></>
                    }
                    <CoachPhil
                        message={`By dropping just $${state.calc || 0} a month towards principal on your loan. You will pay off your loan faster by ${
                            // (time_saved ? moment.duration(time_saved, 'days').humanize().split(' ')[0] : 0) // Amount
                            time_saved ? Math.floor(moment.duration(time_saved, "days").asMonths()).toLocaleString() : 0 // Amount
                        } ${
                            // (time_saved ? moment.duration(time_saved, 'days').humanize().split(' ').pop() : 'Days') // Time humanized
                            Math.floor(moment.duration(time_saved, "days").asMonths()) === 1 ? "month" : "months"
                        } and you will save $${interest_saved} in interest.`}
                    />
                </div>
                <div className="column" style={{ marginTop: "30px" }}>
                    <div className="block box is-calculator-grey has-text-centered px-0 is-clickable" onClick={onStatClicked}>
                        <h1 className="title has-text-white">${formatMoneyHelper(state.calc || 0)}</h1>
                        <p className="subtitle has-text-white">Selected</p>
                    </div>
                    <div className="block box is-calculator-grey has-text-centered px-0 is-clickable" onClick={onStatClicked}>
                        <h1 className="title has-text-white">${formatMoneyHelper(interest_saved)}</h1>
                        <p className="subtitle has-text-white">Interest Saved</p>
                    </div>
                    <div className="block box is-calculator-grey has-text-centered px-0 is-clickable" onClick={onStatClicked}>
                        <h1 className="title has-text-white">
                            {
                                // time_saved ? moment.duration(time_saved, "days").humanize().split(" ")[0] : 0
                                time_saved ? Math.floor(moment.duration(time_saved, "days").asMonths()).toLocaleString() : 0 // Amount
                            }
                        </h1>
                        <p className="subtitle has-text-white is-capitalized">
                            {
                                // time_saved ? moment.duration(time_saved, "days").humanize().split(" ").pop() : "Days"
                                Math.floor(moment.duration(time_saved, "days").asMonths()) === 1 ? "month" : "months"
                            }{" "}
                            Saved
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}