import MultiPress from "common/MultiPress.jsx";
import Styles from '../_.module.sass'

import moment from 'moment'

export default function LoanItem({ loan, showDelete, ...props }) {

    // This collects all additional principal
    
    const { id, type, name="", regular_payment, active_since } = loan;

    let loan_supercompleted = loan?.buckets?.length
        ? loan.buckets.reduce((prev, curr) => {
                return prev + parseFloat(curr.completed)
            }, 0.0)
        : 0

    // This adds regular payments to it :)

    const months_active = Math.floor(active_since.asMonths())
    
    loan_supercompleted += months_active * regular_payment

    let payoff_challenge_offset = 0
    if(loan.payoff_challenge){
        let add = Math.floor(moment.duration(moment().diff(loan.payoff_challenge)).asMonths()) + 1
        if(add < 0){ add = 0 }
        payoff_challenge_offset = ( (add * add) + add) / 2 // https://math.stackexchange.com/a/593320
    }

    const progress  = Math.round((loan_supercompleted / loan.total) * 100)
    const principal = parseFloat(regular_payment + payoff_challenge_offset).toFixed(2)

    console.log({
        name,
        loan,
        active_since,
        payoff_challenge_offset,
        regular_payment,
        principal,
        progress,
        loan_supercompleted,
        type,
        isCompleted: progress >= 100,
    })

    return (
        <MultiPress
            className={`${Styles.loan}${progress >= 100 ? ' '+Styles.completed:''}`}
            to={"/loan/" + id}
            state={{
                id, data: loan,
            }}
            onLongPress={showDelete}
        >
            <Tile name={name} progress={progress} regular_payment={regular_payment} principal={principal} type={type} completed={progress >= 100}/>
        </MultiPress>
    )
}

function Tile({ name, progress, regular_payment, type, completed }){

    regular_payment = parseFloat( regular_payment ).toFixed(2)

    return (
        <>
            <div className={Styles.swirlyHolder}>
                <svg className={Styles.swirly} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 611.89 609.81">
                    <defs>
                        <linearGradient id="swirlyGradient" x1="305.94" y1="304.94" x2="305.94" y2="0" gradientUnits="userSpaceOnUse">
                            <stop offset=".08" stopColor="#2baae2" />
                            <stop offset=".74" stopColor="#010101" />
                        </linearGradient>
                    </defs>
                    <polygon points="305.94 304.94 305.94 304.94 281.15 0 330.74 0 305.94 304.94"           fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 183.47 24.58 230.37 8.48 305.94 304.94"    fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 99.06 79.55 138.19 49.09 305.94 304.94"    fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 37.08 158.95 64.19 117.43 305.94 304.94"   fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 4.24 254.17 16.41 206.1 305.94 304.94"     fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 4.11 354.9 0 305.48 305.94 304.94"         fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 36.69 450.21 16.76 404.8 305.94 304.94"    fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 98.46 529.77 64.86 493.3 305.94 304.94"    fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 182.72 584.97 139.1 561.38 305.94 304.94"  fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 280.33 609.81 231.42 601.66 305.94 304.94" fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 380.73 601.6 331.82 609.78 305.94 304.94"  fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 473.01 561.24 429.41 584.86 305.94 304.94" fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 547.19 493.09 513.62 529.59 305.94 304.94" fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 595.21 404.55 575.32 449.97 305.94 304.94" fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 611.89 305.21 607.82 354.63 305.94 304.94" fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 595.39 205.84 607.6 253.91 305.94 304.94"  fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 547.53 117.22 574.68 158.71 305.94 304.94" fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 473.47 48.94 512.63 79.37 305.94 304.94"   fill="url(#swirlyGradient)" />
                    <polygon points="305.94 304.94 305.94 304.94 381.26 8.41 428.17 24.47 305.94 304.94"    fill="url(#swirlyGradient)" />
                </svg>
            </div>
            <svg className={Styles.tile} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 586.32 677.08">
                <defs>
                    <radialGradient id="radial-gradient" cx="293.9" cy="624.58" fx="293.9" fy="624.58" r="59.58" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#0dbad7" />
                        <stop offset="1" stopColor="#099ec0" />
                    </radialGradient>
                    <radialGradient id="radial-gradient-2" cx="293.9" cy="624.71" fx="293.9" fy="624.71" r="31.6" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#fff" />
                        <stop offset="1" stopColor="#f2f2f2" />
                    </radialGradient>
                    <radialGradient id="radial-gradient-3" cx="294.73" cy="623.86" fx="294.73" fy="623.86" r="37.65" href="#radial-gradient-2" />
                    <linearGradient id="linear-gradient" x1="254.57" y1="470.58" x2="323.88" y2="470.58" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#fed900" />
                        <stop offset="1" stopColor="#ffc808" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="333.26" y1="453.33" x2="366.29" y2="453.33" href="#linear-gradient" />
                    <linearGradient id="linear-gradient-3" x1="371.77" y1="453.33" x2="404.8" y2="453.33" href="#linear-gradient" />
                    <linearGradient id="linear-gradient-4" x1="176.12" y1="453.33" x2="209.15" y2="453.33" href="#linear-gradient" />
                    <linearGradient id="linear-gradient-5" x1="214.63" y1="453.33" x2="247.67" y2="453.33" href="#linear-gradient" />
                    <linearGradient id="linear-gradient-6" x1="231.67" y1="27.65" x2="350.37" y2="146.35" href="#linear-gradient" />
                </defs>
                <rect id="Border" x="2.5" y="93.26" width="581.32" height="581.32" rx="12" ry="12" fill="none" stroke={completed ? "#ffcb06" : "#019ebe"} strokeMiterlimit="10" strokeWidth="5" />
                <g id="Bottom_Badge" data-name="Bottom Badge">
                    <path
                        d="m359.48,676.92c.1-.47.19-.96.26-1.47.13-.91.19-1.83.19-2.74v-62.65c0-7.64-4.46-14.7-11.36-17.99,0,0-31.57-14.99-32.25-15.28-2.72-1.16-5.56-2.11-8.44-2.83-.47-.12-.95-.24-1.42-.34-2.22-.49-4.47-.86-6.7-1.09-.4-.04-.79-.08-1.19-.11-1.55-.12-3.08-.19-4.54-.19-.42,0-.83,0-1.24.02-.36,0-.71.01-1.06.03-1.75.06-3.49.2-5.18.43-.32.04-.64.08-.96.13-1.62.24-3.21.54-4.71.89-.3.06-.6.13-.88.21-1.47.36-2.93.79-4.33,1.27-.28.08-.56.18-.82.28-1.32.46-2.68.99-4.02,1.58-.26.11-.51.22-.75.34-.31.14-.62.28-.92.43,0,0-30.34,14.44-30.52,14.55-1.21.62-2.38,1.38-3.46,2.27-.2.17-.4.34-.59.51-1.01.89-1.94,1.9-2.73,2.98-.16.21-.32.42-.47.64-.79,1.15-1.46,2.4-2.01,3.72-.11.26-.21.53-.3.79-.54,1.49-.88,3.06-1.08,4.64,0,0,0,.02,0,.02-.03.33-.06.67-.08,1-.02.37-.04.75-.04,1.12,0,0,.02,63.69.06,64.17.03.45.08.85.13,1.24"
                        fill={completed ? "#ffcb06" : "#019ebe"}
                    />
                    <ellipse id="Shadow" cx="294.83" cy="660" rx="38.63" ry="4.63" fill="#066367" opacity={completed ? ".05  " : ".25"} />
                    {type === "Home loan" ? (
                        <polygon
                            data-name="Home Icon"
                            points="325.47 624.66 317.57 616.76 317.57 600.98 309.67 600.98 309.67 608.86 293.9 593.08 262.32 624.66 270.19 624.66 270.19 656.34 285.98 656.34 285.98 632.65 301.78 632.65 301.78 656.34 317.57 656.34 317.57 624.66 325.47 624.66"
                            fill="url(#radial-gradient-2)"
                        />
                    ) : type === "Car loan" ? (
                        <path
                            data-name="Car Icon"
                            style={{ transform: 'translate(-2px, 0)' }}
                            d="m249.9,623.7c.41-.8.73-1.66,1.24-2.38.71-1.01,1.77-1.56,2.99-1.84,2.7-.61,5.39-1.25,8.09-1.88,2.38-.56,4.24-1.88,5.57-3.93,3.15-4.87,6.31-9.74,9.44-14.63,1.68-2.63,4.04-3.9,7.17-3.89,12.23.02,24.46.02,36.68,0,3.09,0,5.27,1.34,6.62,4.13,2.13,4.41,4.25,8.82,6.51,13.16,1.07,2.06,2.53,3.92,3.72,5.92,1.11,1.86,1.64,3.93,1.65,6.09.02,5.08.02,10.16,0,15.23,0,2.76-1.73,4.48-4.48,4.5-2.63.01-5.25,0-7.88.01-.06,0-.11.02.04,0-.88,1.46-1.55,2.92-2.52,4.11-2.79,3.42-6.51,4.79-10.83,4.07-4.21-.7-7.2-3.15-8.82-7.14-.32-.79-.65-1.08-1.54-1.07-6.54.05-13.07.04-19.61,0-.66,0-.94.2-1.18.81-1.61,4.14-4.64,6.71-9.01,7.4-4.7.74-8.55-.97-11.36-4.81-.73-1-1.18-2.19-1.8-3.38-1.71,0-3.52-.08-5.32.02-2.62.15-4.47-.79-5.35-3.35,0-5.72,0-11.44,0-17.16Zm54.92-6.19v-16.75c-.31-.01-.57-.04-.82-.04-5.86,0-11.72,0-17.59,0-2.17,0-3.8.94-4.92,2.78-2.23,3.66-4.47,7.31-6.69,10.97-.43.71-1.09,1.43-.58,2.32.5.89,1.46.72,2.29.72,9.1,0,18.2,0,27.3,0,.32,0,.63,0,1.03,0Zm6.76-.04c4.36,0,8.64.05,12.91-.03,1.39-.02,2.08-1.13,1.66-2.47-1.25-3.94-2.52-7.87-3.87-11.77-.47-1.36-1.6-2.3-2.99-2.39-2.55-.17-5.11-.05-7.71-.05v16.71Zm9.98,23.58c.07-3.19-2.43-5.78-5.67-5.88-3.13-.09-5.78,2.49-5.86,5.7-.08,3.1,2.46,5.72,5.64,5.82,3.18.1,5.82-2.43,5.88-5.64Zm-49.69,5.65c3.19,0,5.79-2.6,5.77-5.77-.02-3.15-2.54-5.7-5.71-5.75-3.15-.05-5.79,2.55-5.81,5.74-.03,3.18,2.56,5.78,5.76,5.79Z"
                            fill="url(#radial-gradient-3)"
                        />
                    ) : (
                        <path
                            data-name="Dollar Bill"
                            style={{ transform: 'translate(-5px, 6px)' }}
                            d="M262.49,605.8v36.82c0,2.14,1.2,4.16,3.21,4.91c10.34,3.86,20.68,1.22,31.02-1.41
                                c9.49-2.41,18.97-4.84,28.44-2.25c2.73,0.75,5.79-1.13,5.79-3.97v-36.81c0-2.14-1.2-4.16-3.21-4.91
                                c-10.34-3.86-20.68-1.22-31.02,1.41c-9.49,2.41-18.97,4.83-28.44,2.23C265.53,601.08,262.49,602.96,262.49,605.8z M296.72,637.55
                                c-6.3,0-11.41-6.13-11.41-13.69s5.11-13.69,11.41-13.69c6.3,0,11.41,6.13,11.41,13.69S303.02,637.55,296.72,637.55z M264.77,637.55
                                c5.04,0,9.13,4.09,9.13,9.13h-9.13V637.55z M273.9,607.88c0,5.04-4.09,9.13-9.13,9.13v-9.13H273.9z M328.67,630.71v9.13h-9.13
                                C319.54,634.8,323.64,630.71,328.67,630.71z M319.54,601.04h9.13v9.13C323.64,610.16,319.54,606.07,319.54,601.04z"
                            fill="#f7f7f7" />
                    )}
                </g>
                { completed ? (
                    <g id="Complete_Look" data-name="Complete Look">
                        <g id="_5_Stars" data-name="5 Stars">
                            <polygon points="289.22 437.62 299.93 459.32 323.88 462.8 306.55 479.69 310.64 503.53 289.22 492.28 267.81 503.53 271.9 479.69 254.57 462.8 278.52 459.32 289.22 437.62" fill="url(#linear-gradient)" fillRule="evenodd" />
                            <polygon points="349.77 437.62 354.88 447.97 366.29 449.62 358.03 457.67 359.98 469.04 349.77 463.67 339.57 469.04 341.51 457.67 333.26 449.62 344.67 447.97 349.77 437.62" fill="url(#linear-gradient-2)" fillRule="evenodd" />
                            <polygon points="388.29 437.62 393.39 447.97 404.8 449.62 396.55 457.67 398.49 469.04 388.29 463.67 378.08 469.04 380.03 457.67 371.77 449.62 383.18 447.97 388.29 437.62" fill="url(#linear-gradient-3)" fillRule="evenodd" />
                            <polygon points="192.64 437.62 197.74 447.97 209.15 449.62 200.89 457.67 202.84 469.04 192.64 463.67 182.43 469.04 184.38 457.67 176.12 449.62 187.53 447.97 192.64 437.62" fill="url(#linear-gradient-4)" fillRule="evenodd" />
                            <polygon points="231.15 437.62 236.25 447.97 247.67 449.62 239.41 457.67 241.36 469.04 231.15 463.67 220.94 469.04 222.89 457.67 214.63 449.62 226.05 447.97 231.15 437.62" fill="url(#linear-gradient-5)" fillRule="evenodd" />
                        </g>
                        <g id="Banner">
                            <path d="m539.72,356.88h-82.35v76.75h82.35l-.16-.25-23.71-38.12,23.71-38.12.16-.25Zm-498.51,0h82.38v76.75H41.21l.16-.25,23.72-38.12-23.72-38.12-.16-.25Z" fill="#ffca06" fillRule="evenodd" />
                            <path d="m123.58,416.91v16.71h-.18l-44.14-13.24.4-3.43,43.92-.04Zm333.78,0v16.71h.16l44.16-13.24-.38-3.43-43.94-.04Z" fill="#faa21b" fillRule="evenodd" />
                            <path d="m79.26,343.69h422.39v76.75H79.26v-76.75Z" fill="#fed800" fillRule="evenodd" />
                            <text transform="translate(0 387)" fill="#fff" fontFamily="Nunito-ExtraBold, Nunito" fontSize="52.11" fontWeight="700">
                                <tspan x="50%" y="0" dominantBaseline="middle" textAnchor="middle">
                                    100% Paid Off
                                </tspan>
                            </text>
                        </g>
                        <text transform="translate(0 290)" fill="#019ebe" fontFamily="Nunito-ExtraBold, Nunito" fontSize="55" fontWeight="700">
                            <tspan x="50%" y="0" dominantBaseline="middle" textAnchor="middle">
                                { name }
                            </tspan>
                        </text>
                        <path id="Border-2" data-name="Border" d="m214.63,659.24H29.84c-6.6,0-12-5.4-12-12V120.6c0-6.6,5.4-12,12-12h141.83" fill="none" stroke="#ffcb06" strokeMiterlimit="10" strokeWidth="3" />
                        <path id="Border-3" data-name="Border" d="m417.75,108.6h138.73c6.6,0,12,5.4,12,12v526.64c0,6.6-5.4,12-12,12h-183.71" fill="none" stroke="#ffcb06" strokeMiterlimit="10" strokeWidth="3" />
                    </g>
                ) : (
                    <g id="Incomplete_Look" data-name="Incomplete Look">
                        <text transform="translate(0 245)" fill="#019ebe" fontFamily="Nunito-ExtraBold, Nunito" fontSize="55" fontWeight="700">
                            <tspan x="50%" y="0" dominantBaseline="middle" textAnchor="middle">
                                { name }
                            </tspan>
                        </text>
                        <text transform="translate(0 335)" fill="#231f20" fontFamily="Nunito-Regular, Nunito" fontSize="70">
                        <tspan x="50%" y="0" dominantBaseline="middle" textAnchor="middle">
                                ${ regular_payment }
                            </tspan>
                        </text>
                        <text transform="translate(0 400)" fill="#231f20" fontFamily="Nunito-Regular, Nunito" fontSize="40">
                            <tspan x="50%" y="0" dominantBaseline="middle" textAnchor="middle">
                                Principal Payment
                            </tspan>
                        </text>
                        <g id="Banner-2" data-name="Banner">
                            <path d="m518.69,454.78h-74.27v69.22h74.27l-.14-.23-21.38-34.38,21.38-34.38.14-.23Zm-449.58,0h74.29v69.22h-74.29l.15-.23,21.39-34.38-21.39-34.38-.15-.23Z" fill="#6DC835" fillRule="evenodd" />
                            <path d="m143.4,508.93v15.07h-.16l-39.81-11.94.36-3.1,39.61-.04Zm301.02,0v15.07h.14l39.83-11.94-.34-3.1-39.63-.04Z" fill="#639739" fillRule="evenodd" />
                            <path d="m103.43,442.89h380.94v69.22H103.43v-69.22Z" fill="#6DDC31" fillRule="evenodd" />
                            <text transform="translate(0 480)" fill="#fff" fontFamily="Nunito-ExtraBold, Nunito" fontSize="47" fontWeight="700">
                                <tspan x="50%" y="0" dominantBaseline="middle" textAnchor="middle">
                                    { progress }% Complete
                                </tspan>
                            </text>
                        </g>
                    </g>
                )}
                { completed
                    ? <g id="Complete_Badge" data-name="Complete Badge">
                        <path
                            d="m291.02,222.1c92.25-76.39,91.89-69.19,91.89-91.04,0-5.01-.29-10.13-.29-14.46,0-8.58,2.18-18.11,2.18-88.37V11.18c-31.2-3.63-62.58-7.15-93.78-11.18-31.21,4.03-62.58,7.56-93.78,11.18v17.05c0,70.25,2.18,79.79,2.18,88.37,0,4.32-.29,9.45-.29,14.46,0,21.84-.36,14.65,91.89,91.04Z"
                            fill="#f1f2f2"
                        />
                        <path
                            d="m357.22,36.33h-22.07v-4.41c0-2.43-1.99-4.41-4.41-4.41h-79.43c-2.43,0-4.41,1.99-4.41,4.41v4.41h-22.07c-2.44,0-4.41,1.98-4.41,4.41,0,.58.07,14.28,5.86,28.18,7.38,17.7,20.6,27.67,38.4,29.03,2.52,4.63,5.41,9.33,8.7,14.04,17.97,25.68,0,47.91-17.65,47.91h.52c-2.71,0-4.93,1.99-4.93,4.41s2.22,4.41,4.93,4.41h69.57c2.71,0,4.93-1.99,4.93-4.41s-2.22-4.41-4.93-4.41h.52c-17.65,0-35.63-22.24-17.65-47.91,3.29-4.7,6.17-9.41,8.7-14.04,17.8-1.36,31.02-11.32,38.4-29.03,5.79-13.89,5.86-27.6,5.86-28.18,0-2.44-1.98-4.41-4.41-4.41Zm-122.71,29.41c-3.31-7.84-4.54-15.82-5-20.58h18.05c1.13,9.38,4.17,25.31,12.32,43.2-11.57-2.5-20.09-10.07-25.38-22.62Zm113.03,0c-5.29,12.54-13.8,20.12-25.38,22.62,8.15-17.89,11.19-33.82,12.32-43.2h18.05c-.46,4.77-1.69,12.74-5,20.58Z"
                            fill="url(#linear-gradient-6)"
                        />
                        <path d="m163.87,127.86l58-11.38v40.41l-57.51,11.28,24.48-25.69-24.96-14.62Zm260.06-34.65l-58,10.63v-40.41l57.51-10.52-24.48,25.69,24.96,14.62Z" fill="#088ea5" fillRule="evenodd" />
                        <path d="m221.86,140.13v16.76l-30.07-7.01.21-2.48,29.86-7.27Zm144.06-59.95v-16.76l30.07,7.01-.21,2.48-29.86,7.27Z" fill="#0b626c" fillRule="evenodd" />
                        <path
                            d="m334.48,45.15h18.05c-.28,2.96-.87,7.15-2.06,11.78l10.13-5.36c1-6.17,1.02-10.52,1.02-10.84,0-2.44-1.98-4.41-4.41-4.41h-16.08l-84.38,44.61c.95,2.43,1.98,4.89,3.13,7.41-3.59-.78-6.86-2.1-9.86-3.85l-8.9,4.7c6.54,5.08,14.44,8.04,23.54,8.74.6,1.11,1.3,2.23,1.94,3.34l63.13-33.38c2.7-9.24,4.07-17.17,4.75-22.75Z"
                            fill="#fee600"
                        />
                        <path d="m396,70.42c-95.48,18.52-109.07,20.59-204.2,39.05v40.41c95.88-18.86,108.86-20.73,204.2-39.05v-40.41Z" fill="#099ec0" fillRule="evenodd" />
                        <path d="m253.82,72.76l80.87-42.76c-.72-1.47-2.22-2.5-3.95-2.5h-8.56l-70.71,37.39c.68,2.51,1.47,5.15,2.36,7.87Z" fill="#fee600" />
                        <path d="m237.8,72.11l-7.85,4.15c1.42,2.41,2.97,4.63,4.66,6.65l8.08-4.27c-1.8-1.92-3.42-4.1-4.89-6.53Z" fill="#fee600" />
                        <g id="Text">
                            <text transform="translate(219.30 135.2) rotate(-11) scale(1.02 .98) skewX(-10.61)" fill="#fff" fontFamily="Nunito-Black, Nunito" fontSize="33.53" fontWeight="800">
                                <tspan x="0" y="0">
                                    Complete
                                </tspan>
                            </text>
                        </g>
                    </g>
                    : <></>
                }
            </svg>
        </>
    )
}
