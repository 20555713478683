import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Portal } from "react-portal"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { AmoritizationCalculator as Calculator } from "../../utility/tools.js"

import SavingsItem from './Components/SavingsItem.jsx'
import PromptDelete from '../Loans/Components/PromptDelete.jsx'
import Loader from 'common/Loader.jsx'

import axios from 'axios'
import moment from 'moment'

import Styles from './_.module.sass'

function sortFunction(sort_method="snowball"){
    return (first_element, second_element) => {
        // "avalanche" (Highest interest to smallest)
        // "snowball" (Smallest amount to highest)
        if (sort_method === "avalanche") {
            return second_element.interest - first_element.interest // Biggest interest to smallest interest
        } else {
            return first_element.total - second_element.total // Smallest amount to biggest amount
        } 
    }
}

export default function AllSavings(){

    const [state, setState] = useState({
        loading: true, createLoading: false, showDelete: false,
        data: null
    })

    const { data=[] } = state;

    useEffect(() => {
        axios
            .post('/api/loans/list')
            .then(({ data:list=[] }) => {
                list = list
                    .filter(a => a.type === "Savings")
                    .map(loan => {
                        const Nexium = new Calculator(loan)
                        const regular_payment = Nexium.getMonthlyPayment()
                        const start_date = Nexium.start_date
                        const active_since = moment.duration(moment().diff(start_date))
                        // const paid_off_percent = ;

                        return { ...loan, regular_payment, Nexium, start_date, active_since }
                    })
                setState((s) => { return { ...s, loading: false, data:list } })
            })
    }, [])

    if(state.loading){ return <Loader /> } // return LoadingScreen
    if(state.showDelete !== false){
        return <PromptDelete
            title="Delete loan"
            finishText="Delete loan"
            message="Are you sure you want to delete this loan?"
            close={() => setState({ ...state, showDelete: false })} 
            finish={() => {
                axios.post("/api/loans/delete", { id: state.showDelete }).then(() => {
                    const newList = [...data].filter((a) => a.id !== state.showDelete)
                    setState({ showDelete: false, data: newList })
                })
            }}
        />
    }

    const CreateButton = function(){
        return <Link
            to="/create?s=savings"
            className={"button is-primary" + (state.createLoading ? " is-loading" : "")}
        >
            <span>Add Savings Goal</span>
            <span className="icon">
                <FontAwesomeIcon icon={faPlus} />
            </span>
        </Link>
    }

    return (
        <div className="section pt-0">
            <div className="container">
                {
                    data.length
                    ?  <>
                        <div className="block columns is-vcentered">
                            <div className="column"></div>
                            <div className="column has-text-centered is-hidden-touch is-8">
                                <h1 className="title is-size-1 is-size-3-mobile has-text-black">Your Savings Buckets</h1>
                                <h2 className="subtitle is-size-5 is-size-6-mobile has-text-primary">Create and pay less on those loans!</h2>
                            </div>
                            <div className="column"></div>
                        </div>
                        <Portal node={document.querySelector("#navbar-end-item")}>{<CreateButton />}</Portal>
                        <div className={"block " + Styles.loanList}>
                            {
                                data
                                    .filter(a => a.total > a.completed)
                                    .sort(sortFunction(state.sort))
                                    .map((loan) => {
                                        return <SavingsItem key={loan.id} loan={loan} showDelete={() => {
                                            // Loans created by the envelope challenge cannot be deleted!
                                            if(loan.metadata && loan.metadata.includes('not-deletable') && loan.completed < loan.total){ return }
                                            setState({ ...state, showDelete:loan.id })
                                        }}/>
                                    })
                            }
                        </div>
                        <div className={"block " + Styles.loanList}>
                            {
                                data
                                    .filter(a => a.total <= a.completed)
                                    .sort(sortFunction(state.sort))
                                    .map((loan) => {
                                        return <SavingsItem key={loan.id} loan={loan} showDelete={() => {
                                            // Loans created by the envelope challenge cannot be deleted!
                                            if(loan.metadata && loan.metadata.includes('not-deletable') && loan.completed < loan.total){ return }
                                            setState({ ...state, showDelete:loan.id })
                                        }}/>
                                    })
                            }
                        </div>
                        <div className="block buttons is-centered is-hidden-tablet"><CreateButton /></div>
                    </>
                    : <>
                        <p className="title is-size-2 has-text-centered mt-6">No savings added yet!</p>
                        <p className="subtitle is-size-5 has-text-centered has-text-primary">Click create savings below to get started</p>
                        <div className="block buttons is-centered"><CreateButton /></div>
                    </>
                }
            </div>
        </div>
    )
}