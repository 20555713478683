import { useEffect } from "react"
import moment from "moment"
import { useCallback } from "react"

const loadScript = (callback) => {
    const existingScript = document.getElementById("calculatorWidget")

    if (!existingScript) {
        const script = document.createElement("script")
        if (process.env.NODE_ENV === "production") script.src = "https://api.fillyourbuckets.com/js/widget-modified.js?v=" + moment().format("MM-DD-YYYY-HH-MM")
        else script.src = "http://localhost:3001/js/widget-modified.js"
        script.id = "calculatorWidget"
        document.body.appendChild(script)
        script.onload = () => {
            if (callback) callback()
        }
    }
    if (existingScript && callback) callback()
}

export default function CalculatorX({ value:{ input=0, calc=0, tens=0 }={}, value, onChange=()=>{}, ...props }){

    const initCalculator = useCallback(() => {
        function getData(){
            return {
                input:  document.getElementById("inputbox-mhsa").innerText,
                calc:   document.getElementById("fraction-mhsa").innerText,
                output: document.getElementById("floor-price-mhsa").innerText
            }
        }
        function changeMiddlware({ input, tens }){
            let data = getData()
            if(data.input ){ data.input  = parseFloat(data.input)  }
            if(data.calc  ){ data.calc   = parseFloat(data.calc)   }
            if(data.output){ data.output = parseFloat(data.output) }
            onChange({ ...data, input, tens })
        }
        // If it's already been setup & initialized
        if (document.getElementById("calc-widget-mhsa")) { return }
        if (!window.initCalc || typeof window.initCalc !== "function") { return }

        console.log("Appending Calculator!")

        window.initCalc()
        let calcEvents = new window.JsEvents()
        if(value && value.input){
            calcEvents.initHistory(value)
        }

        // Setup callbacks
        calcEvents.callbacks.onChange = changeMiddlware
        calcEvents.callbacks.onClear = function(){
            changeMiddlware({ input: 0, tens: 0 })
        }

        // If safari: don't autofocus input. We get a weird double cursor ¯\_(ツ)_/¯
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return
        }

        // Focusing the input
        calcEvents.focusInput()
        calcEvents.placeCaretAtEnd(calcEvents.getInputElement())
    }, [onChange, value])

    // Load Script
    useEffect(() => {
        loadScript(function(){
            console.log("Script loaded!"); 
            initCalculator()
        })
        // return function(){ document.getElementById('calculatorWidget').remove() }
    }, [initCalculator])

    return (
        <div className="block calculatorWidget">
            <div className="calcWidget"></div>
        </div>
    )
}