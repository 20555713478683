import { useState, useEffect, useRef, useContext } from "react"
import { Link, Route } from "react-router-dom"

import UserContext from "../context/User.jsx"

// Utility
import moment from "moment"
import axios from "axios"

// Font Awesome 6
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faUser, faShieldHalved, faRightFromBracket, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
// import { faCalendar } from '@fortawesome/free-regular-svg-icons'

import "../sass/Profile.sass"

var availableYears = [],
    startYear = parseInt(moment().format("YYYY"))
for (let i = 0; i < 75; i++) {
    availableYears.push(String(startYear - i))
}

function save(setUser, { first, last, birth_month, birth_year }) {
    if (!first || !last) {
        return
    }

    axios
        .post("/api/updateProfile", { first, last, birth_month, birth_year })
        .catch(console.log)
        // Update the session object
        .then(() => {
            setUser((s) => {
                return {
                    ...s,
                    first,
                    last,
                    birth_month,
                    birth_year,
                }
            })
        })
        // Update the dropdown in the top right
        .then(() => {
            let el = document.querySelector(".navbar .navbar-end .dropdown.is-right .dropdown-trigger .nameTag .titles h1.title.is-5")
            if (el) {
                el.innerText = first + " " + last
            }
        })
    // Let the console know
    // .then(() => { console.log('Successfully saved all data!') })
}

function BlurOnEnter(e) {
    if (e.key && (e.key === "Enter" || e.key === "Escape")) {
        e.target.blur()
    }
}

export function Profile() {
    const [{ user }, setUser] = useContext(UserContext)
    const [state, setState] = useState({
        first: user.first || "",
        last: user.last || "",
        birth_month: user.birth_month || "",
        birth_year: user.birth_year || "",
        email: user.email || "",
        showPasswordHelp: false,
        passwordOk: false,
        passwordButtonLoading: false,
        passwordResetSuccessMessage: false,
    })

    function saveData() {
        save(setUser, state)
    }

    const passwordInput = useRef()

    useEffect(() => {
        let checkForm = setInterval(function () {
            if (passwordInput && passwordInput.current) {
                // console.log("Setting!")
                let value = passwordInput.current.value
                if (value && value.length >= 8) {
                    setState((s) => {
                        return { ...s, passwordOk: true, showPasswordHelp: false }
                    })
                } else if (value.length) {
                    setState((s) => {
                        return { ...s, passwordOk: false, showPasswordHelp: true }
                    })
                } else {
                    setState((s) => {
                        return { ...s, passwordOk: false, showPasswordHelp: false }
                    })
                }
            }
        }, 200)
        return clearInterval(checkForm)
    }, [setState])

    return (
        <section className="section pt-4">
            <div className="container is-max-fullhd">
                <div className="columns">
                    <div className="column profileView">
                        {state.passwordResetSuccessMessage ? (
                            <div className="block notification is-primary">
                                <button className="delete" onClick={() => setState({ ...state, passwordResetSuccessMessage: false })}></button>
                                <p>Your password has been successfully updated!</p>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="block box is-primary is-centered is-not-a-box-mobile">
                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <div className="field">
                                        <label className="label has-text-centered has-text-left-mobile">First Name</label>
                                        <p className="control is-expanded has-icons-left">
                                            <input
                                                className="input is-primary"
                                                type="text"
                                                placeholder="Name"
                                                value={state.first}
                                                autoComplete="given_name"
                                                onChange={(e) => {
                                                    setState({ ...state, first: e.target.value })
                                                }}
                                                onKeyDown={BlurOnEnter}
                                                onBlur={(e) => {
                                                    saveData()
                                                }}
                                            />
                                            <span className="icon is-small is-left">
                                                <FontAwesomeIcon icon={faUser} />
                                            </span>
                                        </p>
                                    </div>
                                    <div className="field">
                                        <label className="label has-text-centered has-text-left-mobile">Last Name</label>
                                        <p className="control is-expanded has-icons-left">
                                            <input
                                                className="input is-primary"
                                                type="text"
                                                placeholder="Last Name"
                                                value={state.last}
                                                autoComplete="family_name"
                                                onChange={(e) => {
                                                    setState({ ...state, last: e.target.value })
                                                }}
                                                onKeyDown={BlurOnEnter}
                                                onBlur={(e) => {
                                                    saveData()
                                                }}
                                            />
                                            <span className="icon is-small is-left">
                                                <FontAwesomeIcon icon={faUser} />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label has-text-centered has-text-left-mobile">Email</label>
                                <div className="control has-icons-left">
                                    <input
                                        className="input is-primary"
                                        type="email"
                                        disabled={true}
                                        placeholder="Email"
                                        autoComplete="email"
                                        value={state.email}
                                        // autoComplete="email"
                                        // onChange={(e) => { setState({ ...state, email: e.target.value }) }}
                                        // onKeyDown={BlurOnEnter}
                                        // onBlur={(e) => { saveData() }}
                                    />
                                    <span className="icon is-left">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </span>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-body equal">
                                    <div className="field">
                                        <label className="label has-text-centered has-text-left-mobile">Birth Month</label>
                                        <div className="control is-expanded">
                                            <div className="select is-fullwidth">
                                                <select
                                                    type="text"
                                                    className="input is-primary"
                                                    placeholder="Birth Month"
                                                    autoComplete="bday-month"
                                                    value={state.birth_month}
                                                    onChange={(e) => {
                                                        setState({ ...state, birth_month: e.target.value }, () => saveData())
                                                    }}
                                                    onBlur={(e) => {
                                                        saveData()
                                                    }}
                                                >
                                                    <option value="january">January</option>
                                                    <option value="february">February</option>
                                                    <option value="march">March</option>
                                                    <option value="april">April</option>
                                                    <option value="may">May</option>
                                                    <option value="june">June</option>
                                                    <option value="july">July</option>
                                                    <option value="august">August</option>
                                                    <option value="september">September</option>
                                                    <option value="october">October</option>
                                                    <option value="november">November</option>
                                                    <option value="december">December</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label has-text-centered has-text-left-mobile">Birth Year</label>
                                        <div className="control is-expanded">
                                            <div className="select is-fullwidth">
                                                <select
                                                    type="text"
                                                    className="input is-primary"
                                                    placeholder="Birth Year"
                                                    autoComplete="bday-year"
                                                    value={state.birth_year}
                                                    onChange={(e) => {
                                                        setState({ ...state, birth_year: e.target.value }, () => saveData())
                                                    }}
                                                    onBlur={(e) => {
                                                        saveData()
                                                    }}
                                                >
                                                    {availableYears.map((year) => {
                                                        return (
                                                            <option key={year} value={year}>
                                                                {year}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {/* <span className="icon is-small is-left">
                                                <FontAwesomeIcon icon={faUser} />
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="block box is-primary is-centered is-not-a-box-mobile">
                            <label className="label has-text-centered has-text-left-mobile">Reset Password</label>
                            <div className="field has-addons">
                                <div className="control is-expanded has-icons-left">
                                    <input className="input is-primary" type="password" placeholder="New Password" autoComplete="new-password" ref={passwordInput} />
                                    <span className="icon is-left">
                                        <FontAwesomeIcon icon={faShieldHalved} />
                                    </span>
                                </div>
                                <div className="control">
                                    <button
                                        className={"button is-primary is-expanded mb-0 pad-bottom" + (state.passwordButtonLoading ? " is-loading" : "")}
                                        type="button"
                                        disabled={!state.passwordOk}
                                        onClick={() => {
                                            const { current: { value: password = null } = {} } = passwordInput
                                            if (!password) {
                                                return
                                            }

                                            setState({ ...state, passwordButtonLoading: true })

                                            axios
                                                .post("/api/reset-password-auth", { password })
                                                .catch(console.log)
                                                .then(() => {
                                                    if (passwordInput.current) {
                                                        passwordInput.current.value = ""
                                                    }
                                                })
                                                .then(() => {
                                                    setState({
                                                        ...state,
                                                        passwordButtonLoading: false,
                                                        passwordResetSuccessMessage: true,
                                                    })
                                                })
                                                .then(() => {
                                                    console.log("Successfully reset the password!")
                                                })
                                        }}
                                    >
                                        {/* <span className="icon">
                                            <FontAwesomeIcon icon={faShieldHalved} />
                                        </span> */}
                                        <span>
                                            Set<span className="is-hidden-touch">&nbsp;Password</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <p className={"help" + (state.showPasswordHelp ? "" : " is-invisible")}>Password must be at least 8 characters long!</p>
                        </div>

                        <div className="block">
                            <div className="field">
                                <Link className="button is-light is-fullwidth" to="/privacy-policy" target="_blank">
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                    </span>
                                    <span>Privacy Policy</span>
                                </Link>
                            </div>
                            <div className="field">
                                <Link className="button is-light is-fullwidth" to="/terms-of-use" target="_blank">
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                    </span>
                                    <span>Terms Of Use</span>
                                </Link>
                            </div>
                            <div className="field">
                                <Link className="button is-primary is-fullwidth" to="/logout">
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faRightFromBracket} />
                                    </span>
                                    <span>Logout</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default (
    <>
        <Route path="/profile" element={<Profile />} />
    </>
)
