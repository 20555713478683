import React from "react"
import { Link } from "react-router-dom"

import Logo from "../images/logo.svg"

import { FontAwesomeIcon as FontAwesome6 } from "@fortawesome/react-fontawesome"
import { faArrowRight, faSquareCheck, faFileLines } from "@fortawesome/free-solid-svg-icons"

import Styles from "../sass/Legal.module.sass"

export default function Legal(props) {
    return (
        <div className={Styles.legal}>
            <section className="section">
                <div className="block container is-max-widescreen">
                    <Link className={"block " + Styles.logoImage} to="/login">
                        <img src={Logo} alt="Fill Your Buckets" />
                    </Link>
                </div>
                <div className="block container is-max-widescreen">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="block buttons is-left is-centered-mobile">
                                    <Link className={"button is-fullwidth-mobile " + (props.type === "TermsOfService" ? "is-primary" : "is-outlined")} to="/terms-of-use">
                                        <span className="icon">
                                            <FontAwesome6 icon={faSquareCheck} />
                                        </span>
                                        <span>Terms Of Service</span>
                                    </Link>
                                    <Link className={"button is-fullwidth-mobile " + (props.type === "PrivacyPolicy" ? "is-primary" : "is-outlined")} to="/privacy-policy">
                                        <span className="icon">
                                            <FontAwesome6 icon={faFileLines} />
                                        </span>
                                        <span>Privacy Policy</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <Link className="button is-primary " to="/login">
                                    <span>Back to app</span>
                                    <span className="icon">
                                        <FontAwesome6 icon={faArrowRight} />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container is-max-fullhd">
                    <div className={"content " + Styles.content}>{props.type === "PrivacyPolicy" ? <PrivacyContent /> : props.type === "TermsOfService" ? <TermsContent /> : <></>}</div>
                    <div className={Styles.contact}>
                        <strong>Fill Your Buckets</strong>
                        <p>
                            <a href="mailto:support@fillyourbuckets.com">
                                <strong>
                                    <u>support@fillyourbuckets.com</u>
                                </strong>
                            </a>
                        </p>
                        <p>1755 N. Westgate Dr. Suite #225</p>
                        <p>Boise, ID 83704</p>
                        <p>Effective Date: July 31, 2022</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

function TermsContent() {
    return (
        <>
            {/* <p>We take your privacy seriously. Please visit <a href="https://www.fillyourbuckets.com/privacy-policy">fillyourbuckets.com/privacy-policy</a> to review our privacy policy.</p> */}
            <h1>Terms of Use</h1>
            <p>
                By visiting fillyourbuckets.com (the “Site”), you, the visitor of the Site, agree to the terms of this agreement. This agreement is subject to change at any time by Fill Your Buckets. This agreement contains the entire agreement between the visitor and Fill Your Buckets, regarding
                the use of the site.
            </p>
            <p>The agreement shall be governed pursuant to the laws of the state of Idaho. The visitor hereby warrants and represents that he or she is in all respects qualified and competent to enter into this agreement.</p>
            <h2>No Warranty</h2>
            <p>
                No warranty is made by Fill Your Buckets, regarding any information, services or products provided through, or in connection with, the Site, unless expressly agreed otherwise in another written agreement for a particular product or service. Fill Your Buckets, hereby expressly
                disclaims any and all warranties, including without limitation: any warranties as to the availability, accuracy or content of information, products or services; any warranties of merchantability or fitness for a particular purpose. Some states/provinces do not allow the exclusion of
                implied warranties, so the above exclusion may not apply to you.
            </p>
            <h2>Use Restriction</h2>
            <p>
                The material on the Site is meant to be shared for informational, non-commercial use only. Site visitors are allowed to download and distribute banner graphics and promotional materials only for the express purpose of promoting the Site. Videos appearing on Fill Your Buckets YouTube
                channel may be inserted into private non-commercial blogs and websites, but must link directly to the official Fill Your Buckets video on YouTube. In no event, shall any video or material be used in any manner that implies any false association between Fill Your Buckets and any other
                third party or in connection with the promotion of any product or service.
            </p>
            <h2>Geographic Restrictions</h2>
            <p>
                The Site and web-based services are intended for access and use within the United States only. The Site and web-based services are not intended for or directed to citizens, domiciles or residents of the European Union. Accordingly, our sites and web-based services comply with
                applicable United States privacy laws. By accessing the Site and web-based services, you affirm that you are not a citizen, domicile or resident of the European Union.
            </p>
            <h2>Your Email Address</h2>
            <p>
                Fill Your Buckets does not sell or share your email address to third-parties, except to deliver any services you have requested. Unsubscribing from any email list does not necessarily unsubscribe you from other email lists within Fill Your Buckets. Users agree to provide Fill Your
                Buckets, with an up-to-date email address.
            </p>
            <h2>Linked Sites</h2>
            <p>
                The Site provides links to other third-party websites (in all cases “Linked Sites”). These Linked Sites are provided solely as a convenience to you and may be based upon the content that you elect to view or the information that you provide. Such Linked Sites are not under our
                control, and we are not responsible for, and do not endorse, monitor, review, investigate, verify, or validate the content of such Linked Sites, including any products, information or materials contained on such Linked Sites. We are not responsible for the accuracy or reliability of
                any information on the Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites. It is your responsibility to evaluate the accuracy, reliability, timeliness, and completeness of the content of these Linked Sites. Some of the
                content served by us may be from merchant sites, and sales or activities through these sites may generate a commission payable to us. We accept no responsibility for reviewing changes or updates to, or the quality, content, policies, nature or reliability of third-party web sites,
                including, without limitation, Linked Sites and websites linking to this website or its content. You should review applicable terms and policies, including privacy and data gathering practices, of third-party web sites, and should make whatever investigation you feel necessary or
                appropriate before proceeding with any transaction with any third-party.
            </p>
            <h2>Additional Help</h2>
            <p>
                For additional information contact Fill Your Buckets, at <a href="mailto:support@fillyourbuckets.com">support@fillyourbuckets.com</a>.
            </p>
        </>
    )
}
function PrivacyContent() {
    return (
        <>
            <h1>Fill Your Buckets Privacy Policy</h1>

            <p>
                Fill Your Buckets is committed to protecting your privacy, and in doing so, we want to make sure that this Privacy Policy (“Policy”) is clear and concise enough for you to understand it. This Policy discloses our privacy practices and how we may use the information that we gather on
                our digital properties, including but not limited to: websites, online platforms, mobile applications, web services, services, and products owned by Fill Your Buckets (the “Sites”). We hope that as you read through the Policy, you will become comfortable using the Sites and learn how
                Fill Your Buckets is here to help you. We created this Policy for the purpose of protecting your privacy and the information you provide to us.
            </p>
            <p>By using our Sites or voluntarily providing personal information to us, you consent to the terms and conditions of this Policy. This Policy applies to information collected by our Sites and contains the following:</p>
            <ol>
                <li>A list of the categories of personally identifiable information we may collect from you through our Sites;</li>
                <li>How the information may be used and with whom it may be shared;</li>
                <li>What choices are available to you regarding our use of your data;</li>
                <li>The security measures in place to protect the misuse of your information;</li>
                <li>How you can correct any inaccuracies in the information collected; and</li>
                <li>Privacy rights for state specific residents.</li>
            </ol>
            <p>
                Respecting the privacy and the security of your personal information is important to us. We realize that providing us with personal information is an act of trust. Therefore, we have created this Policy to show you the steps we have taken to maintain that trust and secure your
                information.
            </p>

            <h2>Collected Information</h2>
            <p>
                Each time you visit our Sites, certain information may be collected both when you provide it voluntarily and automatically when you use the Sites. In some instances, the collected information may allow us to match activities with users. We may also collect your information from other
                sources, as described below. Examples of the type of information that may be collected are as follows:
            </p>
            <ul>
                <li>
                    Your internet service provider's address, your internet protocol or IP address, device ID, the web page from which you accessed our Sites, your web browser software, the type of handheld or mobile device used to link to the web page, and the language settings on your web browser;
                </li>
                <li>Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories, and other information you post or otherwise submit to us, including emails or other communications you provide to us;</li>
                <li>Data about the webpages you visit, including without limitation web browser specifications, websites visited before accessing our Sites, and websites visited after leaving our Sites;</li>
                <li>A record of your activity and interactions while visiting or using our Sites, including the existence or types of data you may upload or the terms you search;</li>
                <li>Data about your device and nearby networks, including internet connection information, computer equipment information, regional and language settings;</li>
                <li>Data about you such as personal reference contact information, and other related data;</li>
                <li>“Cookies” or other common tracking methods of different types to recognize your computer (please see the Cookies section below for description of what cookies are and how they are used); and</li>
                <li>
                    Web beacons, match scripts, pixel tags, or similar technologies that allow us to know information such as whether a particular web page was visited, an e-mail opened, links in the email accessed, if the advertisements on our Sites or other sites were useful or beneficial to the
                    user(s), or other information that may help us improve your experience.
                </li>
            </ul>
            <p>We will also collect personal information that you provide/input on our Sites, such as your name, birthdate, e-mail address, mailing address, phone number, unique personal identifier, or payment processing information (e.g., debit card information or other banking information). </p>
            <p>
                We partner with third parties, who may collect personal information for interest-based advertising purposes when you interact with our Sites. You may opt out of the collection of your information by these third parties here:{" "}
                <a href="https://optout.networkadvertising.org/?c=1">https://optout.networkadvertising.org/?c=1</a>.
            </p>

            <h2>Use of Information</h2>
            <p>We may use the information collected for any of the following purposes:</p>
            <ul>
                <li>Administer our Sites and systems;</li>
                <li>Create, maintain, and personalize your account with us;</li>
                <li>Provide, maintain, personalize, and improve our Sites;</li>
                <li>Detect, prevent, and address technical issues and provide customer support;</li>
                <li>Monitor and maintain the safety, security, and integrity of our Sites and technological assets;</li>
                <li>Fulfill orders you have placed;</li>
                <li>Better understand and serve your needs;</li>
                <li>Respond to you regarding the reason you contacted us;</li>
                <li>Enhance your customer experience and improve our customer service;</li>
                <li>
                    Customize offerings and advertisements to you that relate to the services you have used or requested or which otherwise relate to your behaviors we have observed on the Sites or on the websites you visit before accessing our Sites or the websites you visit after leaving our
                    Sites;
                </li>
                <li>Notify you about changes to our Sites;</li>
                <li>Monitor the usage of our Sites and to gather analysis and assess trends and interests;</li>
                <li>Allow you to participate in interactive features and forums of our Sites;</li>
                <li>Process payments and fulfill orders you have placed; and</li>
                <li>Communicate newsletters, marketing or promotional materials, or other information to you about products, services and special offerings of Fill Your Buckets and our third-party partners that may be of interest to you.</li>
            </ul>
            <p>
                We may also use this information to evaluate and manage our businesses. Combined data is collected from online and offline facilities and may be used to enhance the ability of Fill Your Buckets to communicate with you and to support business functions, such as internal business
                processes, marketing, authentication, customer service, fraud prevention, and public safety and legal functions.
            </p>
            <p>
                We will make every effort to keep you informed of Fill Your Buckets, news and offers, so periodically, we may send these announcements via email or direct mail. If you do not wish to be contacted by Fill Your Buckets or our business affiliates, simply click on the unsubscribe link
                found at the bottom of the email newsletter.
            </p>

            <h2>Sharing of Information</h2>
            <p>We may disclose your non-personal information with third parties at our discretion.</p>
            <p>
                There may be times when we need to share, or otherwise disclose, your personal information with third-party service providers. The reasons we may need to share this information with those providers relate to the services they provide to us or that we may provide to them, which may
                include:
            </p>
            <ul>
                <li>Authorization of debit card and check transactions, order fulfillment, service promotions;</li>
                <li>Marketing and advertising services to communicate with you or to assist us in providing personalized advertising;</li>
                <li>Managing and processing your personal information;</li>
                <li>Developing, hosting, or maintaining our Sites;</li>
                <li>Analyzing customer information and/or improving customer service. We may then share such information with third parties including prospective marketing partners and advertisers; and</li>
                <li>Other services that you expressly request which require us to share your personal information.</li>
            </ul>
            <p>
                These third-party service providers are only granted access to your personal information, as we deem necessary, and they are contractually restricted in the ways they may use your information. The providers may also use cookies (as defined within this Policy), or other automatic
                collection technology on behalf of Fill Your Buckets.
            </p>
            <p>
                Moreover, in the event that a division, or part or all of Fill Your Buckets, is purchased, sold or otherwise transferred to/by another company, or is in the process of a potential transaction with another company, customer information will likely be shared for evaluation purposes and
                included among the transferred business assets. Such acquiring company’s right to use your personal information shall be limited to the terms of this Policy unless additional notice is provided to you.
            </p>
            <p>
                Fill Your Buckets may also be required to disclose specific information to comply with the law, a subpoena, or other litigation process. We may also disclose your information to protect the interests or safety of its visitors and customers, employees, or others, which includes
                exchanging information with other companies and organizations for fraud protection and credit risk reduction.
            </p>

            <h2>Geographic Restrictions</h2>
            <p>
                Our Sites and web-based services are intended for access and use within the United States only and comply with applicable United States privacy laws. Our Sites and web-based services are not intended for, or directed to, citizens, domiciles, or residents outside of the United States,
                including those of the European Union. By accessing our Sites and web-based services, you affirm that you are not a citizen, domicile, or resident of a country outside of the United States, including those of the European Union.
            </p>

            <h2>Do Not Track</h2>
            <p>
                Do Not Track (“DNT”) is a privacy preference that you can set in some web browsers to inform websites that you do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of your web browser. However, these features are not yet uniform, so we
                do not currently respond to such features or signals. Therefore, if you select or turn on a DNT feature in your web browser, we and our third-party providers may continue collecting information about your online activities as described in this Policy.
            </p>

            <h2>Cookies</h2>
            <p>
                “Cookies" are defined as small pieces of information (data files) that are downloaded to and stored by your browser or other application on your computer's, or other device’s, browser or hard drive when you use our Sites, which are unique to your device or account. Cookies make it
                easier for you to use our Sites by saving your preferences so that we can use these to improve your next and subsequent visits to our Sites. Cookies help us learn which areas of our Sites are useful and which areas need improvement. Cookies may be either persistent or temporary (or
                session) cookies. A persistent cookie retains user preferences for a particular website, app or service, allowing those preferences to be used in future use sessions and remains valid until its set expiry date (unless deleted by the user before the expiry date). A temporary cookie,
                on the other hand, will expire at the end of the user session, when the web browser is closed.
            </p>
            <p>
                Cookies are used to connect your computer or other device with information stored about your online activity, searches, preferences, and product purchases, both within the same website visit and from one visit to the next, on our Sites and/or other websites. Some examples of the
                information cookies may store by your browser include your account preferences, user specific information on pages accessed, searched terms or addresses entered, and previous visits to the site. You can choose whether to accept cookies by changing the settings on your browser or
                device. The "help" portion of the toolbar on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive new cookies, or how to disable cookies altogether. If you reject cookies, you may not be able to
                participate in certain activities or receive a promotion customized for you. Our Sites do not control and do not guarantee the effectiveness of browser-based tools for managing cookies. When we use cookies or similar technologies, we may set the cookies ourselves or ask third parties
                to do so.
            </p>

            <h2>Security</h2>
            <p>
                Fill Your Buckets is committed to keep your information private and secure. We use industry-standard security techniques on our Sites to help protect against the loss, misuse or alteration of information collected from you at our Sites. While we strive to protect your personal
                information, we cannot ensure the security of the information you transmit to us. In this regard, we urge you to take every precaution to protect your personal data while you are on the Internet. At a minimum, we encourage you to make sure that you are using a secure browser as you
                surf the Internet.
            </p>

            <h2>Other Sites</h2>
            <p>
                Our Sites may contain links to third-party sites, including those of our business partners. If you click one of those links, you will be taken to websites we do not control. This Policy does not apply to the information practices of those third-party websites. You should read the
                privacy policies of third-party websites carefully. While we seek to link only to sites that share our high standards and respect for privacy and security, we are not responsible or liable for the content, privacy policies/practices, actions, or security of third-party websites or
                services.
            </p>
            <h1>Children’s Privacy</h1>

            <h2>Under Age 13</h2>
            <p>
                Fill Your Buckets does not knowingly solicit or collect personally identifiable information online from children under the age of 13 without prior verifiable parental consent. If we learn that a child under the age of 13 has submitted personally identifiable information online
                without parental consent, we will take all reasonable measures to delete such information from our databases and not use such information for any purpose (except where necessary to protect the safety of the child or others as required or allowed by law).
            </p>

            <h2>Under Age 18</h2>
            <p>Personal information that has been provided to Fill Your Buckets through our Sites by minors under the age of 18, can be deleted by sending an email requesting the deletion to privacy@fillyourbuckets.com.</p>
            <p>Please note that while we make reasonable efforts to comply with such requests, deletion of your personal information does not ensure complete and comprehensive removal of that data from all systems.</p>
            <p>If you become aware of any personally identifiable information we have collected from children or minors under this Policy, please contact us at privacy@fillyourbuckets.com.</p>
            <p>Our Sites are not intended for use by children.</p>

            <h2>California Consumer Privacy Act (CCPA)</h2>
            <p>CCPA provides California residents privacy rights, including the right to:</p>
            <ul>
                <li>Request the following information regarding our use of your personal information:</li>
                <ul>
                    <li>Categories of your personal information that we have collected;</li>
                    <li>Purposes for which we have collected or used such personal information;</li>
                    <li>Categories of sources for the personal information we have collected about you;</li>
                    <li>Categories of third parties to whom we have disclosed your personal information;</li>
                    <li>Categories of personal information disclosed about you for a business purpose; and</li>
                    <li>Categories of information that the business sells or discloses to third parties.</li>
                </ul>
                <li>Receive a copy of your personal information;</li>
                <li>Request us to delete your personal information (subject to certain exceptions that may apply); and</li>
                <li>Opt out of the sale of your personal information (subject to certain exceptions that may apply).</li>
            </ul>
            <p>To make a request under CCPA e-mail privacy@fillyourbuckets.com. Please include "California Privacy Rights Request" in the subject line of the email and include your California street address and zip code in the body of the email.</p>
            <p>Your exercise of these rights will have no adverse effect on the availability, price, or quality of our services.</p>
            <h2>Changes to the Privacy Policy</h2>
            <p>
                This Policy may be revised in our sole discretion from time to time as we add new features and services, as laws change, and as industry privacy and security best practices evolve. When we revise the Policy, we will post the new Policy on this page and change the date located at the
                bottom of the Policy. We encourage you to return to this page periodically to check for any updates or changes. If we make any change to this Policy regarding use or disclosure of personal information, we will provide advance notice on our Sites. Small changes, or changes that do not
                significantly affect individual privacy interest, may be made at any time, and without prior notice.
            </p>
            <h2>Governing Law and Jurisdiction</h2>
            <p>
                This Policy shall be construed and governed under the laws of the United States and the State of Idaho (without regard to rules governing conflicts of laws provisions). You agree that venue for all actions, arising out of or relating in any way to your use of our Sites, shall be in
                state court having jurisdiction over Ada County, Boise, within one (1) year after the claim(s) arises. Each party waives any objections based on forum non conveniens and waives any objection to venue of any action instituted hereunder to the extent that an action is brought in the
                courts identified above. Each party consents to personal jurisdiction in the courts identified above.{" "}
            </p>
            <h2>Contact</h2>
            <p>Please contact us if you have any questions or concerns about our Privacy Policy. Contact us by e-mail at privacy@fillyourbuckets.com.</p>
        </>
    )
}
