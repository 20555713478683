import { createContext, useState, useEffect, useMemo } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"

import { AmoritizationCalculator as Calculator } from "utility/tools.js"
import axios from "axios"
import moment from 'moment'

import CalculatorWithStats from "./Components/CalculatorWithStats"
import LoanCharts from './Components/LoanCharts.jsx'
import LoanSettings from "./Components/LoanSettings"
import PayoffEarlyChallenge, { PayoffEarlyChallengePrompt, PayoffEarlyChallengeStart } from "./Components/PayoffEarlyChallenge"

import Loader from "common/Loader.jsx"

export const LoanContext = createContext()

export default function ViewLoan({ ...props }){

    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [ state, setState ] = useState({
        loading: false,
        page: '',
        calculator_data: { }
    })
    const [ value, setValue ] = useState(null)

    useEffect(function () {
        function setData(new_data, save_to_db=true) {
            if(save_to_db){
                const { loan_owner, name, total, interest, term, term_display, start_months, start_years, paymentHistory, metadata, payoff_challenge, calculator_data={} } = new_data
                axios.post('/api/loans/edit', {
                    id, loan_owner, name, total, interest, term, term_display, start_months, start_years, paymentHistory, metadata, payoff_challenge, calculator_data
                })
                .then(() => { console.log("Saved changes to loan to database!") })
            }
        }
        function refresh() {
            axios
                .post(`/api/loans/get`, { id })
                .catch(console.log)
                .then(({ data, status }) => {
                    if(!data || (status === 404)){
                        return navigate('/loans', { replace: false })
                    }

                    const Nexium = new Calculator(data)
                    const regular_payment = Nexium.getMonthlyPayment()
                    const start_date = Nexium.start_date
                    const active_since = moment.duration(moment().diff(start_date))

                    if(!data.calculator_data){ data.calculator_data = {} }
                    
                    // setData({ ...data, Nexium, regular_payment, start_date, active_since }, () => { setState((s) => { return { ...s, loading: false } }) })
                    setState((s) => ({ ...s, loading: false }))
                    setValue([{ ...data, Nexium, regular_payment, start_date, active_since }, setData, refresh])
                })
        }
        // if(location && location.state && location.state.data){
        //     return setData(location.state.data);
        // }
        refresh()
    }, [id, location, navigate])

    useEffect(() => {
        if (!value || JSON.stringify(state.calculator_data) === JSON.stringify(value?.[0].calculator_data)){
            return;
        }
        console.log("Saving calculator data to database!", {
            state_calculator_data: state.calculator_data,
            value_calculator_data: value?.[0].calculator_data
        })
        // Set data
        axios
            .post('/api/loans/edit', {
                id,
                calculator_data: state.calculator_data
            })
            .then(() => setValue((v) => {
                const [ loan, setData, refresh ] = v
                return [{
                    ...loan,
                    calculator_data: state.calculator_data
                }, setData, refresh]
            }))
            .then(() => { console.log("Saved calculator data to database!") })
    }, [ id, state.calculator_data ])
    
    if (state.loading || !value || !value.length) { return <Loader /> }

    const [loan] = value;
    
    return (
        <LoanContext.Provider value={value}>
            { state.page === ''
                ? <div className="container mb-6 pb-6">
                    <div className="block is-hidden-tablet">
                        <p>{' '}</p>
                    </div>
                    <div className="block mt-4">
                        <h1 className="title is-size-1 is-size-4-mobile has-text-centered icon-text" style={{ justifyContent: "center", width: '100%' }}>
                            <span>{ loan.name }</span>
                            <span className="icon is-medium is-clickable ml-2 has-text-grey-light" onClick={() => { setState({ ...state, page:'settings' }) }}>
                                {" "}<FontAwesomeIcon icon={faCog} size="lg" />
                            </span>
                        </h1>
                    </div>
                    <PayoffEarlyChallengePrompt setPage={() => setState({ ...state, page:'joinPayoffChallenge' })}/>
                    <CalculatorWithStats
                        setCalculatorData={(calculator_data) => setState({ ...state, calculator_data })}
                        onStatClicked={() => { setState({ ...state, showCharts: !state.showCharts }) }}
                    />
                    { state.showCharts
                        ? <LoanCharts calculator_data={loan.calculator_data}/>
                        : <></>
                    }
                    <PayoffEarlyChallenge />
                </div>
                : state.page === 'settings'
                ? <LoanSettings closeSettings={() => { setState({ ...state, page:'' }) }} />
                : <PayoffEarlyChallengeStart finish={() => setState({ ...state, page:'' })}/>
            }
            
        </LoanContext.Provider>
    )

}
