import Mountain from "images/icons/avalanche.svg"
import Snowball from "images/icons/snowball.svg"
import MountainDark from "images/icons/avalanche-dark.svg"
import SnowballDark from "images/icons/snowball-dark.svg"

export default function SortButtons({ sort_method="snowball", setSort, ...props }){

    return (
        <div className="block buttons is-right has-addons">
            <button
                data-tooltip="Sort Snowball"
                className={"button has-tooltip-bottom is-" + (sort_method === "snowball" ? "primary" : "light")}
                onClick={() => { setSort("snowball") }}
                type="button"
            >
                <span className="icon">
                    { sort_method === "snowball" 
                        ? <img src={Snowball} alt="Snowball" /> 
                        : <img src={SnowballDark} alt="Snowball" />
                    }
                </span>
            </button>
            <button
                data-tooltip="Sort Avalanche"
                className={"button has-tooltip-bottom is-" + (sort_method === "avalanche" ? "primary" : "light")}
                onClick={() => { setSort("avalanche") }}
                type="button"
            >
                <span className="icon">
                    { sort_method === "avalanche" 
                        ? <img src={Mountain} alt="Avalanche" /> 
                        : <img src={MountainDark} alt="Avalanche" />
                    }
                </span>
            </button>
        </div>
    )

}