import Styles from '../sass/BucketSVGBasic.module.sass'

export default function BucketSVGBasic({ index, bucket_name, completion=50 }) {
    let bucket_bottom = 28,
        invertedCompletion = Math.abs(completion - 100)

    let translateY = invertedCompletion * (1 - bucket_bottom / 100) + "%",
        scale = 1 - invertedCompletion / 5 / 100

    if (completion >= 100) {
        translateY = 0
        scale = 1
    }

    let gradient_color_1 = "#049CBE",
        gradient_color_2 = "#53c4cc"

    if (completion >= 100) {
        gradient_color_1 = "#f4a23f"
        gradient_color_2 = "#f5c269"
    }

    return (
        <>
            <div className={"fillable-bucket" + (completion >= 100 ? " is-filled" : "")}>
                <div className={Styles.SVG}>
                    {/* <BucketSVG /> */}
                    <svg className={Styles.outline} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 187.7 183.6" style={{ enableBackground: "new 0 0 187.7 183.6" }}>
                        <path fill="#FFFFFF" d="M0,0v183.6h187.7V0H0z M150,13.4c16.2,3.8,20.2,8,20.5,9.3v0l0,0.4c-0.5,1.5-4.8,5.5-20.4,9.2
                        c-14.9,3.5-34.8,5.4-56,5.4c-21.2,0-41.1-1.9-56-5.4c-16.7-3.9-20.5-8.3-20.5-9.5s3.8-5.5,20.5-9.5C53,9.9,72.8,8,94,8
                        C115.2,8,135.1,9.9,150,13.4z M54.4,172.4l-0.3,0h-0.2c-11.1-1.9-19.9-11-21.2-22.2L18.9,34.3c17,8.9,53.3,11.6,75.1,11.6
                        c21.8,0,58-2.7,75-11.5l-13.9,115.9c-1.4,11.3-10.3,20.4-21.5,22.2C107.3,176.7,80.7,176.7,54.4,172.4z"
                        />
                        <path className={Styles.svgStyle1} d="M7.8,51c0,0-18,23,12.4,43.2" />
                        <path className={Styles.svgStyle1} d="M179.9,48c0,0,18,23-12.4,43.2" />
                        <ellipse className={Styles.svgStyle2} cx="94" cy="22.9" rx="80.4" ry="18.9" />
                        <path
                            className={Styles.svgStyle2}
                            d="M13.6,22.9l15.1,127.7c1.6,13.2,11.8,23.7,24.9,25.7h0.2c26.7,4.3,53.9,4.3,80.5,0l0,0
                        c13.1-2.1,23.2-12.5,24.9-25.7l15.3-127.8"
                        />
                        <path
                            className={Styles.svgStyle3}
                            d="M11.3,39.8l-2.7,0.3c-1.7,0.2-3,1.8-2.8,3.5c0,0,0,0.1,0,0.1l2.1,14.8c0.2,1.7,1.8,2.9,3.5,2.7l2.7-0.3
                        c1.7-0.2,3-1.7,2.8-3.5c0,0,0-0.1,0-0.1l-2.1-14.8C14.5,40.8,12.9,39.6,11.3,39.8z"
                        />
                        <path
                            className={Styles.svgStyle3}
                            d="M173.6,58.8l2.7,0.4c1.7,0.3,3.3-0.9,3.6-2.6c0,0,0-0.1,0-0.1l1.8-14.8c0.2-1.7-1-3.2-2.7-3.5l-2.7-0.4
                        c-1.7-0.3-3.3,0.9-3.6,2.6c0,0,0,0.1,0,0.1l-1.8,14.8C170.7,57,171.9,58.6,173.6,58.8z"
                        />
                    </svg>
                    <svg className={Styles.fill} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 187.7 183.6" style={{ enableBackground: "new 0 0 187.7 183.6", transform: `translateY(${translateY}) scale(${scale})` }}>
                        <linearGradient id={"SVGID_" + index} gradientUnits="userSpaceOnUse" x1="94.05" y1="179.525" x2="94.05" y2="4">
                            <stop offset="0.2" style={{ stopColor: gradient_color_1 }} />
                            <stop offset="1" style={{ stopColor: gradient_color_2 }} />
                        </linearGradient>
                        <path
                            style={{ fill: `url(#SVGID_${index})` }}
                            d="M174.5,22.8l-15.3,127.8c-0.9,6.6-3.8,12.5-8.2,17c-4.4,4.5-10.2,7.6-16.7,8.7c-26.6,4.3-53.8,4.3-80.5,0h-0.2
                        c-13.1-2-23.3-12.5-24.9-25.7L13.7,24c-0.1-0.3-0.1-0.7-0.1-1.1C13.6,12.5,49.6,4,94,4c44.3,0,80.2,8.4,80.4,18.8H174.5z"
                        />
                    </svg>
                </div>
            </div>
            <p className={"bucket-name has-text-" + (completion >= 100 ? "warning" : "primary")}>{bucket_name}</p>
        </>
    )
}