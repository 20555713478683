// import { StrictMode } from "react"
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"

// Tools
import axios from "axios"

// Inspectlet
import { install } from 'inspectlet-es';

// Contexts
import { FireBaseProvider } from 'context/FireBase.jsx'
import { UserProvider } from 'context/User.jsx'

// Routes
import { Dashboard }    from 'routes/Dashboard.jsx'
import Auth             from 'routes/Authentication.jsx'
import Create           from "routes/Create.jsx"
import Legal            from "routes/Legal.jsx"
import Loans            from "routes/Loans/_all.jsx"
import Savings          from "routes/Savings/_all.jsx"
import Profile          from "routes/Profile.jsx"
import StacksOfCash     from "routes/StacksOfCash.jsx"
import Challenges       from 'routes/Challenges/_all'
import PageNotFound     from 'routes/PageNotFound.jsx'
import Buckets          from "routes/Buckets/_all.jsx";

// Scss
import "./sass/index.sass"

// Default settings 
axios.defaults.baseURL = process.env.REACT_APP_API
axios.defaults.crossDomain = true
axios.defaults.withCredentials = true
axios.defaults.responseType = "json"

const root = ReactDOM.createRoot(document.getElementById('root'));

if (window && window.location && window.location.origin && window.location.origin.includes("localhost")) { console.log("Not tracking localhost!"); }
else{ install(1770157289); console.log("[INSP] :: Active"); }

root.render(
    <>
        <FireBaseProvider>
            <UserProvider>
                <App/>
            </UserProvider>
        </FireBaseProvider>
    </>
);

function App() {
    return (
        <BrowserRouter>
            <Routes>
                { Auth }
                <Route index path="/" element={<Navigate to="/get-started" />} />
                <Route path="/get-started" element={<Create showLogin={true} />} />
                <Route path="/create" element={<Create showLogin={false} />} />
                <Route path="/" element={<Dashboard />}>
                    { Buckets }
                    { Loans   }
                    { Savings }
                    { Profile }
                    { Challenges }
                    {/* <Route path="calculator" element={<CalculatorWrapper />} /> */}
                    <Route path="cash" element={<StacksOfCash />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
                <Route path="/terms-of-use" element={<Legal type="TermsOfService" />} />
                <Route path="/privacy-policy" element={<Legal type="PrivacyPolicy" />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
// {/* <Route path="calculator/:id" element={<Calculator startFromHistory={true} />}  /> */}