import { useState, useContext } from 'react';
import { LoanContext } from "../ViewLoan"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine, faChartSimple } from "@fortawesome/free-solid-svg-icons"

import { Line as LineChart } from "react-chartjs-2"
import "chart.js/auto"

import moment from 'moment'
import ReactTooltip from "react-tooltip"

import Brand from "brand.json"
import Styles from "../_.module.sass"

export default function LoanCharts({ calculator_data:{ calc }, ...props }){

    const [mode, setMode] = useState("bars")
    const [loan] = useContext(LoanContext)
    const { Nexium } = loan;

        // Gather our data
    const extraPayments = Nexium.gatherPaymentHistory()

    const header = <div className="container is-max-widescreen mt-4">
        <div className="columns">
            <div className="column"></div>
            <div className="columns">
                <div className="buttons is-right">
                    <button className={"button" + (mode === "bars" ? " is-primary" : "")} onClick={() => setMode("bars")} type="button">
                        <span className="icon"> <FontAwesomeIcon icon={faChartSimple} /> </span>
                    </button>
                    <button className={"button" + (mode === "chart" ? " is-primary" : "")} onClick={() => setMode("chart")} type="button">
                        <span className="icon"> <FontAwesomeIcon icon={faChartLine} /> </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    const loanData = {
        default: Nexium.getSchedule("default"),
        current: Nexium.getSchedule("current", { extraPayments }),
        future: Nexium.getSchedule("future", { extraPayments, forecast: { extra: calc, interval: "monthly" } }),
    }

    const early_end_date = moment((loanData.current.find((a) => a.running_totals.remaining <= 0) || loanData.current[loanData.current.length - 1]).date)

    // Set "completed" value of the loan without refreshing state

    // This number is tricky, because it goes off our current date.
    // But what if the loan ended in the past? We need a fallback to ensure "current paid" is still showing correctly! :)

    const loan_completed_amount = (
        loanData.current.find((a) => {
            return moment(a.date).month() === moment().month() && moment(a.date).year() === moment().year()
        }) || loanData.current[loanData.current.length - 1]
    ).running_totals.interest

    // What is this?? (TODO)
    // if (this.state.completed !== loan_completed_amount) {
    //     this.completed = loan_completed_amount
    //     this.save()
    // }

    const months_paid_off_early = Math.round(moment.duration(Nexium.end_date.diff(early_end_date)).asMonths())

    const bigStats = (
        <div className="block columns">
            <div className="column"></div>
            <div className="column">
                <BigStat figure={"$" + String((loanData.default[loanData.default.length - 1].running_totals.interest - loanData.current[loanData.current.length - 1].running_totals.interest).toFixed(2)).toLocaleString()} label="Interest Saved" />
            </div>
            <div className="column">
                <BigStat figure={months_paid_off_early + " month" + (months_paid_off_early === 1 ? "" : "s")} label="Paid Off Early" />
            </div>
            <div className="column"></div>
            {/* <div className="column">
                <BigStat figure={((this.completed / this.state.total) * 100).toFixed(2)+'%'} label="Paid Off" />
            </div>
            <div className="column">
                <BigStat figure={'--'} label="Coming Soon" />
            </div> */}
        </div>
    )

    // Only do the math that is necessary for each
    if (mode === "bars") {
        function minimum(number, min = 0) {
            number = parseFloat(number)
            min = parseFloat(min)
            return number >= min ? number : min
        }

        const progress_A = {
            values: {
                current: minimum(moment.duration(moment().diff(Nexium.start_date)).asDays()),
                remaining: minimum(moment.duration(early_end_date.diff()).asDays()),
                total: minimum(moment.duration(Nexium.end_date.diff(Nexium.start_date)).asDays()),
            },
            duration: {
                current: Nexium.start_date.fromNow(true),
                remaining: Math.round(moment.duration(early_end_date.diff(Nexium.start_date)).asMonths()),
                total: Math.round(moment.duration(Nexium.end_date.diff(Nexium.start_date)).asMonths()),
            },
        }
        progress_A.widths = {
            current: minimum((progress_A.values.current / progress_A.values.total) * 100),
            remaining: minimum((progress_A.values.remaining / progress_A.values.total) * 100),
        }
        // progress_A.widths.total = 100 - (progress_A.widths.current + progress_A.widths.remaining)

        const progress_B = {
            values: {
                current: loan_completed_amount,
                // current:   loanData.current.reduce((prev, curr) => {
                //     if(moment(curr.date).isBefore(moment())){ return prev + curr.interest }
                //     return prev
                // }, 0),
                remaining: loanData.current[loanData.current.length - 1].running_totals.interest,
                // remaining: loanData.current.reduce((prev, curr) => {
                //     if(moment(curr.date).isAfter(moment())){ return prev + curr.interest }
                //     return prev
                // }, 0),
                total: loanData.default[loanData.default.length - 1].running_totals.interest,
            },
        }
        progress_B.labels = {
            current: "$" + String(minimum(progress_B.values.current).toLocaleString()) + " paid interest",
            remaining: "$" + String(minimum(progress_B.values.remaining).toLocaleString()) + " will pay in interest",
        }
        progress_B.labels.total =
            "$" +
            String(
                minimum(
                    progress_B.values.total, // - (progress_B.values.current + progress_B.values.remaining)
                ).toLocaleString(),
            ) +
            " total interest"
        progress_B.widths = {
            current: minimum((progress_B.values.current / progress_B.values.total) * 100),
            remaining: minimum((progress_B.values.remaining / progress_B.values.total) * 100),
        }
        progress_B.widths.total = 100 - (progress_B.widths.current + progress_B.widths.remaining)

        return (
            <section>
                {header}
                <div className="container is-max-widescreen">
                    <div className="block">
                        <label className="label">Payoff Early</label>
                        <SmartProgressbar
                            data={[
                                {
                                    text: `${progress_A.duration.current} into loan`,
                                    width: progress_A.widths.current.toFixed(2) + "%",
                                    color: "white",
                                },
                                {
                                    text: `You will pay this loan off ${progress_A.duration.total - progress_A.duration.remaining} months early!`,
                                    width: progress_A.widths.remaining.toFixed(2) + "%",
                                    color: "primary",
                                },
                                {
                                    text: `${loan.term_display === "years" ? progress_A.duration.total / 12 : progress_A.duration.total} ${loan.term_display === "years" ? "year" : "month"} long mortgage`,
                                    width: "100%",
                                    color: "info",
                                },
                            ]}
                        />
                    </div>
                    <div className="block">
                        <label className="label">Interest Saved</label>
                        <SmartProgressbar
                            data={[
                                {
                                    text: progress_B.labels.current,
                                    width: progress_B.widths.current.toFixed(2) + "%",
                                    color: "white",
                                },
                                {
                                    text: progress_B.labels.remaining,
                                    width: progress_B.widths.remaining.toFixed(2) + "%",
                                    color: "primary",
                                },
                                {
                                    text: progress_B.labels.total,
                                    width: "100%", // progress_B.widths.total.toFixed(2) + '%',
                                    color: "info",
                                },
                            ]}
                        />
                    </div>
                    {bigStats}
                </div>
            </section>
        )
    } else {
        // Do the math!
        const NexiumDatelist = Nexium.getSchedule("labels"),
            labels = NexiumDatelist.map((a) => (loan.term <= 12 ? a.format("MMM") : a.format("YYYY"))),
            tension = 0.1

        const linechartOptions = {
            elements: {
                point: {
                    pointRadius: 0,
                    borderWidth: 0,
                },
                pointRadius: 0,
            },
            scales: {
                y: {
                    beginAtZero: true,
                    // grid: {
                    //     display: false
                    // },
                    // ticks: {
                    //     color: 'white',
                    //     display: false
                    // }
                },
                x: {
                    // ticks: {
                    //     color: 'white'
                    // },
                    // grid: {
                    //     color: 'transparent'
                    // }
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
            plugins: {
                // legend: {
                //     display: false
                // },
                tooltip: {
                    enabled: true,
                    displayColors: false,
                    callbacks: {
                        title: function (items, data) {
                            return NexiumDatelist[items[0].dataIndex].format("MMMM YYYY")
                            // return items[0].formattedValue + ' ' + (items[0].dataset.unit || '');
                        },
                        label: function (items) {
                            return items.dataset.label + ": $" + String(parseFloat(items.raw).toFixed(2)).toLocaleString()
                        },
                    },
                },
            },
        }

        return (
            <section>
                {header}
                <div className="block container is-fluid mt-4">
                    <div className="columns">
                        <div className="column">
                            <h1 className="title is-size-3 has-text-centered mb-1">Interest</h1>
                            <div className={"box " + Styles.lineChart}>
                                <LineChart
                                    options={linechartOptions}
                                    height={"250px"}
                                    data={{
                                        labels,
                                        datasets: [
                                            {
                                                label: "Default",
                                                data: loanData.default.map((a) => a.running_totals.interest),
                                                fill: false,
                                                borderColor: Brand.warning,
                                                tension,
                                            },
                                            {
                                                label: "Current",
                                                data: loanData.current.map((a) => a.running_totals.interest),
                                                fill: false,
                                                borderColor: Brand.primary,
                                                tension,
                                            },
                                            {
                                                label: "Future",
                                                data: loanData.future.map((a) => a.running_totals.interest),
                                                fill: false,
                                                borderColor: Brand.info,
                                                tension,
                                            },
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                        <div className="column">
                            <h1 className="title is-size-3 has-text-centered mb-1">Principal</h1>
                            <div className={"box " + Styles.lineChart}>
                                <LineChart
                                    options={linechartOptions}
                                    height={"250px"}
                                    data={{
                                        labels,
                                        datasets: [
                                            {
                                                label: "Default",
                                                data: loanData.default.map((a) => a.running_totals.principal),
                                                fill: false,
                                                borderColor: Brand.warning,
                                                tension,
                                            },
                                            {
                                                label: "Current",
                                                data: loanData.current.map((a) => a.running_totals.principal),
                                                fill: false,
                                                borderColor: Brand.primary,
                                                tension,
                                            },
                                            {
                                                label: "Future",
                                                data: loanData.future.map((a) => a.running_totals.principal),
                                                fill: false,
                                                borderColor: Brand.info,
                                                tension,
                                            },
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                        <div className="column">
                            <h1 className="title is-size-3 has-text-centered mb-1">Remaining</h1>
                            <div className={"box " + Styles.lineChart}>
                                <LineChart
                                    options={linechartOptions}
                                    height={"250px"}
                                    data={{
                                        labels,
                                        datasets: [
                                            {
                                                label: "Default",
                                                data: loanData.default.map((a) => a.running_totals.remaining),
                                                fill: false,
                                                borderColor: Brand.warning,
                                                tension,
                                            },
                                            {
                                                label: "Current",
                                                data: loanData.current.map((a) => a.running_totals.remaining),
                                                fill: false,
                                                borderColor: Brand.primary,
                                                tension,
                                            },
                                            {
                                                label: "Future",
                                                data: loanData.future.map((a) => a.running_totals.remaining),
                                                fill: false,
                                                borderColor: Brand.info,
                                                tension,
                                            },
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block container is-max-fullhd">{bigStats}</div>
            </section>
        )
    }
}

function SmartProgressbar({ data, debug = false }) {
    // Data format: [ { text:"", width:'33%', color: 'primary' } ]

    return (
        <div className="stacked-progress is-large">
            {data.map((item, index) => {
                if (item.width && parseInt(item.width.slice(0, -1)) <= 0) {
                    console.log("Width is less than 0: " + item.text)
                    return null
                }
                return (
                    <div data-tip={item.text} className={"progress-item is-" + (item.color || "primary")} style={{ width: item.width || "33%" }} key={index}>
                        {debug ? item.width || "33%" : parseInt(item.width.slice(0, -1)) <= 20 ? "" : item.text}
                        <ReactTooltip />
                    </div>
                )
            })}
        </div>
    )
}


function BigStat({ figure = 0, label }) {
    return (
        <div className="box bigStat is-primary has-text-centered">
            <h1 className="title mb-0 is-size-4 is-spaced has-text-weight-bold has-text-primary">{figure}</h1>
            {label ? <label className="label has-text-weight-normal">{label}</label> : <></>}
        </div>
    )
}