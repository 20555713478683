export default function PageMessage({ title, text, children, ...props }) {
    return (
        <div className="container is-max-fullhd has-text-centered">
            <div className="fixedAlertFullscreen mx-auto">
                {title ? (
                    <div className="block">
                        <h1 className="title">{title}</h1>
                    </div>
                ) : (
                    <></>
                )}
                {text ? <div className="block">{text}</div> : <></>}
                <div className="block buttons is-centered">{children}</div>
            </div>
        </div>
    )
}
