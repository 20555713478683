import Favicon from 'images/favicon.svg'

export default function NotFound({ text, title="Whoops..." }){

    return (
        <div className="section">
            <div className="hero is-halfheight">
                <div className="hero-body has-text-centered">
                    <div className="container">
                        <figure className="block image is-centered is-256x256">
                            {" "}
                            <img src={Favicon} alt="Fill Your Buckets" />{" "}
                        </figure>
                        <p className="title mb-0">{title}</p>
                        <p className="title is-size-1 has-text-primary">{ text }</p>
                        <p>This link may be broken or something went wrong.</p>
                    </div>
                </div>
            </div>
        </div>
    )

}