import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios'

import Loader from 'common/Loader'

import Styles from './_.module.sass'

function percentage(remaining, total){
    return Math.round(
        (remaining / total) * 100
    )
}

export default function Index({ ...props }){

    const [ state, setState ] = useState({})

    useEffect(() => {
        axios
            .post('/api/challenges/getProgress', {})
            .catch(console.log)
            .then((payload) => {
                if(!payload || !payload.data){ return; }
                setState(payload.data)
            })
    }, [])

    if(!state || !state){ return <Loader /> }

    let progress_100_challenge = state?.challenge_100_days?.data?.checked
        ? percentage(
            Object.keys(
                state.challenge_100_days.data.checked
            ).length, 
            state.challenge_100_days.data.level === 1
                ? 100
                : 200
        )
        : null

    let progress_52_challenge = state?.challenge_52_weeks?.name
        ? percentage(
            state.challenge_52_weeks.completed,
            state.challenge_52_weeks.total
        )
        : null

    let progress_26_challenge = state?.challenge_26_weeks?.name
        ? percentage(
            state.challenge_26_weeks.completed,
            state.challenge_26_weeks.total
        )
        : null

    let any_are_complete = (progress_26_challenge && progress_26_challenge >= 100) || (progress_52_challenge && progress_52_challenge >= 100) || (progress_100_challenge && progress_100_challenge >= 100)

    return (
        <div className="hero is-halfheight">
            <div className="hero-body">
                <div className="container is-max-widescreen mb-6 pb-6">
                    <h1 className="title has-text-centered is-size-2 is-size-4-mobile" onClick={(() => console.log(state))} >Which Challenge Do You Want To Play?</h1>
                    <div className={"block columns " + (!any_are_complete ? Styles.NoneComplete : '')}>
                        <div className="column">
                            <Link to="/challenge/100days">
                                <GameTile name="100 Day Challenge" color="purple" progress={progress_100_challenge} />
                            </Link>
                        </div>
                        <div className="column">
                            <Link to="/challenge/52weeks">
                                <GameTile name="52 Week Challenge" color="green" progress={progress_52_challenge} />
                            </Link>
                        </div>
                        <div className="column">
                            <Link to="/challenge/26weeks">
                                <GameTile name="26 Week Challenge" color="primary" progress={progress_26_challenge} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

function GameTile({ name, color='primary', to, progress }){

    const complete = (progress && progress >= 100)

    color = ({
        primary: {
            base:   "#039EBE",
            bright: "#03BBD2",
            medium: "#098EA5",
            dark:   "#05606A"
        },
        green: {
            base:   "#62DC50",
            bright: "#92EE79",
            medium: "#3FB724",
            dark:   "#289B12"
        },
        purple: {
            base:   "#B352DB",
            bright: "#C564E2",
            medium: "#923CBA",
            dark:   "#6E2A91"
        }
    })[color]

    return (<div className={Styles.GameTile}>
        <div className={Styles.swirlyHolder}>
            <svg className={Styles.swirly} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 611.89 609.81">
                <defs>
                    <linearGradient id="swirlyGradient" x1="305.94" y1="304.94" x2="305.94" y2="0" gradientUnits="userSpaceOnUse">
                        <stop offset=".08" stopColor="#2baae2" />
                        <stop offset=".74" stopColor="#010101" />
                    </linearGradient>
                </defs>
                <polygon points="305.94 304.94 305.94 304.94 281.15 0 330.74 0 305.94 304.94"           fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 183.47 24.58 230.37 8.48 305.94 304.94"    fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 99.06 79.55 138.19 49.09 305.94 304.94"    fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 37.08 158.95 64.19 117.43 305.94 304.94"   fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 4.24 254.17 16.41 206.1 305.94 304.94"     fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 4.11 354.9 0 305.48 305.94 304.94"         fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 36.69 450.21 16.76 404.8 305.94 304.94"    fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 98.46 529.77 64.86 493.3 305.94 304.94"    fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 182.72 584.97 139.1 561.38 305.94 304.94"  fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 280.33 609.81 231.42 601.66 305.94 304.94" fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 380.73 601.6 331.82 609.78 305.94 304.94"  fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 473.01 561.24 429.41 584.86 305.94 304.94" fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 547.19 493.09 513.62 529.59 305.94 304.94" fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 595.21 404.55 575.32 449.97 305.94 304.94" fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 611.89 305.21 607.82 354.63 305.94 304.94" fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 595.39 205.84 607.6 253.91 305.94 304.94"  fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 547.53 117.22 574.68 158.71 305.94 304.94" fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 473.47 48.94 512.63 79.37 305.94 304.94"   fill="url(#swirlyGradient)" />
                <polygon points="305.94 304.94 305.94 304.94 381.26 8.41 428.17 24.47 305.94 304.94"    fill="url(#swirlyGradient)" />
            </svg>
        </div>
        <svg className={Styles.tile} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 453.13 766.42">
            <defs>
                <linearGradient id="linear-gradient" x1="204.2" y1="630.31" x2="245.51" y2="630.31" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fed900" />
                    <stop offset="1" stopColor="#ffc808" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="311.33" y1="629.51" x2="395.4" y2="629.51" href="#linear-gradient" />
                <linearGradient id="linear-gradient-3" x1="84.12" y1="672.69" x2="165.92" y2="672.69" href="#linear-gradient" />
                <linearGradient id="linear-gradient-4" x1="56.88" y1="177.33" x2="123.97" y2="177.33" href="#linear-gradient" />
                <linearGradient id="linear-gradient-5" x1="368.33" y1="552.01" x2="410.43" y2="552.01" href="#linear-gradient" />
                <linearGradient id="linear-gradient-6" x1="250.17" y1="706" x2="285.82" y2="706" href="#linear-gradient" />
                <linearGradient id="linear-gradient-7" x1="39.92" y1="612.19" x2="79.94" y2="612.19" href="#linear-gradient" />
                <linearGradient id="linear-gradient-8" x1="361.88" y1="158.19" x2="398.81" y2="158.19" href="#linear-gradient" />
                <linearGradient id="linear-gradient-9" x1="29.91" y1="250.33" x2="72" y2="250.33" href="#linear-gradient" />
            </defs>
            { name === "100 Day Challenge" ? (
                <g id="_100_Day_Challenge" data-name="100 Day Challenge">
                    <g>
                        <path
                            d="m304.47,661.25v19.03c0,2.55-2.06,4.61-4.61,4.61h-8.07c-4.98,11.32-14.85,20.05-27.39,27.39v12.97h-18.45v-11.24h-36.9l-3.75,11.24-21.62-5.77,3.75-10.67c-14.49-8.97-22.06-22.74-22.07-36.76-.01-8.44,2.72-16.97,8.34-24.6,8.83-12.02,23.13-18.78,38.04-18.78h25.74c6.6,0,13.1,1.36,19.13,3.98,4.24-7.46,11.27-12.06,21.06-13.78v14.99s9.51,10.67,13.55,22.77h8.65c2.55,0,4.61,2.06,4.61,4.61Z"
                            fill="#db7eb3"
                        />
                        <path
                            d="m162.5,681.94c-5.97,0-10.98-1.39-14.93-4.14-.24-.17-.47-.34-.69-.51-3,.63-6.11.38-8.91-.75-1.39-.56-2.06-2.14-1.5-3.53.56-1.39,2.14-2.06,3.53-1.5.76.31,1.56.51,2.39.61-2.44-4.25-2.93-9.25-1.06-13.36,1.16-2.54,3.05-4.51,5.33-5.55,2.74-1.25,5.9-1.07,8.26.46,2.65,1.7,4.21,4.99,4.16,8.81-.05,4.74-2.42,9.33-6.22,12.15,3.41,1.62,7.74,2.21,12.91,1.75,1.48-.13,2.81.97,2.94,2.46.13,1.49-.97,2.81-2.46,2.94-1.29.11-2.54.17-3.75.17Zm-12.06-24.14c-.51,0-1.05.11-1.54.33-1.08.49-2.04,1.53-2.65,2.86-1.52,3.35-.23,7.34,2.01,10.1.33-.17.66-.36.97-.56,2.64-1.76,4.38-4.96,4.42-8.15.03-1.87-.63-3.51-1.67-4.18-.43-.28-.97-.42-1.55-.42Z"
                            fill="#db7eb3"
                        />
                        <path
                            d="m258.51,667.11c-.43-1.27-.47-2.71-.13-4.15.35-1.43,1.13-2.81,2.2-3.89,2.16-2.19,5.19-3.16,8.06-3.11,2.87.04,5.83,1.07,7.95,3.24,1.05,1.07,1.81,2.42,2.18,3.82.36,1.4.35,2.82,0,4.09-.14.5-.65.79-1.15.66-.31-.09-.55-.32-.64-.61l-.02-.05c-.7-2.15-1.81-3.64-3.29-4.51-1.46-.89-3.25-1.29-5.03-1.25-1.79.05-3.52.52-4.92,1.38-1.42.85-2.54,2.23-3.4,4.38l-.02.05c-.2.49-.75.73-1.24.53-.27-.11-.46-.33-.55-.58Z"
                            fill="#093959"
                        />
                        <g opacity=".1">
                            <path d="m291.79,684.89c-4.98,11.32-14.85,20.05-27.39,27.39v12.97h-18.45v-11.24h-36.9l-3.75,11.24-21.62-5.77,3.75-10.67c-14.49-8.97-22.06-22.74-22.07-36.76,7.94,32.44,99.04,42.53,126.42,12.83Z" fill="#26316a" />
                            <path d="m291.79,684.89c-4.98,11.32-14.85,20.05-27.39,27.39v12.97h-18.45v-11.24h-36.9l-3.75,11.24-21.62-5.77,3.75-10.67c-14.49-8.97-22.06-22.74-22.07-36.76,7.94,32.44,99.04,42.53,126.42,12.83Z" fill="none" stroke="#27306d"  strokeWidth="0" />
                        </g>
                        <g opacity=".2">
                            <path d="m228.58,662.62c0,4.51-7.53,8.17-16.82,8.17s-16.82-3.66-16.82-8.17,7.53-3.94,16.82-3.94,16.82-.57,16.82,3.94Z" fill="#fff" />
                        </g>
                        <path d="m205.5,643.69c-.9-2.42-1.37-5.05-1.29-7.79.33-11.4,9.84-20.38,21.24-20.05,11.4.33,20.38,9.84,20.05,21.24-.08,2.74-.7,5.33-1.74,7.7l-38.26-1.1Z" fill="url(#linear-gradient)" />
                        <line x1="202.36" y1="643.72" x2="248" y2="644.78" fill="none" stroke="#093959" strokeLinecap="round" strokeWidth="2" />
                    </g>
                    <g id="Dollar_Bill" data-name="Dollar Bill">
                        <path
                            d="m385.33,119.56l23.87,32.8c.75,1.03.68,2.43-.15,3.39-1.59,1.85-3.25,3.61-4.99,5.28-1.35,1.3-2.74,2.56-4.17,3.77-5.87,4.98-12.33,9.19-18.83,12.97-3.47,2.02-6.95,3.9-10.37,5.72-10.27,5.45-19.96,10.22-26.95,15.65-2.69,2.09-4.97,4.27-6.73,6.62-1.1,1.47-3.3,1.47-4.38-.02l-23.76-32.65c-.64-.89-.72-2.08-.13-3.02,1.87-3,4.56-5.7,7.85-8.25,6.98-5.43,16.68-10.2,26.95-15.65,9.83-5.22,20.19-11.05,29.2-18.7,2.95-2.49,5.74-5.18,8.34-8.1,1.16-1.3,3.22-1.22,4.25.18Z"
                            fill="#76c050"
                        />
                        <path
                            d="m385.11,137.8l7.67,10.53c-3.39,2.47-4.15,7.22-1.68,10.62.16.23.33.44.52.64-1.49,1.25-3.06,2.47-4.74,3.7-3.24,2.36-6.9,4.71-11.16,7.18-4.52,2.63-9.11,5.04-13.55,7.37-6.93,3.64-13.5,7.1-18.96,10.94l-.09-.12c-2.47-3.39-7.22-4.14-10.62-1.67l-7.65-10.5c3.39-2.47,4.14-7.22,1.68-10.61,4.71-3.28,10.83-6.5,17.28-9.88,8.32-4.37,17.76-9.32,26.39-15.61,1.67-1.21,3.25-2.44,4.76-3.68,2.57,2.89,6.95,3.4,10.14,1.08Z"
                            fill="#a7d276"
                        />
                        <circle cx="360.2" cy="163.33" r="9.01" fill="#46aa48" />
                        <circle cx="377.33" cy="152.43" r="3.71" fill="#46aa48" />
                        <circle cx="342.18" cy="173.42" r="3.71" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-2" data-name="Dollar Bill">
                        <path
                            d="m145.42,204.74l-20.44,35.04c-.64,1.1-1.95,1.6-3.16,1.23-2.33-.71-4.61-1.52-6.83-2.45-1.73-.71-3.44-1.48-5.12-2.3-6.92-3.37-13.37-7.6-19.45-12.03-3.24-2.37-6.37-4.8-9.41-7.2-9.12-7.21-17.38-14.17-25.16-18.39-2.99-1.62-5.9-2.84-8.77-3.5-1.78-.42-2.67-2.43-1.74-4.02l20.35-34.88c.55-.95,1.62-1.49,2.71-1.34,3.49.5,7.05,1.88,10.71,3.87,7.78,4.21,16.05,11.17,25.16,18.39,8.73,6.9,18.24,14.05,28.86,19.23,3.47,1.7,7.05,3.18,10.77,4.38,1.66.54,2.42,2.46,1.54,3.96Z"
                            fill="#76c050"
                        />
                        <path
                            d="m128.62,211.87l-6.56,11.25c-3.63-2.12-8.28-.9-10.39,2.73-.14.24-.27.48-.37.73-1.74-.86-3.49-1.8-5.3-2.86-3.46-2.02-7.08-4.42-11.06-7.33-4.22-3.08-8.28-6.32-12.19-9.44-6.12-4.88-11.93-9.51-17.64-12.97l.07-.13c2.12-3.63.89-8.28-2.74-10.39l6.55-11.22c3.62,2.11,8.27.89,10.39-2.72,4.9,2.99,10.3,7.31,15.99,11.85,7.35,5.87,15.67,12.51,24.89,17.89,1.78,1.04,3.54,2,5.28,2.88-1.61,3.51-.32,7.74,3.08,9.72Z"
                            fill="#a7d276"
                        />
                        <circle cx="95.23" cy="199.31" r="9.01" fill="#46aa48" />
                        <circle cx="112.1" cy="210.62" r="3.71" fill="#46aa48" />
                        <circle cx="78.76" cy="186.87" r="3.71" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-3" data-name="Dollar Bill">
                        <path
                            d="m194.88,592.29l-12.89,31.51c-.4.99-1.44,1.56-2.49,1.39-2.02-.32-4.01-.73-5.97-1.23-1.52-.39-3.03-.83-4.53-1.31-6.14-2-12-4.75-17.57-7.72-2.97-1.59-5.85-3.24-8.66-4.88-8.42-4.93-16.1-9.74-23.06-12.34-2.67-1-5.24-1.67-7.7-1.89-1.53-.14-2.5-1.71-1.92-3.14l12.83-31.36c.35-.85,1.17-1.43,2.09-1.43,2.96.01,6.08.74,9.36,1.96,6.96,2.59,14.64,7.41,23.06,12.34,8.06,4.72,16.8,9.54,26.23,12.6,3.08,1,6.23,1.82,9.46,2.38,1.44.25,2.29,1.76,1.74,3.11Z"
                            fill="#76c050"
                        />
                        <path
                            d="m181.76,600.18l-4.14,10.12c-3.26-1.33-6.98.22-8.32,3.48-.09.22-.16.43-.22.65-1.55-.51-3.11-1.09-4.74-1.75-3.11-1.27-6.4-2.85-10.05-4.8-3.87-2.07-7.62-4.28-11.24-6.42-5.66-3.34-11.02-6.51-16.18-8.71l.05-.11c1.33-3.26-.23-6.98-3.49-8.32l4.13-10.09c3.26,1.33,6.98-.23,8.32-3.48,4.42,1.91,9.42,4.87,14.68,7.98,6.79,4.02,14.49,8.56,22.78,11.96,1.6.65,3.18,1.25,4.73,1.78-.93,3.1.64,6.47,3.7,7.72Z"
                            fill="#a7d276"
                        />
                        <circle cx="152.55" cy="593.65" r="7.56" fill="#46aa48" />
                        <circle cx="167.89" cy="601.07" r="3.12" fill="#46aa48" />
                        <circle cx="137.4" cy="585.23" r="3.12" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-4" data-name="Dollar Bill">
                        <path
                            d="m308.52,517.47l27.3,34.65c.85,1.09.84,2.61-.02,3.69-1.65,2.08-3.38,4.05-5.2,5.94-1.41,1.47-2.87,2.89-4.37,4.26-6.17,5.65-13.01,10.5-19.92,14.88-3.69,2.34-7.39,4.53-11.03,6.65-10.93,6.35-21.27,11.93-28.63,18.13-2.83,2.38-5.22,4.84-7.04,7.48-1.13,1.64-3.52,1.73-4.76.16l-27.17-34.49c-.74-.94-.87-2.23-.27-3.27,1.9-3.33,4.72-6.38,8.19-9.3,7.36-6.19,17.7-11.78,28.63-18.13,10.46-6.07,21.48-12.85,30.96-21.53,3.1-2.83,6.03-5.86,8.72-9.15,1.2-1.47,3.45-1.46,4.62.02Z"
                            fill="#76c050"
                        />
                        <path
                            d="m309.04,537.31l8.77,11.13c-3.59,2.83-4.21,8.02-1.38,11.6.19.24.38.46.59.67-1.56,1.42-3.22,2.82-5,4.22-3.43,2.7-7.3,5.4-11.83,8.27-4.8,3.04-9.69,5.85-14.41,8.57-7.38,4.25-14.38,8.27-20.15,12.68l-.1-.12c-2.83-3.59-8.02-4.2-11.61-1.37l-8.74-11.1c3.58-2.82,4.2-8.01,1.39-11.6,4.98-3.76,11.5-7.51,18.37-11.46,8.86-5.09,18.91-10.87,28.02-18.05,1.76-1.39,3.44-2.78,5.02-4.2,2.91,3.03,7.7,3.41,11.06.76Z"
                            fill="#a7d276"
                        />
                        <circle cx="283.03" cy="566.08" r="9.8" fill="#46aa48" />
                        <circle cx="301.19" cy="553.52" r="4.04" fill="#46aa48" />
                        <circle cx="263.87" cy="577.79" r="4.04" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-5" data-name="Dollar Bill">
                        <path
                            d="m235.93,531.97l-27.29,20.36c-.85.64-2.03.59-2.85-.09-1.57-1.31-3.06-2.69-4.48-4.13-1.11-1.12-2.17-2.27-3.2-3.46-4.24-4.88-7.83-10.25-11.07-15.67-1.73-2.89-3.34-5.8-4.9-8.65-4.67-8.56-8.77-16.65-13.4-22.46-1.78-2.23-3.63-4.13-5.62-5.59-1.24-.91-1.26-2.75-.03-3.68l27.16-20.26c.74-.55,1.74-.62,2.53-.14,2.53,1.54,4.83,3.77,7,6.51,4.62,5.8,8.72,13.9,13.4,22.46,4.47,8.2,9.47,16.83,15.98,24.32,2.12,2.45,4.4,4.77,6.88,6.92,1.11.96,1.06,2.69-.11,3.56Z"
                            fill="#76c050"
                        />
                        <path
                            d="m220.62,531.97l-8.76,6.54c-2.11-2.82-6.1-3.41-8.92-1.3-.19.14-.36.29-.53.45-1.06-1.24-2.11-2.54-3.15-3.94-2.01-2.7-4.02-5.74-6.14-9.29-2.25-3.77-4.32-7.6-6.32-11.29-3.12-5.78-6.09-11.26-9.37-15.8l.1-.07c2.82-2.11,3.4-6.1,1.29-8.93l8.74-6.52c2.1,2.82,6.1,3.4,8.92,1.31,2.8,3.92,5.56,9.02,8.46,14.4,3.75,6.94,8,14.81,13.35,21.99,1.03,1.39,2.08,2.71,3.14,3.96-2.4,2.18-2.79,5.87-.81,8.52Z"
                            fill="#a7d276"
                        />
                        <circle cx="198.95" cy="511.32" r="7.56" fill="#46aa48" />
                        <circle cx="208.27" cy="525.58" r="3.12" fill="#46aa48" />
                        <circle cx="190.31" cy="496.3" r="3.12" fill="#46aa48" />
                    </g>
                    <g id="Banner">
                        <path d="m412.58,435.39l-61.48,9.35v49.4l61.48-9.35-.12-.14-17.7-21.85,17.7-27.23.12-.18Zm-372.14,56.58l61.5-9.35v49.4l-61.5,9.35.12-.18,17.71-27.23-17.71-21.85-.12-.14Z" fill="#88499d" fillRule="evenodd" />
                        <path d="m101.93,521.26v10.76l-.14.02-32.95-3.51.3-2.25,32.79-5.01Zm249.17-37.88v10.76l.12-.02,32.97-13.54-.28-2.17-32.8,4.96Z" fill="#622b7e" fillRule="evenodd" />
                        <path d="m68.85,479.16c105.11-15.98,210.21-31.96,315.32-47.94v49.4c-105.11,15.98-210.21,31.96-315.32,47.94v-49.4Z" fill="#955aa4" fillRule="evenodd" />
                        <text transform="translate(104.72 510.89) rotate(-9.49) scale(1.07 .99) skewX(-8.77)" fill="#fff" fontFamily="Nunito-ExtraBold, Nunito" fontSize="36.1" fontWeight="700">
                            <tspan x="0" y="0">
                                Day Challenge
                            </tspan>
                        </text>
                    </g>
                    <g id="_100_Text" data-name="100 Text">
                        <path id="_1_text" data-name="1 text" d="m99.19,315.27l-26.32,4.64c-7.31,1.29-13.37,8.21-13.37,15.31s6.06,12.1,13.37,10.81l13.16-2.32v106.32c0,8.36,7.1,13.79,15.25,12.35,8.35-1.47,15.25-9.37,15.25-17.73v-115.1c0-10.02-7.31-16.04-17.34-14.28Z" fill="#955aa4" />
                        <path
                            id="_0_text"
                            data-name="0 text"
                            d="m247.57,328.11c-9.88-25.29-32.47-34.74-54.59-30.84-21.8,3.84-44.55,21.29-54.53,50.08-3.3,9.55-5.21,20.35-5.21,32.32v5.01c0,.46,0,.91.01,1.36.05,4.08.32,7.93.8,11.57.32,2.5.74,4.9,1.25,7.2,7.34,33.16,33.11,45.52,57.68,41.19,24.94-4.39,50.47-25.81,57.72-61.54.5-2.48.92-5.02,1.24-7.64.47-3.81.73-7.76.78-11.85,0-.45,0-.9,0-1.36v-5.01c0-11.97-1.88-22.1-5.16-30.49Zm-26.3,54.12c-.37,2.56-.86,5.14-1.5,7.69-3.52,14.14-11.37,27.25-26.79,29.97-15.42,2.72-23.26-7.64-26.79-20.53-.63-2.32-1.13-4.73-1.5-7.16-.6-3.88-.88-7.81-.93-11.54-.02-.46-.02-.91-.02-1.36v-5.01c0-10,1.68-22.44,6.64-32.57,4.47-9.14,11.61-16.41,22.6-18.34,10.99-1.93,18.13,2.81,22.61,10.37,4.96,8.38,6.64,20.22,6.64,30.22v5.02c0,.45,0,.9,0,1.36-.06,3.75-.34,7.79-.95,11.88Z"
                            fill="#955aa4"
                        />
                        <path
                            id="_0_text-2"
                            data-name="0 text"
                            d="m377.51,305.2c-9.88-25.29-32.47-34.74-54.59-30.84-21.81,3.84-44.55,21.28-54.53,50.08-3.31,9.55-5.22,20.35-5.22,32.32v5.01c0,.46,0,.91.01,1.36.05,4.08.32,7.93.8,11.57.32,2.5.74,4.9,1.25,7.2,7.34,33.16,33.11,45.52,57.69,41.19,24.93-4.39,50.46-25.81,57.71-61.54.51-2.48.93-5.03,1.25-7.64.47-3.81.73-7.76.78-11.85,0-.45,0-.9,0-1.36v-5.01c0-11.97-1.88-22.1-5.16-30.49Zm-25.34,40.88c0,.45,0,.9,0,1.36-.06,3.75-.34,7.78-.94,11.87h-.01c-.36,2.56-.86,5.14-1.5,7.69-3.52,14.14-11.37,27.26-26.79,29.98-15.43,2.72-23.27-7.64-26.8-20.53-.63-2.32-1.13-4.73-1.5-7.16-.6-3.88-.88-7.81-.93-11.54-.02-.46-.02-.91-.02-1.36v-5.01c0-10,1.68-22.44,6.64-32.57,4.47-9.13,11.62-16.4,22.61-18.34,10.99-1.94,18.13,2.81,22.61,10.37,4.96,8.38,6.64,20.22,6.64,30.22v5.02Z"
                            fill="#955aa4"
                        />
                        <polygon points="116.53 400.7 116.53 408.12 86.03 413.5 86.03 406.07 116.53 400.7" fill="#a56aac" fillRule="evenodd" />
                        <path d="m164.69,392.2c.37,2.43.87,4.84,1.5,7.16l-30.89,5.45c-.51-2.3-.93-4.7-1.25-7.2l30.64-5.41Z" fill="#a56aac" fillRule="evenodd" />
                        <path d="m221.27,382.23l30.67-5.41c-.32,2.62-.74,5.16-1.24,7.64l-30.93,5.46c.64-2.55,1.13-5.13,1.5-7.69Z" fill="#a56aac" fillRule="evenodd" />
                        <path d="m294.62,369.29c.37,2.43.87,4.84,1.5,7.16l-30.89,5.45c-.51-2.3-.93-4.7-1.25-7.2l30.64-5.41Z" fill="#a56aac" fillRule="evenodd" />
                        <path d="m381.88,353.91c-.32,2.61-.74,5.16-1.25,7.64l-30.92,5.45c.64-2.55,1.14-5.13,1.5-7.69h.01l30.66-5.4Z" fill="#a56aac" fillRule="evenodd" />
                        <polygon points="116.53 351.22 116.53 388.99 86.03 394.36 86.03 356.6 116.53 351.22" fill="#a56aac" fillRule="evenodd" />
                        <path d="m163.74,374.29v5.01c0,.45,0,.9.02,1.36l-30.51,5.38c-.01-.45-.01-.9-.01-1.36v-5.01c0-11.97,1.91-22.77,5.21-32.32l31.93-5.63c-4.96,10.13-6.64,22.57-6.64,32.57Z" fill="#a56aac" fillRule="evenodd" />
                        <path d="m247.57,328.11c3.28,8.39,5.16,18.52,5.16,30.49v5.01c0,.46,0,.91,0,1.36l-30.5,5.38c0-.46,0-.91,0-1.36v-5.02c0-10-1.68-21.84-6.64-30.22l31.98-5.64Z" fill="#a56aac" fillRule="evenodd" />
                        <path d="m293.67,356.39c0,.45,0,.9.02,1.36l-30.51,5.38c-.01-.45-.01-.9-.01-1.36v-5.01c0-11.97,1.91-22.77,5.22-32.32l31.92-5.63c-4.96,10.13-6.64,22.57-6.64,32.57v5.01Z" fill="#a56aac" fillRule="evenodd" />
                        <path d="m382.67,335.69v5.01c0,.46,0,.91,0,1.36l-30.5,5.38c0-.46,0-.91,0-1.36v-5.02c0-10-1.68-21.84-6.64-30.22l31.98-5.64c3.28,8.39,5.16,18.52,5.16,30.49Z" fill="#a56aac" fillRule="evenodd" />
                    </g>
                    <g id="Stars">
                        <polygon points="211.01 218.43 218.59 232.46 235.55 231.93 223.28 246.05 226.18 262.43 211.01 257.13 195.85 267.78 198.74 250.38 186.47 240.59 203.43 235.13 211.01 218.43" fill="#fed007" fillRule="evenodd" />
                        <polygon points="253.89 234.85 257.5 241.53 265.58 241.28 259.74 248.01 261.12 255.82 253.89 253.29 246.66 258.37 248.04 250.08 242.19 245.41 250.27 242.81 253.89 234.85" fill="#fed007" fillRule="evenodd" />
                        <polygon points="281.16 230.04 284.77 236.72 292.86 236.47 287.01 243.2 288.39 251.01 281.16 248.48 273.93 253.56 275.31 245.27 269.46 240.6 277.55 238 281.16 230.04" fill="#fed007" fillRule="evenodd" />
                        <polygon points="142.61 254.47 146.23 261.15 154.31 260.9 148.46 267.63 149.84 275.44 142.61 272.91 135.38 277.99 136.76 269.7 130.92 265.03 139 262.43 142.61 254.47" fill="#fed007" fillRule="evenodd" />
                        <polygon points="169.89 249.66 173.5 256.34 181.58 256.09 175.73 262.82 177.11 270.63 169.89 268.1 162.66 273.18 164.04 264.89 158.19 260.22 166.27 257.62 169.89 249.66" fill="#fed007" fillRule="evenodd" />
                        <line x1="82.47" y1="296.56" x2="340.3" y2="253.8" fill="none" stroke="#fed007" strokeLinecap="round"  strokeWidth="3" />
                    </g>
                </g>
            ) : name === "52 Week Challenge" ? (
                <g id="_52_Week_Challenge" data-name="52 Week Challenge">
                    <g id="Coin">
                        <path d="m393.92,647.65l-4.62,11.07c-3.8,9.1-14.27,14.74-28.74,13.99-22.28-1.17-45.6-17.07-52.08-35.53-2.27-6.47-2.17-12.4-.12-17.32l4.62-11.07c-2.05,4.92-2.15,10.84.12,17.32,6.48,18.45,29.79,34.36,52.08,35.53,14.47.76,24.94-4.89,28.74-13.99Z" fill="#cc7a29" />
                        <polygon
                            points="395.4 641.42 395.08 644.55 394.86 645.48 394.37 647.51 393.27 650.28 391.82 652.85 390.01 655.21 388.04 657.16 387.87 657.33 385.4 659.19 382.63 660.79 379.57 662.11 376.23 663.12 372.63 663.81 368.79 664.17 364.71 664.18 360.51 663.83 356.32 663.13 352.16 662.12 348.06 660.8 344.04 659.19 340.14 657.32 336.37 655.19 332.76 652.83 329.34 650.26 326.14 647.5 323.17 644.56 320.48 641.46 318.07 638.21 315.99 634.85 314.41 631.7 314.25 631.38 312.88 627.83 311.93 624.29 311.42 620.88 311.33 617.6 311.65 614.47 311.7 614.26 312.36 611.51 313.45 608.73 314.91 606.16 315.06 605.97 316.72 603.81 318.24 602.3 318.86 601.69 321.33 599.82 324.1 598.22 327.16 596.91 330.49 595.9 334.09 595.2 337.94 594.85 342.02 594.84 346.22 595.19 350.41 595.88 354.57 596.9 358.67 598.22 362.69 599.82 366.59 601.7 370.36 603.82 373.97 606.18 377.39 608.75 380.59 611.52 383.55 614.46 386.25 617.56 388.65 620.8 390.74 624.16 392.48 627.63 393.85 631.19 394.61 634 394.8 634.72 395.31 638.14 395.34 639.24 395.4 641.42"
                            fill="url(#linear-gradient-2)"
                        />
                        <path
                            d="m344.65,602.88c17.15.71,35.01,13.24,39.82,27.92,4.81,14.69-5.24,26.06-22.39,25.35-17.15-.71-35.01-13.24-39.82-27.93-4.8-14.68,5.24-26.05,22.39-25.34Zm17.14,52.39c16.58.69,26.28-10.31,21.64-24.51-4.64-14.2-21.91-26.31-38.49-26.99-16.58-.69-26.29,10.3-21.64,24.5,4.64,14.2,21.91,26.32,38.49,27"
                            fill="#b16b28"
                        />
                        <polygon points="395.34 639.24 315.06 605.97 316.72 603.81 318.24 602.3 394.61 634 394.8 634.72 395.31 638.14 395.34 639.24" fill="#f8ec22" />
                        <polygon points="394.86 645.48 394.37 647.51 393.27 650.28 391.82 652.85 390.01 655.21 388.04 657.16 314.41 631.7 314.25 631.38 312.88 627.83 311.93 624.29 311.42 620.88 311.33 617.6 311.65 614.47 311.7 614.26 394.86 645.48" fill="#f8ec22" />
                    </g>
                    <g id="Coin-2" data-name="Coin">
                        <path d="m162.17,648.48l5.63,8.74c4.63,7.19,2.78,18.16-6.18,29.4-13.81,17.32-39.28,28.74-56.89,25.51-6.18-1.13-10.57-3.92-13.07-7.8l-5.63-8.74c2.5,3.89,6.89,6.67,13.07,7.8,17.61,3.23,43.08-8.19,56.89-25.51,8.96-11.24,10.81-22.21,6.18-29.4Z" fill="#cc7a29" />
                        <polygon
                            points="165.92 657.74 165.9 660.57 165.49 663.51 164.7 666.56 163.52 669.7 161.95 672.9 159.99 676.14 157.62 679.42 154.92 682.62 151.97 685.66 148.8 688.53 145.43 691.21 141.89 693.69 138.21 695.97 134.43 698.02 130.57 699.83 126.66 701.4 122.72 702.7 118.79 703.73 114.9 704.48 111.07 704.92 107.34 705.06 103.73 704.87 103.29 704.8 103.28 704.8 100.26 704.35 97.06 703.5 94.2 702.35 91.68 700.92 89.52 699.24 88.28 697.93 87.71 697.32 86.27 695.18 85.18 692.84 85.01 692.25 84.46 690.32 84.12 687.64 84.13 686.34 84.15 684.81 84.55 681.86 85.34 678.81 86.52 675.68 88.09 672.48 90.06 669.23 92.42 665.96 95.12 662.76 98.07 659.72 101.25 656.85 104.62 654.17 108.15 651.69 111.83 649.41 115.61 647.36 119.47 645.55 123.39 643.98 127.32 642.68 131.25 641.65 135.14 640.9 138.97 640.45 142.7 640.32 146.32 640.51 147.02 640.61 149.78 641.03 151.25 641.42 152.98 641.88 155.84 643.03 158.36 644.45 158.55 644.6 160.52 646.14 162.33 648.06 163.78 650.2 164.86 652.54 165.4 654.41 165.58 655.06 165.92 657.74"
                            fill="url(#linear-gradient-3)"
                        />
                        <path
                            d="m99.98,667.53c10.49-13.41,30.26-22.01,44.06-19.16,13.81,2.85,16.52,16.08,6.03,29.49-10.49,13.41-30.26,22.01-44.07,19.16-13.81-2.85-16.51-16.08-6.02-29.49Zm49.26,10.16c10.14-12.96,7.52-25.75-5.83-28.51-13.35-2.75-32.46,5.55-42.6,18.52-10.14,12.96-7.52,25.75,5.83,28.51,13.35,2.75,32.47-5.55,42.61-18.51"
                            fill="#b16b28"
                        />
                        <polygon points="165.4 654.41 103.29 704.8 103.28 704.8 100.26 704.35 97.06 703.5 94.2 702.35 91.68 700.92 89.52 699.24 88.28 697.93 158.55 644.6 160.52 646.14 162.33 648.06 163.78 650.2 164.86 652.54 165.4 654.41" fill="#f8ec22" />
                        <polygon points="151.25 641.42 85.01 692.25 84.46 690.32 84.12 687.64 84.13 686.34 147.02 640.61 149.78 641.03 151.25 641.42" fill="#f8ec22" />
                    </g>
                    <g id="Coin-3" data-name="Coin">
                        <path d="m116.41,138.49l7.58,5.53c6.23,4.55,7.81,15.45,3.13,29.78-7.22,22.08-26.55,43.95-43.19,48.84-5.83,1.72-10.56,1.12-13.93-1.34l-7.58-5.53c3.37,2.46,8.1,3.06,13.93,1.34,16.63-4.89,35.97-26.76,43.19-48.84,4.68-14.34,3.11-25.24-3.13-29.78Z" fill="#cc7a29" />
                        <polygon
                            points="123.97 153.99 123.84 157.4 123.39 161.04 122.59 164.9 121.45 168.96 119.98 173.11 118.24 177.22 116.26 181.27 114.04 185.25 111.62 189.11 109.02 192.84 106.25 196.41 103.34 199.8 100.31 202.99 97.19 205.94 94 208.64 90.75 211.07 87.47 213.19 84.18 214.98 80.91 216.42 80.79 216.46 80.78 216.46 77.67 217.48 74.57 218.13 71.68 218.35 69.02 218.16 67.93 217.9 66.6 217.58 64.43 216.62 62.51 215.3 62.12 214.9 60.85 213.63 59.47 211.64 58.38 209.32 58.38 209.3 57.57 206.72 57.07 203.82 56.88 200.67 57.01 197.26 57.47 193.62 58.26 189.76 59.41 185.7 60.87 181.55 62.61 177.43 64.6 173.38 66.81 169.41 69.23 165.55 71.84 161.82 74.61 158.24 77.51 154.85 80.54 151.67 83.66 148.71 86.86 146.01 90.11 143.59 93.39 141.47 96.67 139.68 99.94 138.24 102.07 137.54 103.18 137.17 106.29 136.53 109.03 136.31 109.17 136.3 111.83 136.49 113.63 136.92 114.25 137.07 116.43 138.03 118.35 139.35 120 141.02 121.38 143.02 122.48 145.33 122.74 146.17 123.28 147.94 123.78 150.83 123.97 153.99"
                            fill="url(#linear-gradient-4)"
                        />
                        <path
                            d="m66.6,183.76c5.4-17.01,20.49-33.73,33.63-37.28,13.14-3.55,19.44,7.41,14.03,24.42-5.4,17.01-20.49,33.73-33.63,37.28-13.14,3.54-19.43-7.41-14.03-24.42Zm46.87-12.64c5.22-16.44-.87-27.03-13.57-23.6-12.7,3.43-27.29,19.59-32.51,36.04-5.22,16.44.86,27.03,13.56,23.61,12.7-3.43,27.29-19.59,32.52-36.04"
                            fill="#b16b28"
                        />
                        <polygon points="122.74 146.17 80.79 216.46 80.78 216.46 77.67 217.48 74.57 218.13 71.68 218.35 69.02 218.16 67.93 217.9 113.63 136.92 114.25 137.07 116.43 138.03 118.35 139.35 120 141.02 121.38 143.02 122.48 145.33 122.74 146.17" fill="#f8ec22" />
                        <polygon points="109.03 136.31 62.12 214.9 60.85 213.63 59.47 211.64 58.38 209.32 58.38 209.3 102.07 137.54 103.18 137.17 106.29 136.53 109.03 136.31" fill="#f8ec22" />
                    </g>
                    <g id="Coin-4" data-name="Coin">
                        <path d="m409.25,542.92l2.11,4.67c1.73,3.84.04,9.05-5.15,13.88-7.99,7.44-21.24,11.15-29.58,8.3-2.93-1-4.87-2.68-5.8-4.75l-2.11-4.67c.94,2.08,2.87,3.75,5.8,4.75,8.35,2.85,21.59-.86,29.58-8.3,5.19-4.83,6.88-10.04,5.15-13.88Z" fill="#cc7a29" />
                        <polygon
                            points="410.43 546.37 410.41 547.7 410.19 549.08 409.77 550.48 409.17 551.91 408.36 553.35 407.37 554.8 406.17 556.24 404.78 557.66 403.24 559.03 401.58 560.29 399.82 561.46 397.98 562.52 396.08 563.48 394.12 564.32 392.13 565.04 390.11 565.65 388.09 566.12 386.08 566.47 384.09 566.69 383.54 566.71 382.14 566.77 380.24 566.71 378.41 566.5 376.66 566.15 375.01 565.64 374.22 565.3 373.51 565 372.2 564.23 371.08 563.35 370.55 562.8 370.15 562.37 369.41 561.31 369.06 560.58 368.86 560.16 368.5 558.94 368.33 557.66 368.36 556.32 368.58 554.95 368.99 553.54 369.6 552.11 370.4 550.67 371.4 549.23 372.59 547.79 373.98 546.36 375.53 545 377.19 543.73 378.95 542.56 380.78 541.5 382.69 540.55 384.64 539.71 386.64 538.98 388.65 538.38 390.67 537.9 392.64 537.56 392.69 537.55 394.68 537.33 396.42 537.26 396.63 537.25 398.52 537.31 400.35 537.52 401.57 537.76 402.1 537.87 403.75 538.38 405.25 539.03 406.56 539.8 407.68 540.67 408.62 541.65 409.09 542.33 409.36 542.72 409.91 543.87 410.27 545.09 410.43 546.37"
                            fill="url(#linear-gradient-5)"
                        />
                        <path
                            d="m377.55,547.68c6.09-5.78,16.35-8.53,22.87-6.13,6.53,2.39,6.88,9.04.79,14.81-6.09,5.78-16.35,8.53-22.87,6.13-6.52-2.39-6.88-9.04-.79-14.81Zm23.27,8.54c5.88-5.58,5.54-12.01-.77-14.32-6.31-2.31-16.23.35-22.11,5.93s-5.54,12.01.77,14.32c6.31,2.31,16.23-.35,22.11-5.93"
                            fill="#b16b28"
                        />
                        <polygon points="409.09 542.33 383.54 566.71 382.14 566.77 380.24 566.71 378.41 566.5 376.66 566.15 375.01 565.64 374.22 565.3 401.57 537.76 402.1 537.87 403.75 538.38 405.25 539.03 406.56 539.8 407.68 540.67 408.62 541.65 409.09 542.33" fill="#f8ec22" />
                        <polygon points="396.42 537.26 370.55 562.8 370.15 562.37 369.41 561.31 369.06 560.58 392.64 537.56 392.69 537.55 394.68 537.33 396.42 537.26" fill="#f8ec22" />
                    </g>
                    <g id="Coin-5" data-name="Coin">
                        <path d="m283.38,690.04l3.09,3.23c2.55,2.65,2.38,7.89-1,14.2-5.21,9.72-16.08,18.31-24.28,19.19-2.88.31-5.04-.37-6.42-1.81l-3.09-3.23c1.38,1.43,3.54,2.12,6.42,1.81,8.2-.88,19.07-9.47,24.28-19.19,3.38-6.31,3.54-11.54,1-14.2Z" fill="#cc7a29" />
                        <polygon
                            points="285.82 695.04 285.81 696.44 285.64 697.93 285.3 699.51 284.78 701.18 284.09 702.91 283.22 704.72 282.19 706.53 281.04 708.31 279.77 710.04 278.4 711.71 276.95 713.31 275.42 714.83 273.83 716.27 272.19 717.61 270.5 718.84 268.8 719.96 267.08 720.95 265.35 721.81 263.64 722.53 262.45 722.92 261.95 723.08 260.3 723.48 258.7 723.7 257.19 723.74 255.82 723.6 254.59 723.29 253.92 722.99 253.5 722.81 252.56 722.18 251.77 721.4 251.72 721.34 251.72 721.33 251.13 720.48 250.65 719.43 250.64 719.39 250.33 718.25 250.17 716.97 250.17 715.57 250.34 714.08 250.68 712.49 251.2 710.83 251.89 709.09 252.76 707.29 253.79 705.47 254.95 703.7 256.21 701.97 257.58 700.3 259.03 698.7 260.56 697.17 262.16 695.74 263.8 694.4 265.48 693.16 267.19 692.04 268.91 691.05 270.63 690.19 272.34 689.48 272.7 689.36 274.03 688.92 275.68 688.53 277.29 688.3 278.23 688.28 278.8 688.26 280.17 688.4 281.4 688.72 282.49 689.19 283.24 689.7 283.42 689.83 284.21 690.61 284.85 691.53 285.33 692.58 285.66 693.75 285.82 695.04"
                            fill="url(#linear-gradient-6)"
                        />
                        <path
                            d="m256.29,707c3.94-7.5,12.39-14.04,18.84-14.59s8.5,5.11,4.56,12.61-12.39,14.04-18.84,14.59c-6.45.54-8.49-5.11-4.55-12.61Zm23.01-1.94c3.81-7.25,1.83-12.72-4.41-12.19s-14.41,6.85-18.21,14.1c-3.81,7.25-1.83,12.72,4.4,12.19,6.24-.53,14.41-6.85,18.22-14.1"
                            fill="#b16b28"
                        />
                        <polygon points="283.24 689.7 262.45 722.92 261.95 723.08 260.3 723.48 258.7 723.7 257.19 723.74 255.82 723.6 254.59 723.29 253.92 722.99 278.23 688.28 278.8 688.26 280.17 688.4 281.4 688.72 282.49 689.19 283.24 689.7" fill="#f8ec22" />
                        <polygon points="272.7 689.36 251.72 721.33 251.13 720.48 250.65 719.43 250.64 719.39 269.11 690.95 270.63 690.19 272.34 689.48 272.7 689.36" fill="#f8ec22" />
                    </g>
                    <g id="Coin-6" data-name="Coin">
                        <path d="m73.53,584.98l5.38,2.96c4.42,2.43,6.35,9.35,4.51,19-2.83,14.86-13.55,30.56-23.92,35.07-3.64,1.58-6.75,1.58-9.14.26l-5.38-2.96c2.39,1.31,5.5,1.32,9.14-.26,10.38-4.51,21.09-20.22,23.92-35.07,1.84-9.65-.09-16.57-4.51-19Z" fill="#cc7a29" />
                        <polygon
                            points="79.94 598.98 79.75 601.54 79.34 604.26 78.74 607.06 77.96 609.86 77.01 612.64 75.9 615.39 74.66 618.08 73.28 620.7 71.78 623.24 70.18 625.67 68.49 627.97 66.71 630.13 64.86 632.14 63.72 633.24 62.96 633.97 61.01 635.6 59.03 637.03 57.03 638.23 55.02 639.18 53.06 639.85 52.69 639.92 51.2 640.22 49.46 640.32 48.36 640.2 47.85 640.14 46.36 639.7 45.2 639.11 45 639 43.79 638.05 42.73 636.87 41.83 635.47 41.09 633.85 40.52 632.02 40.13 629.99 39.93 627.78 39.92 625.39 40.12 622.83 40.52 620.11 41.12 617.31 41.91 614.51 42.86 611.73 43.96 608.99 45.21 606.29 46.59 603.67 48.08 601.14 49.68 598.71 51.38 596.4 53.16 594.24 55 592.24 56.91 590.41 58.86 588.77 60.33 587.71 60.84 587.35 62.84 586.15 63.62 585.78 64.85 585.2 66.81 584.53 68.66 584.15 69.09 584.13 70.4 584.06 72.02 584.24 73.51 584.68 74.86 585.38 75.95 586.23 76.07 586.32 77.14 587.5 78.04 588.91 78.78 590.53 79.34 592.35 79.73 594.38 79.93 596.59 79.94 598.98"
                            fill="url(#linear-gradient-7)"
                        />
                        <path
                            d="m45.02,618.28c2.08-11.43,10.47-23.47,18.69-26.82,8.22-3.36,13.22,3.21,11.14,14.65s-10.47,23.47-18.69,26.83c-8.22,3.36-13.22-3.21-11.13-14.65Zm29.33-11.98c2.01-11.05-2.82-17.4-10.77-14.15-7.95,3.25-16.05,14.88-18.07,25.93-2.01,11.05,2.82,17.4,10.76,14.16,7.95-3.25,16.06-14.88,18.07-25.93"
                            fill="#b16b28"
                        />
                        <polygon points="75.95 586.23 63.72 633.24 62.96 633.97 61.01 635.6 59.03 637.03 57.03 638.23 55.02 639.18 53.06 639.85 52.69 639.92 69.09 584.13 70.4 584.06 72.02 584.24 73.51 584.68 74.86 585.38 75.95 586.23" fill="#f8ec22" />
                        <polygon points="63.62 585.78 48.36 640.2 47.85 640.14 46.36 639.7 45.2 639.11 60.33 587.71 60.84 587.35 62.84 586.15 63.62 585.78" fill="#f8ec22" />
                    </g>
                    <g id="Coin-7" data-name="Coin">
                        <path d="m396.6,173.64l-3.64,3.79c-3,3.12-8.61,3.49-15.18.4-10.13-4.77-18.73-15.96-19.2-25-.17-3.17.69-5.66,2.31-7.34l3.64-3.79c-1.62,1.68-2.48,4.17-2.31,7.34.47,9.04,9.07,20.23,19.2,25,6.58,3.1,12.19,2.72,15.18-.4Z" fill="#cc7a29" />
                        <polygon
                            points="398.81 166.67 398.77 168.34 398.7 168.84 398.54 169.89 398.13 171.29 398.09 171.39 397.56 172.55 396.83 173.66 395.94 174.63 394.92 175.43 394.88 175.45 393.76 176.08 392.48 176.56 391.09 176.88 389.59 177.02 388 176.99 386.32 176.78 384.56 176.38 383.92 176.17 382.74 175.79 380.85 175.02 378.96 174.06 377.12 172.97 375.34 171.74 373.62 170.39 371.99 168.94 370.44 167.4 368.99 165.78 367.64 164.09 366.42 162.35 365.31 160.57 364.35 158.76 363.52 156.93 362.86 155.1 362.35 153.28 362.02 151.48 361.91 150.11 361.88 149.71 361.92 148.04 362.15 146.5 362.56 145.09 363.13 143.83 363.87 142.72 364.39 142.15 364.75 141.76 365.77 140.95 366.93 140.3 368.21 139.82 368.33 139.79 369.6 139.5 371.1 139.36 371.41 139.37 372.69 139.39 374.37 139.6 376.13 140 377.96 140.59 379.84 141.37 381.73 142.32 383.57 143.42 385.36 144.64 387.07 145.99 388.71 147.44 390.25 148.98 391.7 150.6 393.05 152.29 394.28 154.03 395.38 155.81 396.35 157.62 397.17 159.45 397.83 161.28 398.34 163.1 398.67 164.9 398.81 166.67"
                            fill="url(#linear-gradient-8)"
                        />
                        <path
                            d="m379.96,145.27c7.83,3.59,14.36,12.31,14.58,19.44.21,7.13-5.98,10.01-13.8,6.41s-14.36-12.31-14.58-19.44c-.21-7.12,5.98-10,13.8-6.41Zm.76,25.42c7.56,3.47,13.55.69,13.34-6.2-.21-6.89-6.53-15.32-14.09-18.79s-13.55-.69-13.34,6.2c.21,6.89,6.53,15.32,14.09,18.79"
                            fill="#b16b28"
                        />
                        <polygon
                            points="394.88 175.45 393.76 176.08 392.48 176.56 391.09 176.88 389.59 177.02 388 176.99 386.32 176.78 384.56 176.38 383.92 176.17 361.91 150.11 361.88 149.71 361.92 148.04 362.15 146.5 362.56 145.09 363.13 143.83 363.87 142.72 364.39 142.15 394.88 175.45"
                            fill="#f8ec22"
                        />
                        <polygon points="398.7 168.84 398.54 169.89 398.13 171.29 398.09 171.39 368.33 139.79 369.6 139.5 371.1 139.36 371.41 139.37 398.7 168.84" fill="#f8ec22" />
                    </g>
                    <g id="Coin-8" data-name="Coin">
                        <path d="m71.29,259.77l-2.24,4.76c-1.84,3.91-7.04,6.06-14.26,5.23-11.13-1.28-22.85-9.14-26.19-17.55-1.17-2.95-1.15-5.58-.16-7.69l2.24-4.76c-1,2.11-1.01,4.74.16,7.69,3.34,8.41,15.06,16.27,26.19,17.55,7.22.83,12.42-1.32,14.26-5.23Z" fill="#cc7a29" />
                        <polygon
                            points="72 257.05 71.85 258.43 71.53 259.66 71.51 259.72 70.99 260.92 70.27 262.01 69.38 262.99 68.33 263.86 67.11 264.6 66.18 265.02 65.74 265.22 64.22 265.7 62.56 266.03 60.76 266.22 58.85 266.25 56.81 266.11 54.71 265.81 52.62 265.36 50.54 264.77 48.48 264.04 46.47 263.19 44.51 262.23 42.62 261.16 40.8 259.99 39.08 258.73 37.47 257.39 35.97 255.98 34.61 254.51 33.39 252.99 32.33 251.43 31.45 249.83 30.74 248.21 30.25 246.6 30.22 246.45 29.97 245.07 29.91 243.61 30.05 242.23 30.39 240.94 30.81 240.01 30.92 239.75 31.63 238.65 32.52 237.67 33.58 236.8 33.99 236.55 34.8 236.06 36.17 235.44 36.97 235.19 37.69 234.96 39.35 234.63 41.14 234.44 43.06 234.41 45.1 234.55 47.19 234.85 49.29 235.3 51.37 235.89 53.42 236.62 55.44 237.47 57.39 238.43 59.29 239.51 61.1 240.68 62.82 241.93 64.44 243.27 65.93 244.68 67.29 246.15 68.51 247.67 69.57 249.23 70.46 250.83 71.15 252.44 71.16 252.46 71.66 254.06 71.83 255.04 71.93 255.59 72 257.05"
                            fill="url(#linear-gradient-9)"
                        />
                        <path
                            d="m46.45,238.21c8.56.9,17.55,7.07,20.03,13.75,2.48,6.69-2.46,11.39-11.03,10.49s-17.55-7.07-20.03-13.76,2.47-11.39,11.03-10.49Zm8.85,23.84c8.28.87,13.06-3.68,10.66-10.14-2.4-6.46-11.08-12.43-19.36-13.3s-13.06,3.68-10.66,10.14c2.4,6.46,11.08,12.43,19.36,13.3"
                            fill="#b16b28"
                        />
                        <polygon points="71.53 259.66 71.51 259.72 70.99 260.92 70.27 262.01 69.38 262.99 68.33 263.86 67.11 264.6 66.18 265.02 30.22 246.45 29.97 245.07 29.91 243.61 30.05 242.23 30.39 240.94 30.81 240.01 71.53 259.66" fill="#f8ec22" />
                        <polygon points="71.83 255.04 33.99 236.55 34.8 236.06 36.17 235.44 36.97 235.19 71.15 252.44 71.16 252.46 71.66 254.06 71.83 255.04" fill="#f8ec22" />
                    </g>
                    <g id="Stars-2" data-name="Stars">
                        <polygon points="226.34 558.98 233.17 572.21 249.02 572.02 237.31 585 239.72 600.34 225.65 595.13 211.29 604.8 214.31 588.6 203.02 579.23 218.96 574.44 226.34 558.98" fill="#fed007" fillRule="evenodd" />
                        <polygon points="266.52 552.68 269.78 558.99 277.33 558.9 271.75 565.08 272.9 572.4 266.19 569.91 259.35 574.52 260.79 566.8 255.41 562.33 263.01 560.05 266.52 552.68" fill="#fed007" fillRule="evenodd" />
                        <polygon points="292.08 548.67 295.34 554.98 302.89 554.89 297.31 561.07 298.46 568.39 291.75 565.9 284.91 570.51 286.35 562.79 280.97 558.33 288.57 556.04 292.08 548.67" fill="#fed007" fillRule="evenodd" />
                        <polygon points="162.24 569.02 165.49 575.33 173.05 575.24 167.46 581.43 168.61 588.74 161.91 586.26 155.06 590.87 156.5 583.15 151.12 578.68 158.72 576.4 162.24 569.02" fill="#fed007" fillRule="evenodd" />
                        <polygon points="187.8 565.02 191.05 571.33 198.61 571.24 193.02 577.42 194.17 584.74 187.47 582.25 180.62 586.86 182.06 579.14 176.68 574.67 184.28 572.39 187.8 565.02" fill="#fed007" fillRule="evenodd" />
                        <line x1="106.03" y1="569.36" x2="347.62" y2="534.01" fill="none" stroke="#fed007" strokeLinecap="round"  strokeWidth="3" />
                    </g>
                    <g id="Banner-2" data-name="Banner">
                        <path d="m412.58,452.96l-61.48,9.35v49.4l61.48-9.35-.12-.14-17.7-21.85,17.7-27.23.12-.18Zm-372.14,56.58l61.5-9.35v49.4l-61.5,9.35.12-.18,17.71-27.23-17.71-21.85-.12-.14Z" fill="#48b649" fillRule="evenodd" />
                        <path d="m101.93,538.84v10.76l-.14.02-32.95-3.51.3-2.25,32.79-5.01Zm249.17-37.88v10.76l.12-.02,32.97-13.54-.28-2.17-32.8,4.96Z" fill="#369043" fillRule="evenodd" />
                        <path d="m68.85,496.74c105.11-15.98,210.21-31.96,315.32-47.94v49.4c-105.11,15.98-210.21,31.96-315.32,47.94v-49.4Z" fill="#6cbe4f" fillRule="evenodd" />
                        <text transform="translate(83.77 532.89) rotate(-9.49) scale(1.07 .99) skewX(-8.77)" fill="#fff" fontFamily="Nunito-ExtraBold, Nunito" fontSize="36.1" fontWeight="700">
                            <tspan x="0" y="0" letterSpacing="-.06em">
                                W
                            </tspan>
                            <tspan x="38.09" y="0">
                                eek Challenge
                            </tspan>
                        </text>
                    </g>
                    <g id="_52_Text" data-name="52 Text">
                        <path
                            id="_5"
                            data-name="5"
                            d="m234.56,374.35c-.53-5.61-1.64-10.8-3.25-15.55-8.88-26.24-33.05-39.04-61.04-34.1-19.43,3.43-29.87,13.1-30.16,10.83l1.25-13.25,2.23-23.61,55.1-9.72c9.86-1.74,18.27-11.34,18.27-21.49s-8.41-16.5-18.27-14.76l-69.02,12.17c-11.89,2.1-21.17,11.86-22.62,24l-4.76,39.67-2.78,23.14c-1.11,10.91,2.49,19.81,9.6,24.07,4.05,2.43,9.23,3.35,15.34,2.27,5.49-.97,13.56-3.83,17.35-6.81.02,0,.03-.02.05-.03,8.12-5.2,14.21-7.72,20.01-8.75,6.52-1.15,13.68-.31,19.45,3.3,4.6,2.87,8.32,7.51,10.11,14.31.76,2.85,1.18,6.09,1.18,9.74,0,1.93-.1,3.77-.3,5.53-2.52,22.62-20.38,31.76-32.76,33.94-12.76,2.25-21.75-1.96-27.55-7.61-2.9-3.26-8.99-5.95-15.66-4.78-11.31,2-19.14,11.79-19.14,21.94,0,6.67,3.77,12.09,6.67,14.48,13.63,12.1,33.35,16.16,56.55,12.07,40.54-7.15,70.61-38.23,74.18-75.57.23-2.46.35-4.95.35-7.46,0-2.74-.13-5.4-.38-7.97Z"
                            fill="#76c050"
                        />
                        <path
                            id="_2"
                            data-name="2"
                            d="m321.65,363.55c.24-.27.48-.54.71-.8,6.19-6.99,11.77-13.23,16.77-18.9,23.19-26.29,34.01-40.35,35.19-58.9.07-1.15.11-2.31.11-3.5,0-32.19-27.26-56.96-69.31-49.54-33.93,5.98-55.39,29.19-63.51,52.37-2.41,8.86-1.77,16.64,1.6,21.68,3.12,4.66,8.57,6.98,16.09,5.65,7.17-1.26,13.12-5.63,17.11-10.97,2.15-2.86,3.73-6,4.64-9.1,5.51-16.63,18.56-21.54,26.1-22.87,8.7-1.54,24.36.05,24.36,18.03,0,1.75-.17,3.47-.53,5.2-2.64,12.75-15.43,26.17-44.9,59.31h-.01c-2.25,2.53-4.6,5.19-7.05,7.96l-9.67,10.58-16.07,17.58-5.87,6.42c-21.75,25.29-6.67,54.53,23.49,49.22l84.97-14.99c10.15-1.79,18.27-11.34,18.27-22.07s-8.12-17.13-18.27-15.34l-69.6,12.28,20.24-22.48,15.14-16.82Z"
                            fill="#76c050"
                        />
                        <path d="m231.31,358.8l-50,6.93-39.51,5.48-32.69,4.54c-7.11-4.26-10.71-13.16-9.6-24.07l2.78-23.14,39.07-6.26-1.25,13.25c.29,2.27,10.73-7.4,30.16-10.83,27.99-4.94,52.16,7.86,61.04,34.1Z" fill="#9ecf75" fillRule="evenodd" />
                        <path d="m243.21,305.96l33.2-5.32c-3.99,5.34-9.94,9.71-17.11,10.97-7.52,1.33-12.97-.99-16.09-5.65Z" fill="#9ecf75" fillRule="evenodd" />
                        <path d="m374.32,284.95c-1.18,18.55-12,32.61-35.19,58.9l-53.05,7.36c29.47-33.14,42.26-46.56,44.9-59.31l43.34-6.95Z" fill="#9ecf75" fillRule="evenodd" />
                        <path d="m234.56,374.35c.25,2.57.38,5.23.38,7.97,0,2.51-.12,5-.35,7.46l-42.29,5.53c.2-1.76.3-3.6.3-5.53,0-3.65-.42-6.89-1.18-9.74l43.14-5.69Z" fill="#9ecf75" fillRule="evenodd" />
                        <path d="m322.36,362.75c-.23.26-.47.53-.71.8l-15.14,16.82-53.23,6.96,16.07-17.58,53.01-7Z" fill="#9ecf75" fillRule="evenodd" />
                    </g>
                    <g id="Stars-3" data-name="Stars">
                        <polygon points="226.16 179.19 233.24 192.29 249.08 191.8 237.62 205 240.33 220.29 226.16 215.34 211.99 225.29 214.7 209.04 203.23 199.89 219.07 194.79 226.16 179.19" fill="#fed007" fillRule="evenodd" />
                        <polygon points="266.22 194.52 269.59 200.77 277.14 200.54 271.68 206.83 272.97 214.12 266.22 211.76 259.46 216.5 260.75 208.75 255.29 204.39 262.84 201.96 266.22 194.52" fill="#fed007" fillRule="evenodd" />
                        <polygon points="291.7 190.03 295.07 196.28 302.62 196.04 297.16 202.33 298.45 209.62 291.7 207.27 284.94 212.01 286.23 204.26 280.77 199.9 288.32 197.47 291.7 190.03" fill="#fed007" fillRule="evenodd" />
                        <polygon points="162.26 212.85 165.64 219.1 173.19 218.87 167.72 225.16 169.01 232.45 162.26 230.09 155.51 234.83 156.8 227.08 151.33 222.72 158.88 220.29 162.26 212.85" fill="#fed007" fillRule="evenodd" />
                        <polygon points="187.74 208.36 191.11 214.61 198.67 214.37 193.2 220.66 194.49 227.96 187.74 225.6 180.98 230.34 182.27 222.59 176.81 218.23 184.36 215.8 187.74 208.36" fill="#fed007" fillRule="evenodd" />
                        <line x1="106.07" y1="252.18" x2="346.95" y2="212.23" fill="none" stroke="#fed007" strokeLinecap="round"  strokeWidth="3" />
                    </g>
                </g>
            ) : (
                <g id="_26_Week_Challenge" data-name="26 Week Challenge">
                    <g id="Dollar_Bill-6" data-name="Dollar Bill">
                        <path
                            d="m112.85,628.98l-15.61,24.71c-.49.77-1.45,1.1-2.31.8-1.66-.57-3.28-1.22-4.86-1.94-1.23-.56-2.44-1.15-3.63-1.79-4.89-2.61-9.43-5.81-13.7-9.16-2.27-1.79-4.46-3.62-6.59-5.42-6.38-5.43-12.15-10.65-17.65-13.88-2.11-1.24-4.18-2.19-6.23-2.75-1.27-.34-1.86-1.82-1.15-2.94l15.54-24.59c.42-.67,1.2-1.03,1.98-.89,2.5.45,5.03,1.54,7.62,3.06,5.49,3.23,11.27,8.45,17.65,13.88,6.11,5.19,12.77,10.58,20.29,14.58,2.45,1.31,4.99,2.47,7.64,3.43,1.18.43,1.68,1.83,1.01,2.89Z"
                            fill="#76c050"
                        />
                        <path
                            d="m100.58,633.69l-5.01,7.93c-2.56-1.62-5.94-.86-7.55,1.7-.11.17-.2.34-.29.52-1.23-.66-2.47-1.39-3.74-2.19-2.44-1.54-4.99-3.37-7.78-5.56-2.96-2.33-5.8-4.76-8.54-7.11-4.28-3.67-8.34-7.15-12.37-9.79l.06-.09c1.62-2.56.85-5.94-1.71-7.55l5-7.91c2.55,1.61,5.93.85,7.55-1.7,3.45,2.28,7.23,5.52,11.21,8.94,5.14,4.41,10.97,9.41,17.47,13.52,1.26.79,2.5,1.53,3.73,2.21-1.25,2.49-.43,5.56,1.97,7.08Z"
                            fill="#a7d276"
                        />
                        <circle cx="76.86" cy="623.8" r="6.49" fill="#46aa48" />
                        <circle cx="88.72" cy="632.37" r="2.68" fill="#46aa48" />
                        <circle cx="65.31" cy="614.41" r="2.68" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-7" data-name="Dollar Bill">
                        <path
                            d="m408.81,711.27l-21.34,12.58c-.67.39-1.52.27-2.05-.29-1.03-1.07-2-2.19-2.91-3.34-.71-.9-1.39-1.82-2.04-2.75-2.68-3.86-4.86-8.03-6.78-12.21-1.02-2.23-1.96-4.46-2.87-6.64-2.71-6.56-5.04-12.73-7.93-17.3-1.11-1.76-2.3-3.27-3.63-4.48-.83-.75-.7-2.09.27-2.66l21.24-12.52c.58-.34,1.31-.31,1.84.1,1.71,1.31,3.19,3.11,4.55,5.26,2.89,4.56,5.22,10.74,7.93,17.3,2.59,6.28,5.53,12.92,9.64,18.85,1.34,1.94,2.81,3.8,4.43,5.55.72.78.55,2.03-.36,2.57Z"
                            fill="#76c050"
                        />
                        <path
                            d="m397.73,710.06l-6.85,4.04c-1.3-2.21-4.14-2.95-6.35-1.64-.15.09-.29.18-.42.28-.67-.98-1.32-2-1.97-3.1-1.24-2.11-2.46-4.47-3.71-7.21-1.33-2.9-2.53-5.84-3.68-8.67-1.81-4.43-3.52-8.63-5.53-12.17l.08-.05c2.21-1.3,2.94-4.15,1.64-6.36l6.84-4.03c1.3,2.21,4.14,2.94,6.35,1.65,1.72,3.06,3.31,6.97,4.99,11.09,2.17,5.32,4.62,11.34,7.93,16.96.64,1.08,1.29,2.12,1.96,3.11-1.91,1.39-2.48,4.03-1.26,6.1Z"
                            fill="#a7d276"
                        />
                        <circle cx="383.68" cy="693.42" r="5.5" fill="#46aa48" />
                        <circle cx="389.3" cy="704.47" r="2.27" fill="#46aa48" />
                        <circle cx="378.61" cy="681.87" r="2.27" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-8" data-name="Dollar Bill">
                        <path
                            d="m189.1,623.92l28.89,40.11c.9,1.26.81,2.97-.2,4.13-1.95,2.25-3.98,4.37-6.11,6.4-1.65,1.58-3.35,3.1-5.1,4.56-7.18,6.03-15.07,11.12-23.02,15.69-4.24,2.44-8.5,4.72-12.67,6.91-12.54,6.58-24.38,12.33-32.93,18.91-3.29,2.53-6.08,5.17-8.24,8.03-1.35,1.78-4.03,1.77-5.34-.05l-28.76-39.92c-.78-1.08-.86-2.54-.15-3.68,2.29-3.64,5.59-6.91,9.61-10.01,8.54-6.57,20.38-12.33,32.93-18.91,12.01-6.3,24.67-13.35,35.69-22.61,3.6-3.02,7.03-6.27,10.21-9.82,1.42-1.58,3.93-1.47,5.17.25Z"
                            fill="#76c050"
                        />
                        <path
                            d="m188.72,646.15l9.28,12.88c-4.15,2.99-5.1,8.77-2.11,12.92.2.28.41.53.63.78-1.82,1.51-3.74,3-5.8,4.48-3.96,2.86-8.43,5.7-13.64,8.69-5.52,3.17-11.13,6.09-16.55,8.9-8.47,4.4-16.49,8.57-23.17,13.22l-.1-.14c-2.99-4.15-8.78-5.08-12.93-2.09l-9.25-12.84c4.14-2.99,5.09-8.77,2.11-12.92,5.76-3.97,13.24-7.85,21.11-11.94,10.17-5.28,21.69-11.26,32.24-18.86,2.04-1.47,3.98-2.95,5.82-4.46,3.11,3.53,8.45,4.19,12.35,1.38Z"
                            fill="#a7d276"
                        />
                        <circle cx="158.22" cy="677.11" r="10.98" fill="#46aa48" />
                        <circle cx="179.16" cy="663.93" r="4.53" fill="#46aa48" />
                        <circle cx="136.21" cy="689.29" r="4.53" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-9" data-name="Dollar Bill">
                        <path
                            d="m373.77,573.04l28.47,18.1c.89.57,1.27,1.67.92,2.67-.66,1.92-1.41,3.78-2.25,5.6-.65,1.42-1.34,2.81-2.08,4.18-3.02,5.64-6.74,10.87-10.62,15.78-2.07,2.62-4.19,5.14-6.28,7.59-6.28,7.35-12.33,13.99-16.08,20.33-1.44,2.43-2.54,4.81-3.19,7.18-.4,1.47-2.1,2.14-3.4,1.32l-28.34-18.02c-.77-.49-1.19-1.39-1.02-2.29.53-2.89,1.79-5.8,3.56-8.78,3.75-6.33,9.8-12.98,16.08-20.33,6.01-7.03,12.25-14.71,16.9-23.37,1.52-2.83,2.87-5.76,3.98-8.81.5-1.36,2.12-1.93,3.34-1.15Z"
                            fill="#76c050"
                        />
                        <path
                            d="m379.16,587.23l9.14,5.81c-1.87,2.95-1.01,6.85,1.94,8.73.2.12.39.24.6.33-.77,1.42-1.61,2.85-2.54,4.31-1.79,2.81-3.9,5.75-6.44,8.96-2.7,3.41-5.51,6.68-8.23,9.83-4.25,4.93-8.28,9.61-11.34,14.25l-.1-.07c-2.95-1.87-6.85-1-8.72,1.95l-9.12-5.8c1.87-2.94,1-6.85-1.93-8.72,2.64-3.97,6.4-8.33,10.36-12.91,5.11-5.92,10.9-12.63,15.66-20.12.92-1.45,1.78-2.88,2.56-4.3,2.86,1.45,6.42.51,8.18-2.25Z"
                            fill="#a7d276"
                        />
                        <circle cx="367.67" cy="614.57" r="7.5" fill="#46aa48" />
                        <circle cx="377.6" cy="600.91" r="3.09" fill="#46aa48" />
                        <circle cx="356.8" cy="627.87" r="3.09" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-10" data-name="Dollar Bill">
                        <path
                            d="m111.07,125.26l24.12,32.72c.76,1.02.7,2.43-.13,3.4-1.58,1.87-3.23,3.63-4.96,5.32-1.34,1.31-2.73,2.58-4.15,3.8-5.85,5.02-12.29,9.28-18.79,13.11-3.47,2.04-6.94,3.95-10.36,5.8-10.25,5.53-19.94,10.36-26.9,15.85-2.68,2.11-4.95,4.3-6.71,6.68-1.09,1.48-3.29,1.49-4.39,0l-24.01-32.56c-.65-.88-.73-2.08-.15-3.02,1.85-3.01,4.53-5.74,7.82-8.32,6.96-5.48,16.65-10.33,26.9-15.85,9.82-5.29,20.16-11.2,29.14-18.91,2.94-2.52,5.72-5.22,8.3-8.17,1.15-1.31,3.22-1.25,4.26.16Z"
                            fill="#76c050"
                        />
                        <path
                            d="m110.96,143.55l7.75,10.51c-3.39,2.5-4.11,7.26-1.61,10.65.17.22.34.44.53.63-1.48,1.26-3.05,2.5-4.73,3.74-3.23,2.38-6.88,4.76-11.14,7.27-4.51,2.66-9.1,5.11-13.53,7.47-6.92,3.69-13.49,7.2-18.93,11.08l-.09-.12c-2.5-3.39-7.26-4.1-10.65-1.6l-7.73-10.48c3.38-2.49,4.11-7.26,1.62-10.64,4.7-3.32,10.81-6.58,17.25-10.01,8.31-4.43,17.73-9.45,26.34-15.8,1.66-1.23,3.25-2.46,4.75-3.72,2.59,2.88,6.99,3.37,10.17,1.02Z"
                            fill="#a7d276"
                        />
                        <circle cx="86.16" cy="169.28" r="9.03" fill="#46aa48" />
                        <circle cx="103.25" cy="158.25" r="3.72" fill="#46aa48" />
                        <circle cx="68.16" cy="179.5" r="3.72" fill="#46aa48" />
                    </g>
                    <g id="Dollar_Bill-11" data-name="Dollar Bill">
                        <path
                            d="m353.16,149.61l19.62-21.32c.61-.67,1.61-.82,2.4-.37,1.52.85,2.98,1.77,4.39,2.75,1.1.76,2.18,1.56,3.22,2.39,4.31,3.41,8.16,7.34,11.73,11.37,1.9,2.15,3.71,4.32,5.46,6.46,5.26,6.43,9.96,12.54,14.74,16.67,1.84,1.59,3.68,2.88,5.58,3.79,1.18.56,1.49,2.1.6,3.07l-19.53,21.22c-.53.58-1.36.79-2.09.52-2.36-.89-4.63-2.39-6.88-4.34-4.78-4.13-9.48-10.25-14.74-16.67-5.03-6.15-10.57-12.59-17.19-17.83-2.16-1.71-4.43-3.3-6.84-4.7-1.08-.63-1.31-2.08-.47-3Z"
                            fill="#76c050"
                        />
                        <path
                            d="m365.97,147.21l6.3-6.85c2.21,2.03,5.64,1.89,7.67-.31.13-.15.26-.3.37-.46,1.08.87,2.16,1.79,3.26,2.8,2.11,1.94,4.27,4.17,6.6,6.81,2.47,2.8,4.81,5.67,7.06,8.45,3.52,4.34,6.86,8.46,10.32,11.74l-.07.08c-2.03,2.21-1.88,5.64.32,7.67l-6.28,6.83c-2.2-2.03-5.63-1.89-7.67.31-2.96-2.84-6.07-6.67-9.34-10.71-4.23-5.22-9.02-11.13-14.63-16.29-1.08-1-2.16-1.94-3.25-2.82,1.66-2.2,1.41-5.35-.66-7.25Z"
                            fill="#a7d276"
                        />
                        <circle cx="387.34" cy="161.07" r="6.44" fill="#46aa48" />
                        <circle cx="377.3" cy="150.6" r="2.65" fill="#46aa48" />
                        <circle cx="396.93" cy="172.27" r="2.65" fill="#46aa48" />
                    </g>
                    <g id="Stars-4" data-name="Stars">
                        <polygon points="226.34 558.98 233.17 572.21 249.02 572.02 237.31 585 239.72 600.34 225.65 595.13 211.29 604.8 214.31 588.6 203.02 579.23 218.96 574.44 226.34 558.98" fill="#fed007" fillRule="evenodd" />
                        <polygon points="266.52 552.68 269.78 558.99 277.33 558.9 271.75 565.08 272.9 572.4 266.19 569.91 259.35 574.52 260.79 566.8 255.41 562.33 263.01 560.05 266.52 552.68" fill="#fed007" fillRule="evenodd" />
                        <polygon points="292.08 548.67 295.34 554.98 302.89 554.89 297.31 561.07 298.46 568.39 291.75 565.9 284.91 570.51 286.35 562.79 280.97 558.33 288.57 556.04 292.08 548.67" fill="#fed007" fillRule="evenodd" />
                        <polygon points="162.24 569.02 165.49 575.33 173.05 575.24 167.46 581.43 168.61 588.74 161.91 586.26 155.06 590.87 156.5 583.15 151.12 578.68 158.72 576.4 162.24 569.02" fill="#fed007" fillRule="evenodd" />
                        <polygon points="187.8 565.02 191.05 571.33 198.61 571.24 193.02 577.42 194.17 584.74 187.47 582.25 180.62 586.86 182.06 579.14 176.68 574.67 184.28 572.39 187.8 565.02" fill="#fed007" fillRule="evenodd" />
                        <line x1="106.03" y1="569.36" x2="347.62" y2="534.01" fill="none" stroke="#fed007" strokeLinecap="round"  strokeWidth="3" />
                    </g>
                    <g id="Banner-3" data-name="Banner">
                        <path d="m412.58,452.96l-61.48,9.35v49.4l61.48-9.35-.12-.14-17.7-21.85,17.7-27.23.12-.18Zm-372.14,56.58l61.5-9.35v49.4l-61.5,9.35.12-.18,17.71-27.23-17.71-21.85-.12-.14Z" fill="#0683a0" fillRule="evenodd" />
                        <path d="m101.93,538.84v10.76l-.14.02-32.95-3.51.3-2.25,32.79-5.01Zm249.17-37.88v10.76l.12-.02,32.97-13.54-.28-2.17-32.8,4.96Z" fill="#006479" fillRule="evenodd" />
                        <path d="m68.85,496.74c105.11-15.98,210.21-31.96,315.32-47.94v49.4c-105.11,15.98-210.21,31.96-315.32,47.94v-49.4Z" fill="#059cbb" fillRule="evenodd" />
                        <text transform="translate(83.77 532.89) rotate(-9.49) scale(1.07 .99) skewX(-8.77)" fill="#fff" fontFamily="Nunito-ExtraBold, Nunito" fontSize="36.1" fontWeight="700">
                            <tspan x="0" y="0" letterSpacing="-.06em">
                                W
                            </tspan>
                            <tspan x="38.09" y="0">
                                eek Challenge
                            </tspan>
                        </text>
                    </g>
                    <g id="_26_Text" data-name="26 Text">
                        <path
                            id="_2-2"
                            data-name="2"
                            d="m205.87,416.56l-68.83,12.14,17.41-19.34,11.36-12.61,6.22-6.91c7.78-8.78,14.6-16.4,20.52-23.17,13.25-15.13,21.99-26.07,26.88-36.56h0c3.25-7,4.79-13.78,4.79-21.45,0-31.83-26.95-56.33-68.54-49-33.55,5.92-54.77,28.87-62.8,51.8-4.59,16.86,2.01,29.75,17.49,27.02,10.9-1.92,18.93-11.08,21.51-19.85,5.45-16.44,18.35-21.3,25.81-22.62,8.6-1.51,24.09.06,24.09,17.84,0,7.11-2.84,13.64-9.64,22.82-6.64,8.97-17.05,20.49-32.27,37.57-3.12,3.51-6.46,7.26-10,11.27l-16.73,18.3-11.64,12.73-2.89,3.16c-21.5,25.01-6.59,53.93,23.23,48.67l84.03-14.82c10.04-1.77,18.07-11.21,18.07-21.82s-8.03-16.94-18.07-15.17Z"
                            fill="#019ebe"
                        />
                        <path
                            id="_6"
                            data-name="6"
                            d="m292.11,352.37l-29.22,4.19-21.3,3.07c-2.13,7.08-3.32,14.32-3.32,21.97,0,1.85.06,3.65.18,5.41l39.21-5.26c-.07-.72-.1-1.46-.1-2.21,0-9.94,5.02-20.61,14.55-27.17Zm0,0l-29.22,4.19-21.3,3.07c-2.13,7.08-3.32,14.32-3.32,21.97,0,1.85.06,3.65.18,5.41l39.21-5.26c-.07-.72-.1-1.46-.1-2.21,0-9.94,5.02-20.61,14.55-27.17Zm78.01-11.21c-4.96-12.95-14.4-22.64-26.06-28.36h-.02c-11.05-5.41-24.09-7.26-37.23-4.94-.58.1-.29-.52.57-2.4l33.27-48.88c2.01-3.5,4.01-7.87,4.01-11.89,0-10.32-7.45-17.32-18.06-15.45-6.03,1.06-11.47,4.89-14.92,10.66l-33.26,53.47c-7.07,11.31-13.94,21.54-19.94,31.31-7.35,11.96-13.39,23.23-16.89,34.95-2.13,7.08-3.32,14.32-3.32,21.97,0,1.85.06,3.65.18,5.41.26,3.78.8,7.37,1.59,10.75,7.4,31.99,36.98,46.12,66.77,40.86,28.57-5.04,56.72-28.4,64.8-58.68.96-3.59,1.64-7.28,1.99-11.05.21-2.09.31-4.2.31-6.34,0-7.86-1.35-15.02-3.79-21.39Zm-40.24,44.43c-4.77,8.25-13.1,14.85-23.65,16.71-11.53,2.04-20.66-2.35-25.31-10.07-1.83-3.04-2.96-6.59-3.26-10.48-.07-.72-.1-1.46-.1-2.21,0-9.94,5.02-20.61,14.55-27.17h.01c3.94-2.73,8.66-4.73,14.11-5.7,5.68-1,10.71-.34,14.89,1.53,8.28,3.66,13.22,11.99,13.22,21.33,0,1.56-.13,3.12-.38,4.68-.62,3.94-2.02,7.81-4.08,11.38Zm-37.77-33.22l-29.22,4.19-21.3,3.07c-2.13,7.08-3.32,14.32-3.32,21.97,0,1.85.06,3.65.18,5.41l39.21-5.26c-.07-.72-.1-1.46-.1-2.21,0-9.94,5.02-20.61,14.55-27.17Zm-29.22,4.19l-21.3,3.07c-2.13,7.08-3.32,14.32-3.32,21.97,0,1.85.06,3.65.18,5.41l39.21-5.26c-.07-.72-.1-1.46-.1-2.21,0-9.94,5.02-20.61,14.55-27.17h.01s-29.23,4.19-29.23,4.19Z"
                            fill="#019ebe"
                        />
                        <path d="m219.43,330.11c-4.89,10.49-13.63,21.43-26.88,36.56l-52.68,7.57c15.22-17.08,25.63-28.6,32.27-37.57l47.29-6.56Z" fill="#0dbbd2" fillRule="evenodd" />
                        <path d="m370.12,341.16l-49,7.04c-4.18-1.87-9.21-2.53-14.89-1.53-5.45.97-10.17,2.97-14.11,5.69h-.01s-29.22,4.2-29.22,4.2l-21.3,3.07c3.5-11.72,9.54-22.99,16.89-34.95l85.56-11.88h.02c11.66,5.72,21.1,15.41,26.06,28.36Z" fill="#0dbbd2" fillRule="evenodd" />
                        <polygon points="165.81 396.75 154.45 409.36 101.5 416.54 113.14 403.81 165.81 396.75" fill="#0dbbd2" fillRule="evenodd" />
                        <path d="m277.66,381.75c.3,3.89,1.43,7.44,3.26,10.48l-40.88,5.53c-.79-3.38-1.33-6.97-1.59-10.75l39.21-5.26Z" fill="#0dbbd2" fillRule="evenodd" />
                        <path d="m373.6,368.89c-.35,3.77-1.03,7.46-1.99,11.05l-41.73,5.65c2.06-3.57,3.46-7.44,4.08-11.38l39.64-5.32Z" fill="#0dbbd2" fillRule="evenodd" />
                    </g>
                    <g id="Stars-5" data-name="Stars">
                        <polygon points="226.16 179.19 233.24 192.29 249.08 191.8 237.62 205 240.33 220.29 226.16 215.34 211.99 225.29 214.7 209.04 203.23 199.89 219.07 194.79 226.16 179.19" fill="#fed007" fillRule="evenodd" />
                        <polygon points="266.22 194.52 269.59 200.77 277.14 200.54 271.68 206.83 272.97 214.12 266.22 211.76 259.46 216.5 260.75 208.75 255.29 204.39 262.84 201.96 266.22 194.52" fill="#fed007" fillRule="evenodd" />
                        <polygon points="291.7 190.03 295.07 196.28 302.62 196.04 297.16 202.33 298.45 209.62 291.7 207.27 284.94 212.01 286.23 204.26 280.77 199.9 288.32 197.47 291.7 190.03" fill="#fed007" fillRule="evenodd" />
                        <polygon points="162.26 212.85 165.64 219.1 173.19 218.87 167.72 225.16 169.01 232.45 162.26 230.09 155.51 234.83 156.8 227.08 151.33 222.72 158.88 220.29 162.26 212.85" fill="#fed007" fillRule="evenodd" />
                        <polygon points="187.74 208.36 191.11 214.61 198.67 214.37 193.2 220.66 194.49 227.96 187.74 225.6 180.98 230.34 182.27 222.59 176.81 218.23 184.36 215.8 187.74 208.36" fill="#fed007" fillRule="evenodd" />
                        <line x1="106.07" y1="252.18" x2="346.95" y2="212.23" fill="none" stroke="#fed007" strokeLinecap="round"  strokeWidth="3" />
                    </g>
                </g>
            )}
            <rect id="Border_Main"  data-name="Border Main"  x="3"     y="83.16" width="447.13" height="680.25" rx="12" ry="12" fill="none" stroke={color.base}  strokeWidth="6"   />
            <rect id="Border_Small" data-name="Border Small" x="18.07" y="98.11" width="417"    height="650.36" rx="12" ry="12" fill="none" stroke={color.base}  strokeWidth=".99" />
            { complete
                ? <g id="Trophy">
                    <path
                        d="m224.44,160.15c66.52-55.08,66.26-49.89,66.26-65.65,0-3.61-.21-7.31-.21-10.43,0-6.19,1.57-13.06,1.57-63.72v-12.3c-22.5-2.61-45.12-5.15-67.63-8.06-22.5,2.91-45.13,5.45-67.63,8.06v12.3c0,50.66,1.57,57.53,1.57,63.72,0,3.12-.21,6.82-.21,10.43,0,15.75-.26,10.56,66.26,65.65Z"
                        fill="#F0F2F2"
                    />
                    <path
                        d="m272.17,26.19h-15.91v-3.18c0-1.75-1.43-3.18-3.18-3.18h-57.28c-1.75,0-3.18,1.43-3.18,3.18v3.18h-15.91c-1.76,0-3.18,1.42-3.18,3.18,0,.42.05,10.3,4.22,20.32,5.32,12.77,14.86,19.95,27.69,20.93,1.82,3.34,3.9,6.73,6.27,10.12,12.96,18.52,0,34.55-12.73,34.55h.38c-1.96,0-3.56,1.43-3.56,3.18s1.6,3.18,3.56,3.18h50.16c1.96,0,3.56-1.43,3.56-3.18s-1.6-3.18-3.56-3.18h.38c-12.73,0-25.69-16.03-12.73-34.55,2.37-3.39,4.45-6.78,6.27-10.12,12.83-.98,22.37-8.16,27.69-20.93,4.17-10.02,4.22-19.9,4.22-20.32,0-1.76-1.42-3.18-3.18-3.18Zm-88.49,21.21c-2.38-5.66-3.27-11.4-3.6-14.84h13.02c.81,6.77,3.01,18.25,8.89,31.15-8.34-1.8-14.49-7.26-18.3-16.31Zm81.5,0c-3.81,9.05-9.95,14.51-18.3,16.31,5.88-12.9,8.07-24.39,8.89-31.15h13.02c-.33,3.44-1.22,9.19-3.6,14.84Z"
                        fill="#FFD500"
                    />
                    <path d="m132.75,92.2l41.82-8.2v29.14l-41.47,8.13,17.65-18.53-18-10.54Zm187.53-24.99l-41.82,7.66v-29.14l41.47-7.59-17.65,18.53,18,10.54Z" fill={color.medium} fillRule="evenodd" />
                    <path d="m174.57,101.05v12.09l-21.68-5.05.15-1.79,21.53-5.24Zm103.88-43.23v-12.09l21.68,5.05-.15,1.79-21.53,5.24Z" fill={color.dark} fillRule="evenodd" />
                    <path
                        d="m255.77,32.56h13.02c-.21,2.13-.63,5.16-1.49,8.5l7.31-3.86c.72-4.45.74-7.59.74-7.82,0-1.76-1.42-3.18-3.18-3.18h-11.6l-60.84,32.17c.69,1.75,1.43,3.53,2.25,5.35-2.59-.56-4.94-1.52-7.11-2.78l-6.41,3.39c4.72,3.66,10.41,5.8,16.97,6.3.43.8.93,1.6,1.4,2.41l45.52-24.07c1.94-6.67,2.94-12.38,3.42-16.4Z"
                        fill="#fee600"
                    />
                    <path d="m300.13,50.78c-68.85,13.35-78.65,14.85-147.25,28.16v29.14c69.14-13.6,78.5-14.95,147.25-28.16v-29.14Z" fill={color.base} fillRule="evenodd" />
                    <path d="m197.61,52.46l58.31-30.83c-.52-1.06-1.6-1.8-2.85-1.8h-6.18l-50.99,26.96c.49,1.81,1.06,3.71,1.7,5.67Z" fill="#fee600" />
                    <path d="m186.06,52l-5.66,2.99c1.02,1.74,2.14,3.34,3.36,4.8l5.82-3.08c-1.3-1.38-2.47-2.96-3.53-4.71Z" fill="#fee600" />
                    <text transform="translate(160.22 100.37) rotate(-11) scale(1.02 .98) skewX(-10.61)" fill="#fff" fontFamily="Nunito-Black, Nunito" fontSize="24.18" fontWeight="800">
                        <tspan x="0" y="0" letterSpacing="-.02em">
                            COMPLETE
                        </tspan>
                    </text>
                </g>
            : <></>
            }
        </svg>
    </div>
    )
}
