import moment from 'moment'

const monthmap = {'january':0,'february':1,'march':2,'april':3,'may':4,'june':5,'july':6,'august':7,'september':8,'october':9,'november':10,'december':11}

export class AmoritizationCalculator{

    constructor({ term, term_display, total, interest, start_years, start_months, paymentHistory=[], linkedBuckets=[] }){
        // Formatting
        term     = parseInt(term)
        // total    = parseFloat(total)
        interest = parseFloat(interest)

        interest = interest / 100 // Expecting interest to be: 10 = 10% so convert to => 0.010

        this.total = total
        this.term = term_display === 'years' ? term * 12 : term;
        this.loan_amount = total;
        this.interest = {
            yearly: interest,
            monthly: interest / 12,
            daily: interest / 365
        }
        this.start_date = moment({
            year: parseInt(start_years),
            month: monthmap[start_months.toLowerCase()],
            day: 1
        })
        this.end_date = moment({
            year: parseInt(start_years),
            month: monthmap[start_months],
            day: 1
        }).add(term, term_display)

        this.paymentHistory = paymentHistory
        this.linkedBuckets = linkedBuckets
    }

    getMonthlyPayment(
        P = this.loan_amount,
        i = this.interest.monthly,
        n = this.term
    ){
        // P, i, n
        // P = Loan Amount
        // i = Annual Interest Rate (0.01) = 1%
        // o = Number of periods (months in our case)

        // Original Formula
        // M = P [ i(1 + i)^n ] / [ (1 + i)^n – 1]

        // Interest rate must be converted
        // From "annual interest rate"
        // To "monthly interest rate"
        // i = i / 12

        // No interest loans, return principal divided by term (months)
        if(i === 0){
            return P / n
        }

        return P *
            ( i * ((1 + i) ** n) )
            /
            ( ((1 + i) ** n) - 1 )
    }

    getSchedule(type, { extraPayments=[], callbackPerStep, forecast }={}){
        // Valid types: 'labels' | 'default'
        // Valid forecast: <object> { extra: 100, interval:'snowball'|'months'|'years' } => Extra fixed number per month or year starting from today

        const date  = moment(this.start_date),
              today = moment(),
              payload = [],
              monthlyPayment = this.getMonthlyPayment(),
              running_totals = {
                  interest:  0, // Interest piad
                  principal: 0, // Principal paid
                  paid:      0, // Balance paid
                  remaining: this.loan_amount, // Remaining principal
              }
        
        for (let index = 0; index < this.term; index++) {
            if(type === 'labels'){ payload.push( moment(date) ) }
            else {
                // Formula: (remaining_principal * interest) / 12 months = Monthly Interest Payment
                let interest  = (running_totals.remaining * this.interest.yearly) / 12 ,
                    principal = monthlyPayment - interest

                // Limit it to 0 if it is paid off! :)
                if(running_totals.remaining <= 0){ running_totals.remaining = 0; principal = 0; interest = 0 }

                // If custom calculation per step, call it here
                if(callbackPerStep){ callbackPerStep({ index, date, running_totals, interest, principal}) }

                // Add in previous "additional principal" payment history
                let additional_principal = 0
                if(extraPayments && extraPayments.length){
                    additional_principal = extraPayments.reduce((sum, arr) => {
                        if( // return all payments made within that day and year
                            moment(arr.date).month() === date.month() &&
                            moment(arr.date).year()  === date.year()
                        ){
                            return sum += parseFloat(arr.value)
                        }
                            return sum;
                    }, 0)
                }

                // Forecasting
                if(forecast && date.isAfter(today)){
                    // if(forecast.interval === 'snowball'){ forecast.extra += 1 }
                    if(
                        forecast.interval === 'snowball' ||
                        forecast.interval === 'monthly'  || 
                        (
                            forecast.interval === 'yearly'   &&
                            date.month() === today().month()
                        )
                    ) {
                        additional_principal += forecast.extra
                    }
                }

                // Don't let principal go past the max principal amount ¯\_(ツ)_/¯
                if ((running_totals.principal + principal + additional_principal) >= this.loan_amount) {
                    running_totals.principal = this.loan_amount
                    running_totals.interest += 0
                    let running_paid = (running_totals.principal) - (principal + additional_principal)
                    if(running_paid < 0){ running_paid = 0 }
                    running_totals.paid      += running_paid
                }
                else {
                    // Add the totals
                    running_totals.interest  += ( interest )
                    running_totals.principal += ( principal + additional_principal )
                    running_totals.paid      += ( principal + additional_principal + interest)
                }


                // Put into payload
                payload.push({
                    date: date.toISOString(),
                    running_totals:{ ...running_totals },
                    interest,
                    principal: (principal + additional_principal),
                })

                // Modify for calculation
                running_totals.remaining -= (principal + additional_principal);
            }

            date.add(1, 'month')
        }

        return payload;
    }

    getDailySchedule(type, { extraPayments=[], callbackPerStep, forecast, onlyReturnLast=false }={}){
        // Valid types: 'labels' | 'default'
        // Valid forecast: <object> { extra: 100, interval:'snowball'|'monthly'|'years' } => Extra fixed number per month or year starting from today

        const date  = moment(this.start_date),
              today = moment(),
              payload = [],
              dailyPayment = this.getMonthlyPayment(this.loan_amount, this.interest.daily, this.term * 30.4166667),
              running_totals = {
                  interest:  0, // Interest paid
                  principal: 0, // Principal paid
                  paid:      0, // Balance paid
                  days_paid: 0,
                  remaining: this.loan_amount, // Remaining principal
              }

        const total_days = Math.floor(this.term * 30.4166667)
        for (let index = 0; index < total_days; index++) {
            if(type === 'labels'){ payload.push( moment(date) ) }
            else {
                // Formula: (remaining_principal * interest) / 30.4166667 days = Daily Interest Payment
                let interest  = (running_totals.remaining * this.interest.daily),
                    principal = dailyPayment - interest

                // if(index < 3){
                //     console.log({
                //         index, interest, principal, dailyPayment
                //     })
                // }

                // Limit it to 0 if it is paid off! :)
                if(running_totals.remaining <= 0){ running_totals.remaining = 0; principal = 0; interest = 0 }

                // If custom calculation per step, call it here
                if(callbackPerStep){ callbackPerStep({ index, date, running_totals, interest, principal}) }

                // Add in previous "additional principal" payment history
                let additional_principal = 0
                if(extraPayments && extraPayments.length && date.date() === 15){ // Guarantees to run this only once per month
                    additional_principal = extraPayments.reduce((sum, arr) => {
                        if( // return all payments made within that day and year
                            moment(arr.date).month() === date.month() &&
                            moment(arr.date).year()  === date.year()
                        ){
                            return sum += parseFloat(arr.value)
                        }
                            return sum;
                    }, 0)
                }

                // Forecasting
                if(forecast && principal > 0){
                    // if(forecast.interval === 'snowball'){ forecast.extra += 1 }
                    if(forecast.interval === 'snowball'){

                    } else if(
                        (
                            forecast.interval === 'monthly'
                            && date.date() === 1
                        )  || 
                        (
                            forecast.interval === 'yearly'
                            && date.month() === today().month()
                        )
                    ){
                        // console.log('adding an extra payment to forecast...')
                        additional_principal += forecast.extra
                    }
                }

                if(principal < 0){ principal = 0; additional_principal = 0 }
                if(additional_principal < 0){ additional_principal = 0 }

                // Add the totals
                if(running_totals.remaining - (principal + additional_principal) > 0){
                    running_totals.interest  += ( interest )
                    running_totals.principal += ( principal + additional_principal )
                    running_totals.paid      += ( principal + additional_principal + interest )
                    running_totals.days_paid += ( principal === 0 ) ? 0 : 1
                }

                // Put into payload
                if(!onlyReturnLast){
                    payload.push({
                        date: date.toISOString(),
                        running_totals:{ ...running_totals },
                        interest,
                        principal: (principal + additional_principal),
                    })
                }
                else if(index === total_days - 1){
                    payload.push({
                        date: date.toISOString(),
                        running_totals:{ ...running_totals },
                        interest,
                        principal: (principal + additional_principal),
                    })
                }

                // Modify for calculation
                if(running_totals.remaining - (principal + additional_principal) > 0){
                    running_totals.remaining -= (principal + additional_principal);
                }
                else{
                    running_totals.remaining = 0
                }
            }

            date.add(1, 'day')
        }

        return payload;
    }

    gatherPaymentHistory(){
        // Format: { date:'ISO', value: '100.00', type: 'guess'|'bucket'|'principal-only'|'verified' }

        const { paymentHistory=[], linkedBuckets=[] } = this;

        let newHistory = [ ...paymentHistory ]

        // Merge linkedBuckets payment history
        linkedBuckets.forEach(({ bucket_name, bucket_id, bucket_data=({history:[]}) }) => {
            if(typeof bucket_data === 'string'){ bucket_data = JSON.parse(bucket_data) }
            let bucket_history = [ ...bucket_data.history ]
            bucket_history.forEach(h => {
                h.type = 'bucket';
                h.bucket_name = bucket_name;
                h.bucket_id = bucket_id;
            })
            newHistory = newHistory.concat(bucket_history)
        })

        // Sort it!
        newHistory = newHistory.sort(function(a,b){ return new Date(b.date) - new Date(a.date) })

        return newHistory||[];
    }
}