import { createContext, useState, useEffect } from "react"
import Loader from "common/Loader.jsx"
import axios from "axios"

import insp from 'inspectlet-es';

const Context = createContext()

export default Context

export function UserProvider({ children }) {
    const [state, setState] = useState({
        data: null,
        loading: true,
    })

    useEffect(function () {
        function setData(d) {
            setState({
                data: [d, setData, refresh],
                loading: false,
            })
            if(d.authorized){
                try{
                    insp(['identify', d.user.email]);
                    // window.__insp.push(['identify', d.user.email]);
                    console.log('[INSP] :: Identified as ' + d.user.email)
                } catch(e){  }
            }
        }
        function refresh() {
            axios
                .post("/api/getSession")
                .catch((e) => { return { data: { user: null, authenticated: false } } })
                .then(({ data }) => { setData(data) })
        }
        refresh()
    }, [])

    if (!state || state.loading) {
        return <Loader />
    }
    return <Context.Provider value={state.data}>{children}</Context.Provider>
}
