import { useState, useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faUndo } from "@fortawesome/free-solid-svg-icons"

import useSound from "use-sound"
import envelope_sound from "audio/Envelope.ogg"

import axios from "axios"

import Loader from "common/Loader.jsx"

import Styles from './_.module.sass'

function getTotal(data){
    let total = Object
        .values(data)
        .reduce((prev, curr) => (curr.checked?1:0) + prev, 0)

    return total * 20
}

export default function YearChallenge({ ...props }) {

    const [state, setState] = useState({
        data: null,
        total: 0
    })

    const [play_envelope_sound] = useSound(envelope_sound)

    // Get the data!
    useEffect(() => {
        axios.post("/api/challenges/challenge_52_weeks/get")
            .catch(console.log)
            .then(({ data:payload }={}) => {
                if (!payload || !payload.data) { return }

                let { id: doc_id, data={} } = payload;

                // console.log({ pre: data })

                for (let i = 0; i < 52; i++) {
                    let d = data[String(i)]||null
                    if(!d){
                        data[String(i)] = {
                            checked: false,
                            date: new Date().toISOString()
                        }
                    }
                }

                // console.log({ post: data })

                setState((state) => ({ ...state, doc_id, data }))
            })
    }, [])

    if (!state.data) {
        return <Loader />
    }

    const total = getTotal(state.data)
    let latest_entry = 0
    for (let i = 0; i < Object.keys(state.data).length; i++) {
        let value = state.data[i];
        if(value && value.checked){ latest_entry = i+1 }
    }

    return (
        <div className="container is-max-fullhd mb-6 pb-6">
            <div className="block columns is-vcentered pt-4 mt-2">
                <div className="column is-6 is-offset-3">
                    <div className="block has-text-centered">
                        <h1 className="has-text-weight-bold is-size-2 is-size-3-mobile">Add $20 this week</h1>
                    </div>
                    { latest_entry !== 0
                        ? <div className={`block box has-text-centered ${Styles.WeekBox}`}>
                            <p className="is-size-2 is-size-3-mobile">Week:</p>
                            <p className={`has-text-primary has-text-weight-bold ${Styles.WeekSlot}`}>{ latest_entry }</p>
                        </div>
                        : <></>
                    }
                    <div className="block buttons is-centered are-medium">
                        { total === 1040 ? (
                            <button className="button is-medium is-warning is-normal-mobile is-fullwidth-mobile pad-bottom mb-0" type="button" onClick={() => {
                                play_envelope_sound()
                                let data = {}
                                for (let i = 0; i < 52; i++) {
                                    data[String(i)] = {
                                        checked: false,
                                        date: new Date().toISOString()
                                    }
                                }
                                setState({ ...state, data })
                                axios.post('/api/challenges/challenge_52_weeks/edit', { data, restart:true }).catch(console.log)
                            }}>
                                <span>Restart Challenge</span>
                            </button>
                        ) : (
                            <button className="button is-primary is-medium is-normal-mobile is-fullwidth-mobile pad-bottom mb-0" type="button" onClick={() => {
                                play_envelope_sound()
                                let data = { ...state.data }
                                data[String(latest_entry)].checked = true
                                setState({ ...state, data })
                                axios.post('/api/challenges/challenge_52_weeks/edit', { data }).catch(console.log)
                            }}>
                                { latest_entry === 0
                                    ? <span>Start Challenge</span>
                                    : <span>Mark As Complete</span>
                                }
                                
                                <span className="icon">
                                    <FontAwesomeIcon icon={faCheck}/>
                                </span>
                            </button>
                        )}
                        { latest_entry > 0
                            ? <button className="button is-info is-outlined is-medium is-normal-mobile is-fullwidth-mobile pad-bottom mb-0" type="button" onClick={() => {
                                play_envelope_sound()
                                let data = { ...state.data }
                                data[String(latest_entry-1)].checked = false
                                setState({ ...state, data })
                                axios.post('/api/challenges/challenge_52_weeks/edit', { data }).catch(console.log)
                            }}>
                                <span>Go Back</span>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faUndo}/>
                                </span>
                            </button>
                            : <></>
                        }
                    </div>
                    { total !== 0
                        ? <h1 className="title is-size-2 is-size-4-mobile has-text-centered" onClick={() => console.log(state)}>
                            Current Total <span className="has-text-primary">${total}</span>
                        </h1>
                        : <></>
                    }
                </div>
            </div>
            {/* <div className="block">
                <p>
                    The concept of the 100 envelope challenge has a bit of history attached to it. At a young age, famous mathematician and physics scholar Carl Friedrich Gauss (1777-1855) invented a new method of summing sequences. The story is told that his math teacher assigned his class to
                    add the numbers 1 to 100, which would normally take some time. However, Gauss quickly figured out that the answer was 5,050 by grouping the numbers into different patterns.
                </p>
            </div> */}
        </div>
    )
// }

}
