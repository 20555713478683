import { useContext } from 'react'

import { prettyNumber } from "utility/numbers"
import { calculateMilestones } from "utility/bucket"
import { useLongPress } from "use-long-press"

import { Context as BucketContext } from '../Bucket.jsx'

import BucketSVGComplex from "../Components/BucketSVGComplex"

import '../sass/BucketOverviewComplex.sass'

const longpressConfig = {
    threshold: 500,
    cancelOnMovement: true,
    detect: "both",
    filterEvents: (e) => e.button !== 2,
}

export default function BucketOverviewPage(){

    const [bucket, , setRootState] = useContext(BucketContext)

    let { history, total, completed, name, completion } = bucket;
    
    total = prettyNumber(total)
    const milestones = calculateMilestones(history, total)

    const bindLongPress = useLongPress(function(){
        setRootState(s => { return { ...s, showDelete: true } })
    }, longpressConfig)

    return <>
        <div className="block is-relative" { ...bindLongPress() }>
            <BucketSVGComplex
                completion={completion}
                number={prettyNumber(completed)}
                milestones={milestones}
                onClick={() => { setRootState(s => { return { ...s, page:'settings' } }) }}
                name={name}
            />
        </div>
        <div className="block has-text-centered myGoal">
            <label className="label is-size-6 is-size-4-mobile has-text-dark m-0">Your Goal:</label>
            <h1 className="title is-size-1 is-size-4-mobile has-text-primary mb-0 has-text-weight-bold">${total}</h1>
        </div>
    </>
}