import { Route } from "react-router-dom"

import BucketList from './BucketList.jsx'
import Bucket from './Bucket.jsx'

import './_.sass'

export default <>
    <Route path="/buckets" element={<BucketList />}/>
    <Route path="/bucket/:id" element={<Bucket />}/>
</>