import moment from "moment"

export function calculateMilestones(history, total) {

    // Sort the history
    history = history.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
    })

    // Round 150.30 or 199.99 to 150.00 and 180.00 (Whole numbers!)
    let rounding_offset = total % 10 // Was: Math.abs(parseFloat(step).toFixed(0) - step)
    rounding_offset = parseFloat(rounding_offset).toFixed(2)

    total = total - rounding_offset

    // Calculate the milestones
    let runningTotal = 0,
        milestones = [],
        step = (total / 10).toFixed(2)

    history.forEach((item) => {
        // Add to a running total
        runningTotal += parseFloat(item.value)

        // We remove milestones as the total climbs over the value
        while (runningTotal >= step) {
            // The first item should offset the other milestones, so it's an even number per milestone.
            if (rounding_offset) {
                runningTotal -= parseFloat(step) + parseFloat(rounding_offset)
                // console.log(parseFloat(step)+ parseFloat(rounding_offset))
                milestones.push({
                    step: "$" + parseFloat(parseFloat(step) + parseFloat(rounding_offset)).toFixed(2),
                    date: moment(item.date).format("MMM Do"),
                })
                rounding_offset = null
            } else {
                runningTotal -= step
                milestones.push({
                    step: "$" + parseFloat(step).toFixed(0),
                    date: moment(item.date).format("MMM Do"),
                })
            }
            // milestones.push(<div key={iterations} className="bucket-rung">
            //     <p>${parseInt(step)}</p>
            //     <p><Moment date={historyItem.date} format="MMM Do"/></p>
            // </div>)
        }
    })

    if (!(milestones.length >= 10) && milestones.length) {
        milestones.push({
            step: "$" + parseFloat(step - runningTotal).toFixed(2),
            date: "To Next Line",
        })
    }

    // if(milestones.length && milestones.length !== 10){
    //     milestones.push(<div key={iterations+1} className="bucket-rung has-text-grey-light">
    //         <p>${parseFloat(Math.round((step - runningTotal.toFixed(2)) * 100) / 100).toFixed(2)}</p>
    //         <p>To Next Milestone</p>
    //     </div>)
    // }

    return milestones //.reverse()
}