import React from 'react';

export default class Dropdown extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            active:false
        }

        this.className = props.className?props.className:'dropdown'

        this.dropdownContent = React.createRef()
        this.closeOnDocumentClick = !props.closeOnDocumentClick
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.handleDropdownItemClick = this.handleDropdownItemClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.dropdownContent.current.addEventListener('click', this.handleDropdownItemClick)
    }
    
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        this.dropdownContent.current.removeEventListener('click', this.handleDropdownItemClick)
    }

    handleClickOutside(event) {
        if (this.dropdownContent.current && !this.dropdownContent.current.contains(event.target)) {
            this.setState({ active:false })
        }
    }

    handleDropdownItemClick(event){
        if (this.dropdownContent.current && this.dropdownContent.current.querySelector('.dropdown-trigger').contains(event.target)) { return }
        this.setState({ active:false })
    }

    render(){
        return <div
                ref={this.dropdownContent}
                className={this.className + (this.state.active?' is-active':'') + (this.props.isRight?' is-right':'')}
            >
            <div className="dropdown-trigger" onClick={() => {
                console.log("Toggling dropdown")
                this.setState({ active:!this.state.active })
            }}>
                {this.props.trigger}
            </div>
            <div className="dropdown-menu">
                <div className="dropdown-content">
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}