import { useNavigate } from "react-router-dom"
import { useLongPress } from "use-long-press"

export default function LongAndShortPress({ to, onClick, onLongPress, state, className, children, ...props }) {

    const navigate = useNavigate()

    const bindLongPress = useLongPress(onLongPress, {
        threshold: 500,
        cancelOnMovement: true,
        detect: "both",
        filterEvents: (e) => {
            if (e.button === 2) {
                return false
            }
            return true
        },
    })

    return (
        <div
            className={className}
            onClick={(e) => {
                if (to) { 
                    return navigate(to, { state: state ? state : null, replace: false })
                }
                if (onClick) { onClick(e) }
            }}
            {...bindLongPress()}
        >
            { children }
        </div>
    )
}