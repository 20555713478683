import { useContext, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import NumbersOnlyInput from 'common/NumbersOnlyInput.jsx'

import { Context as BucketContext } from '../Bucket.jsx'

import CalculatorIcon from "images/icons/calculator_white.svg"

import DatePicker from "react-datepicker"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from "@fortawesome/free-regular-svg-icons"

import HistoryItem from '../Components/HistoryItem.jsx'
import AddHistory from '../Components/AddHistory.jsx'

import axios from 'axios'

export default function BucketOverviewPage(){

    const [bucket, setBucket, setRootState] = useContext(BucketContext)

    const { completion, remaining, completed, loan, type, history, total, name } = bucket

    const [state, setState] = useState({
        showAddItem: false,
        name,
        total,
        activeItem: null
    })
    const { id } = useParams()

    function save(key, close=false){
        let data = { id };
        if(key === "name"){
            data.name = state.name
            setBucket(data)
        }
        if(key === "total"){
            let t = state.total
            if(t === ''){ t = 0 }
            t = parseInt(t)
            if(isNaN(t)){ t = 0 }
            data.total = t
            setBucket(data)
        }
        axios.post('/api/buckets/edit', data).catch(console.log)
        if(close){
            setRootState(s => { return { ...s, page: '' } })
        }
    }

    function Dropdown({ item:{ value: initial_value, date: initial_date } }){

        const [date, setDate] = useState(new Date(initial_date));
        const [valu, setValu] = useState(initial_value);
        
        function saveAndClose(is_delete){
            let hist = [ ...history ]
            let index = hist.findIndex(a => a.id === state.activeItem)

            if(!index !== -1){ 
                if(is_delete){
                    hist = hist.filter(a => a.id !== state.activeItem)
                }
                else {
                    hist[index] = { ...hist[index], value:parseFloat(valu), date: date.toISOString() }
                }

                let new_completed = hist.reduce((prev, curr) => prev += parseFloat(curr.value), 0)

                let completion = parseInt((new_completed / total) * 100)
                if (completion > 100) { completion = 100 }
            
                let remaining = parseFloat(
                    parseFloat(
                        Math.abs(new_completed - total)
                    ).toFixed(2)
                )

                // Update root
                setBucket({ history:hist, completed: new_completed, completion, remaining })

                // Save to cloud
                axios.post('/api/buckets/edit', { id, history: hist, completed: new_completed })
            }

            // Close
            setState(s => { return { ...s, activeItem: null } })
        }

        useEffect(() => {
            function handleClickOutside(event) {
                let ref = document.getElementById(state.activeItem)
                if (ref && !ref.contains(event.target)) {
                    // saveAndClose(false)
                    setState(s => { return { ...s, activeItem: null } })
                }
            }
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }, [])

        return <div className="dropdown-menu">
            <div className="dropdown-content">
                <div className="dropdown-item">
                    <label className="is-block mb-2">Payment Amount</label>
                    <div className="level">
                        <div className="level-item">
                            <div className="control is-expanded has-icons-left">
                                <NumbersOnlyInput
                                    autoFocus={true}
                                    value={valu}
                                    onChange={(value) => { setValu(value) }}
                                    onKeyDown={(e, key) => { if (["Enter", "Escape"].includes(key)) {  }}}
                                    maxLength={9}
                                    placeholder="Amount"
                                />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                            </div>
                        </div>
                        <div className="level-item">
                            <div className="control is-expanded has-icons-left">
                                <DatePicker selected={date} onChange={date => { setDate(date) }} className="input is-clickable" />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faCalendar} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dropdown-item">
                    <button className="button is-primary is-fullwidth pad-bottom" onClick={() => saveAndClose(true)}>
                        <span>
                            <b>Delete</b>
                        </span>
                    </button>
                </div>

                <div className="dropdown-item">
                    <button className="button is-link is-outlined is-fullwidth pad-bottom" onClick={() => saveAndClose(false)}>
                        <span>
                            <b>Save</b>
                        </span>
                    </button>
                </div>

            </div>
        </div>

    }

    return (
        <section className="section pt-0">
            <div className="container">
                <div className="fixedEditColumn mx-auto">
                    <div className="block level">
                        <button className="delete is-large" onClick={() => save(null, true)}></button>
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <div className="box bigStat has-text-centered is-primary">
                                <h1 className="title mb-0 is-size-4 is-spaced has-text-weight-bold has-text-primary">${completed}</h1>
                                <label className="label has-text-weight-normal">Paid</label>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box bigStat has-text-centered is-primary">
                                <h1 className="title mb-0 is-size-4 is-spaced has-text-weight-bold has-text-primary">${remaining}</h1>
                                <label className="label has-text-weight-normal">Remaining</label>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box bigStat has-text-centered is-primary">
                                <h1 className="title mb-0 is-size-4 is-spaced has-text-weight-bold has-text-primary">{completion}%</h1>
                                <label className="label has-text-weight-normal">Paid</label>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <label className="label has-text-centered has-text-left-mobile">Bucket Name</label>
                                </div>
                            </div>
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <label className="label has-text-centered has-text-left-mobile">Goal Total</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        className="input is-primary"
                                        type="text"
                                        placeholder="Bucket Name"
                                        value={state.name}
                                        onKeyDown={(event) => { if (event.key === "Enter" || event.key === "Escape") { event.target.blur() } }}
                                        onChange={({ target:{ value } }) => { setState({ ...state, name: value }) }}
                                        onBlur={(e) => { save("name") }}
                                        maxLength={64}
                                    />
                                </div>
                            </div>
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <NumbersOnlyInput
                                        value={state.total}
                                        maxLength={9}
                                        placeholder="Goal Total"
                                        onKeyDown={(event) => { if (event.key === "Enter" || event.key === "Escape") { event.target.blur() } }}
                                        onChange={(value) => { setState({ ...state, total: value }) }}
                                        onBlur={(e) => { save("total") }}
                                    />
                                </div>
                                { loan && type !== "Savings" ? (
                                    <div className="control">
                                        <Link className="button is-warning test" to={`/loan/${loan.id}`}>
                                            <span className="icon">
                                                <img src={CalculatorIcon} alt="Calculator" />
                                            </span>
                                        </Link>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                    { total > completed
                        ? <div className="block mt-5">
                            <div className="buttons">
                                <button
                                    className="button is-primary pad-bottom mb-0 is-fullwidth"
                                    onClick={() => { setState({ ...state, showAddItem: true }) }}
                                >
                                    <span>+ Add Payment</span>
                                </button>
                            </div>
                        </div>
                        : <></>
                    }
                    <div className="block pt-3">
                        { history.length ? (
                            <div className="field">
                                <label className="label has-text-centered has-text-left-mobile">Payment History</label>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className={"paymentHistory" + (state.activeItem !== null ? " has-focused" : "")}>{
                            history
                                .sort(function (a, b) {
                                    return new Date(b.date) - new Date(a.date)
                                })
                                .map(item => {
                                    return <HistoryItem
                                        key={item.id}
                                        id={item.id}
                                        item={item}
                                        active={item.id === state.activeItem}
                                        onClick={() => {
                                            // Close if open
                                            if(state.activeItem === item.id){
                                                return setState(s => { return { ...s, activeItem: null } })
                                            }
                                            // Set the new target
                                            setState({
                                                ...state,
                                                activeItem: item.id,
                                                editValue: item.value,
                                                editDate: item.date
                                            })
                                        }}
                                    >
                                        { state.activeItem === item.id ? <Dropdown item={item} /> : null }
                                    </HistoryItem>
                                })
                        }</div>
                    </div>
                </div>
            </div>
            { state.showAddItem
                ? <AddHistory close={() => setState(s => { return { ...s, showAddItem: false } })} />
                : <></>
            }
        </section>
    )
}