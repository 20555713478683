// Core
import { Component, useState, useEffect, useContext } from "react"
import { Navigate, Link, Route } from "react-router-dom"

// Context
import UserContext from "../context/User.jsx"

import { getQue, saveQue } from 'utility/actionQue.js'

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faLock, faUser, faArrowRight, faSave } from "@fortawesome/free-solid-svg-icons"

// Tools
import axios from "axios"
import moment from "moment"

// Components
import Notification from "../common/Notification"
import Loader from "../common/Loader"

// Images
import Logo from "../images/logo.svg"
import Background from "../images/login-screen-final-1.png"

// Style
import "../sass/Authentication.sass"

var availableYears = [],
    startYear = parseInt(moment().format("YYYY"))
for (let i = 0; i < 75; i++) {
    availableYears.push(String(startYear - i))
}

export function Logout() {
    const [finished, setFinished] = useState(false)
    const [, setData] = useContext(UserContext)

    useEffect(() => {
        axios.post('/api/logout', {})
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setData((d) => {})
                setFinished(true)
            })
    })

    if (finished) {
        return <Navigate to="/home" replace={true} />
    }

    return <div></div>
}

export function Login({ mode = "Login", ...props }) {
    const [user, setUser] = useContext(UserContext)
    const [state, setState] = useState({
        redirect: false,
        isLoading: false,
        mode,
        showValid: false,
        firstValid: false,
        lastValid: false,
        emailValid: false,
        passwordValid: false,
        first: "",
        last: "",
        email: "",
        password: "",
        birth_month: "",
        birth_year: "",
    })

    if (state.redirect) {
        return <Navigate to={state.redirect} replace={false} />
    }
    if (user && user.authorized) {
        return <Navigate to="/buckets" replace={true} />
    }

    function listenForSubmit({ code = null }) {
        if (code === "Enter") {
            onSubmit()
        }
    }
    function onSubmit() {
        // Show Loading
        setState({ ...state, isLoading: true, showValid: true })

        const { firstValid, lastValid, emailValid, passwordValid } = state

        // Validation
        if (state.mode === "Signup" && !(firstValid && lastValid && emailValid && passwordValid)) {
            return setState({ ...state, isLoading: false })
        } else if (!emailValid || !passwordValid) {
            return setState({ ...state, isLoading: false })
        }

        axios.post('/api/' + state.mode.toLowerCase(), {
            first: state.first,
            last: state.last,
            email: state.email,
            password: state.password,
            birth_month: state.birth_month,
            birth_year: state.birth_year,
            actionQue: getQue()
        })
            .then(({ data }) => {
                if (data.success === false) {
                    return setState({ ...state, isLoading: false, errorMessage: data.message })
                }
                saveQue([])
                setUser({ authorized: true, user: data.user })

                let { redirect="/buckets" } = data
                
                setState({ ...state, redirect })
            })
            .catch((err) => {
                setState({
                    ...state,
                    isLoading: false,
                    errorMessage: "Something went wrong trying to " + state.mode.toLowerCase() + ", please try again",
                })
                console.log(err)
            })
    }
    return (
        <div className="authScreen is-full">
            <div className="art" style={{ backgroundImage: `url('${Background}')` }}></div>
            <div className="art-text">
                <h1 className="title">
                    Make saving <br />
                    money fun again!
                </h1>
                <h2 className="subtitle">Now go fill those buckets!</h2>
            </div>
            <div className="authContainer">
                <Link to="/" className="block image is-logo" alt="Fill Your Buckets">
                    <img src={Logo} alt="Fill Your Buckets" />
                </Link>
                <div className="authComponent">
                    <div className="block has-text-centered welcomeBack">
                        <h1 className="title mb-2 is-size-4 has-text-black">
                            <b>{state.mode === "Login" ? "Log in" : "Create Your Free Account"}</b>
                        </h1>
                        <p>{state.mode === "Login" ? "It's good to see you again!" : "No credit card needed"}</p>
                    </div>
                    <div className="tabs has-text-white">
                        <ul>
                            <li className={state.mode === "Login" ? "is-active" : ""}>
                                <div
                                    className="tab"
                                    onClick={() => {
                                        setState({ ...state, mode: "Login", showValid: false })
                                    }}
                                >
                                    Login
                                </div>
                            </li>
                            <li className={state.mode === "Signup" ? "is-active" : ""}>
                                <div
                                    className="tab"
                                    onClick={() => {
                                        setState({ ...state, mode: "Signup", showValid: false })
                                    }}
                                >
                                    Signup
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={"field is-horizontal " + (state.mode === "Login" ? "is-hidden" : "")}>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded has-icons-left">
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="First Name"
                                        onChange={(event) => {
                                            let value = event.target.value
                                            setState({ ...state, first: value, firstValid: value.length })
                                        }}
                                    />
                                    <span className="icon is-small is-left">
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                </div>
                                <p className={"help is-danger " + (state.showValid && !state.firstValid ? "" : "is-hidden")}>Please enter your first name</p>
                            </div>
                            <div className="field">
                                <div className="control is-expanded has-icons-left">
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="Last Name"
                                        onChange={(event) => {
                                            let value = event.target.value
                                            setState({ ...state, last: value, lastValid: value.length })
                                        }}
                                    />
                                    <span className="icon is-small is-left">
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                </div>
                                <p className={"help is-danger " + (state.showValid && !state.lastValid ? "" : "is-hidden")}>Please enter your last name</p>
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control has-icons-left">
                            <input
                                autoFocus
                                className="input"
                                type="email"
                                placeholder="Email"
                                autoComplete="email"
                                onChange={(event) => {
                                    let value = event.target.value
                                    setState({ ...state, email: value, emailValid: value.length && value.length > 3 && value.includes("@") })
                                }}
                                onKeyPress={listenForSubmit}
                            />
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                        </div>
                        <p className={"help is-danger " + (state.showValid && !state.emailValid ? "" : "is-hidden")}>Invalid Email Address</p>
                    </div>
                    <div className="field">
                        <div className="control has-icons-left">
                            <input
                                className="input"
                                type="password"
                                placeholder="Password"
                                autoComplete="password"
                                onChange={(event) => {
                                    let value = event.target.value
                                    setState({ ...state, password: value, passwordValid: value.length && value.length > 7 })
                                }}
                                onKeyPress={listenForSubmit}
                            />
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faLock} />
                            </span>
                        </div>
                        <p className={"help is-danger " + (state.showValid && !state.passwordValid ? "" : "is-hidden")}>{state.mode === "Signup" ? "Password must be at least 8 characters long!" : "Please enter a password"}</p>
                    </div>
                    <div className="field">
                        <Notification color="danger" hidden={state.errorMessage ? false : true}>
                            {state.errorMessage}
                        </Notification>
                    </div>
                    {state.mode === "Signup" ? (
                        <>
                            <div className="field is-horizontal">
                                <div className="field-body equal">
                                    <div className="field">
                                        <div className="control is-expanded">
                                            <div className="select is-fullwidth">
                                                <select
                                                    type="text"
                                                    className="input is-primary"
                                                    placeholder="Birth Month"
                                                    autoComplete="bday-month"
                                                    value={state.birth_month}
                                                    onChange={(e) => {
                                                        setState({ ...state, birth_month: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Birth Month</option>
                                                    <option value="january">January</option>
                                                    <option value="february">February</option>
                                                    <option value="march">March</option>
                                                    <option value="april">April</option>
                                                    <option value="may">May</option>
                                                    <option value="june">June</option>
                                                    <option value="july">July</option>
                                                    <option value="august">August</option>
                                                    <option value="september">September</option>
                                                    <option value="october">October</option>
                                                    <option value="november">November</option>
                                                    <option value="december">December</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control is-expanded">
                                            <div className="select is-fullwidth">
                                                <select
                                                    type="text"
                                                    className="input is-primary"
                                                    placeholder="Birth Year"
                                                    autoComplete="bday-year"
                                                    value={state.birth_year}
                                                    onChange={(e) => {
                                                        setState({ ...state, birth_year: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Birth Year</option>
                                                    {availableYears.map((year) => {
                                                        return (
                                                            <option key={year} value={year}>
                                                                {year}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {/* <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faUser} />
                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="notification is-primary">
                                <p>
                                    By signing up, you are agreeing to our{" "}
                                    <Link to="/terms-of-use" className="has-text-white">
                                        <u>Terms of use</u>
                                    </Link>{" "}
                                    and{" "}
                                    <Link to="/privacy-policy" className="has-text-white">
                                        <u>Privacy Policy</u>
                                    </Link>
                                </p>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <div className="block">
                        <div className="buttons">
                            <button
                                disabled={(state.mode === "Signup" && state.first && state.last && state.email && state.password && (state.email.match(/@/g) || []).length === 1 && state.birth_month && state.birth_year) || (state.mode === "Login" && state.email && state.password) ? false : true}
                                className={"button is-primary pad-bottom mb-0 is-fullwidth" + (state.isLoading ? " is-loading" : "")}
                                type="button"
                                onClick={onSubmit}
                            >
                                <span>{state.mode}</span>
                            </button>
                        </div>
                    </div>
                    <div className="field has-text-centered">
                        <Link className="is-size-6 has-text-primary" to="/forgot">
                            <u>Forgot Password?</u>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            error: false,
            showSuccess: false,
        }

        this.request = this.request.bind(this)
    }

    request() {
        if (!this.state.email.includes("@")) {
            return this.setState({ error: true })
        }
        this.setState({ showSuccess: true })
        axios.post("/api/reset-password", { email: this.state.email }).catch(console.log)
    }

    render() {
        return (
            <section className="section reset-password">
                <div className="hero is-medium">
                    <div className="hero-body">
                        <div className="container">
                            <Link className="block" to="/login">
                                <figure className="image">
                                    <img src={Logo} alt="Fill Your Buckets" />
                                </figure>
                            </Link>
                            <div className="block box">
                                <div className="block">
                                    <h1 className="title is-size-2">Reset Password</h1>
                                </div>
                                {this.state.showSuccess ? (
                                    <>
                                        <div className="block">
                                            <p className="is-size-6">Great! If an account with that email address exists in our system, you will receive instructions to reset your password.</p>
                                        </div>
                                        <div className="block">
                                            <p className="is-size-6">If you don't see the reset email right away, please wait up to 5 minutes and be sure to check your spam folder.</p>
                                        </div>
                                        <div className="block buttons is-centered">
                                            <Link className="button is-primary mb-0 pad-bottom" type="button" to="/login">
                                                <span>Return To Login</span>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="block">
                                            <p className="is-size-6">Trouble signing in? Resetting your password is easy!</p>
                                        </div>
                                        <div className="field">
                                            <div className="control has-icons-left">
                                                <input
                                                    className="input"
                                                    type="email"
                                                    placeholder="Email"
                                                    autoComplete="email"
                                                    value={this.state.email}
                                                    onChange={({ target }) => this.setState({ email: target.value, error: false })}
                                                    onKeyDown={({ key, target }) => {
                                                        if (key === "Enter") {
                                                            target.blur()
                                                            this.request()
                                                        }
                                                    }}
                                                />
                                                <span className="icon is-left">
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                </span>
                                            </div>
                                            {this.state.error ? <p className="help is-danger">Please provide a valid email address!</p> : <></>}
                                        </div>
                                        <div className="block">
                                            <p className="is-size-6">Just enter your email and press the button below and we will send instructions to your email. We'll have you filling buckets in no time.</p>
                                        </div>
                                        <div className="block buttons is-right">
                                            <button className="button is-primary mb-0 pad-bottom" type="button" onClick={this.request}>
                                                <span>Reset Password</span>
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export class NewPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            success: false,
            expired: false,
            newPassword: "",
            confirmPassword: "",
        }

        const search = new URLSearchParams(window.location.search)

        this.s = search.get("s")
        this.t = search.get("t")

        this.submitNewPassword = this.submitNewPassword.bind(this)
    }

    componentDidMount() {
        axios.get(`/api/new-password?s=${this.s}&t=${this.t}`).then(({ data }) => {
            if (data.success) {
                return this.setState({ loading: false })
            }
            this.setState({ loading: false, expired: true })
        })
    }

    submitNewPassword() {
        if (!this.state.newPassword.length >= 8 || !this.state.confirmPassword.length >= 8 || this.state.confirmPassword !== this.state.newPassword) {
            return
        }
        this.setState({ loading: true })
        axios.post("/api/new-password", { s: this.s, t: this.t, new_password: this.state.newPassword }).then(({ data }) => {
            if (data.success) {
                return this.setState({ loading: false, success: true })
            }
            this.setState({ loading: false, expired: true })
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <section className="section reset-password">
                    <div className="hero is-medium">
                        <div className="hero-body has-text-centered">
                            <div className="container">
                                <Loader />
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        return (
            <section className="section reset-password">
                <div className="hero is-medium">
                    <div className="hero-body">
                        <div className="container">
                            <Link className="block" to="/login">
                                <figure className="image">
                                    <img src={Logo} alt="Fill Your Buckets" />
                                </figure>
                            </Link>
                            <div className="block box">
                                {this.state.expired ? (
                                    <>
                                        <div className="block">
                                            <h1 className="title is-size-2 has-text-centered">Link Expired</h1>
                                        </div>
                                        <div className="block has-text-centered">
                                            <p>
                                                We're sorry but the link you have clicked on is either expired or doesn't exist. Please try requesting a new link, or if the issue persists please contact us at <a href="mailto:support@fillyourbuckets.com">support@fillyourbuckets.com</a>
                                            </p>
                                        </div>
                                    </>
                                ) : this.state.success ? (
                                    <>
                                        <div className="block">
                                            <h1 className="title is-size-2 has-text-centered">Password Reset!</h1>
                                        </div>
                                        <div className="block has-text-centered">
                                            <p>
                                                Success! You have set a new password for your account.
                                                <br />
                                                Click the button below to login to your account now.
                                            </p>
                                        </div>
                                        <div className="block buttons is-centered">
                                            <Link className="button is-primary" to="/login">
                                                <span>Go To Login</span>
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </span>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="block has-text-centered">
                                            <h1 className="title is-size-2">Create A New Password</h1>
                                        </div>
                                        <div className="block has-text-centered">
                                            <p>Create a new password for your account below.</p>
                                        </div>
                                        <div className="block">
                                            <div className="field">
                                                <div className="control has-icons-left">
                                                    <input
                                                        className="input"
                                                        type="password"
                                                        placeholder="New Password"
                                                        autoComplete="off"
                                                        value={this.state.newPassword}
                                                        onChange={({ target: { value } }) => {
                                                            this.setState({ newPassword: value })
                                                        }}
                                                        onKeyDown={({ key }) => {
                                                            if (key === "Enter") {
                                                                this.submitNewPassword()
                                                            }
                                                        }}
                                                    />
                                                    <span className="icon is-left">
                                                        <FontAwesomeIcon icon={faLock} />
                                                    </span>
                                                </div>
                                                {this.state.newPassword.length < 8 && this.state.newPassword.length ? <p className="help">Password must be at least 8 characters long</p> : ""}
                                            </div>
                                            <div className="field">
                                                <div className="control has-icons-left">
                                                    <input
                                                        className="input"
                                                        type="password"
                                                        placeholder="Confirm New Password"
                                                        autoComplete="off"
                                                        value={this.state.confirmPassword}
                                                        onChange={({ target: { value } }) => {
                                                            this.setState({ confirmPassword: value })
                                                        }}
                                                        onKeyDown={({ key }) => {
                                                            if (key === "Enter") {
                                                                this.submitNewPassword()
                                                            }
                                                        }}
                                                    />
                                                    <span className="icon is-left">
                                                        <FontAwesomeIcon icon={faLock} />
                                                    </span>
                                                </div>
                                                {this.state.confirmPassword.length && this.state.confirmPassword !== this.state.newPassword ? <p className="help">New password and confirm password do not match!</p> : ""}
                                            </div>
                                        </div>
                                        <div className="buttons is-right">
                                            <button
                                                className="button is-primary mb-0 pad-bottom"
                                                onClick={this.submitNewPassword}
                                                type="button"
                                                {...(this.state.newPassword.length >= 8 && this.state.confirmPassword.length >= 8 && this.state.confirmPassword === this.state.newPassword ? "" : { disabled: true })}
                                            >
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faSave} />
                                                </span>
                                                <span>Set Password</span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export function ConfirmEmail(props) {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let search = window.location.search
        axios.post("/api/confirmEmail", { search }).then(({ data }) => {
            console.log({ emailConfirmed: data.success })
            setLoading(false)
        })
    })

    if (loading) {
        return (
            <section className="section reset-password">
                <div className="hero is-large">
                    <div className="hero-body">
                        <div className="mx-auto">
                            <Loader />
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className="section reset-password">
            <div className="hero is-large">
                <div className="hero-body">
                    <div className="container is-max-fullhd">
                        <div className="block">
                            <figure className="image is-logo">
                                <img src={Logo} alt="Fill Your Buckets" />
                            </figure>
                        </div>
                        <div className="block has-text-centered">
                            <h1 className="title is-size-2">Email Confirmed!</h1>
                            <h2 className="subtitle is-size-5">Thanks! You have successfully confirmed your email!</h2>
                        </div>
                        <div className="block">
                            <div className="block buttons is-centered">
                                <Link className="button is-primary" to="/calculator">
                                    <span>Go to Fill Your Buckets</span>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default (
    <>
        <Route path="/login" element={<Login mode="Login" />} />
        <Route path="/signup" element={<Login mode="Signup" />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/confirm" element={<ConfirmEmail />} />
        <Route path="/new-password" element={<NewPassword />} />
    </>
)
