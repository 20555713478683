import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown19, faArrowUp19 } from "@fortawesome/free-solid-svg-icons"

export default function SortButtons({ sort="smallest", setSort, ...props }){

    return (
        <div className="block buttons is-right has-addons">
            <button
                data-tooltip="Sort Smallest First"
                className={"button has-tooltip-bottom is-" + (sort === "smallest" ? "primary" : "light")}
                onClick={() => { setSort("smallest") }}
                type="button"
            >
                <span className="icon">
                    <FontAwesomeIcon icon={faArrowUp19} />
                </span>
            </button>
            <button
                data-tooltip="Sort Largest First"
                className={"button has-tooltip-bottom is-" + (sort === "largest" ? "primary" : "light")}
                onClick={() => { setSort("largest") }}
                type="button"
            >
                <span className="icon">
                    <FontAwesomeIcon icon={faArrowDown19} />
                </span>
            </button>
        </div>
    )

}