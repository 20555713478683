import { useCallback, useEffect, useState } from "react"
import Particles from "react-particles"
import { loadFull } from "tsparticles"

function play(state) {
    try {
        state.Emitters.array[0].play()
        state.Emitters.array[1].play()
    } catch (e) {}
}
function pause(state) {
    try {
        state.Emitters.array[0].pause()
        state.Emitters.array[1].pause()
    } catch (e) {}
}

export default function Confetti({ time = 5000, ...props }) {
    const [state, setState] = useState({
        Container: null,
        Emitters: null,
        active: true,
    })

    useEffect(() => {
        if (!state.Emitters || !state.Emitters.array) {
            return
        }
        if (!time) {
            play(state)
        }
        setTimeout(() => {
            pause(state)
        }, parseInt(time))
        setTimeout(() => {
            setState({
                ...state,
                active: false,
            })
        }, parseInt(time) + 10000)
    }, [state, time])

    // Particle loading
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine)
    }, [])
    const particlesLoaded = useCallback(async (Container) => {
        setState((state) => {
            return {
                ...state,
                Container,
                Emitters: Container.plugins.get("emitters"),
            }
        })
    }, [])

    if (!state.active) {
        return <></>
    }

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fpsLimit: 60,
                fullScreen: {
                    zIndex: 1,
                },
                emitters: [
                    {
                        position: {
                            x: 0,
                            y: 30,
                        },
                        rate: {
                            quantity: 5,
                            delay: 0.15,
                        },
                        particles: {
                            move: {
                                direction: "top-right",
                                outModes: {
                                    top: "none",
                                    left: "none",
                                    default: "destroy",
                                },
                            },
                        },
                    },
                    {
                        position: {
                            x: 100,
                            y: 30,
                        },
                        rate: {
                            quantity: 5,
                            delay: 0.15,
                        },
                        particles: {
                            move: {
                                direction: "top-left",
                                outModes: {
                                    top: "none",
                                    right: "none",
                                    default: "destroy",
                                },
                            },
                        },
                    },
                ],
                particles: {
                    move: {
                        decay: 0.05,
                        direction: "top",
                        enable: true,
                        gravity: {
                            enable: true,
                        },
                        outModes: {
                            top: "none",
                            default: "destroy",
                        },
                        speed: {
                            min: 10,
                            max: 50,
                        },
                    },
                    number: {
                        value: 0,
                    },
                    opacity: {
                        value: 1,
                    },
                    rotate: {
                        value: {
                            min: 0,
                            max: 360,
                        },
                        direction: "random",
                        animation: {
                            enable: true,
                            speed: 30,
                        },
                    },
                    tilt: {
                        direction: "random",
                        enable: true,
                        value: {
                            min: 0,
                            max: 360,
                        },
                        animation: {
                            enable: true,
                            speed: 30,
                        },
                    },
                    size: {
                        value: {
                            min: 0,
                            max: 2,
                        },
                        animation: {
                            enable: true,
                            startValue: "min",
                            count: 1,
                            speed: 16,
                            sync: true,
                        },
                    },
                    roll: {
                        darken: {
                            enable: true,
                            value: 25,
                        },
                        enable: true,
                        speed: {
                            min: 5,
                            max: 15,
                        },
                    },
                    wobble: {
                        distance: 30,
                        enable: true,
                        speed: {
                            min: -7,
                            max: 7,
                        },
                    },
                    shape: {
                        type: "image",
                        options: {
                            image: [
                                {
                                    src: process.env.PUBLIC_URL + "/dollar-bill.png",
                                    width: 50,
                                    height: 32,
                                    particles: {
                                        size: {
                                            value: 16,
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            }}
        />
    )
}
