import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { useLongPress } from "use-long-press"

import BucketSVGBasic from './BucketSVGBasic.jsx'
import PromptDelete from "./PromptDelete.jsx"

const bind_settings = {
    threshold: 500,
    cancelOnMovement: true,
    detect: "both",
    filterEvents: (e) => { return e.button !== 2 },
}

export default function BucketListItem({ setState:setRootState, bucket }){

    const navigate = useNavigate()
    const [ showModal, setShowModal ] = useState(false)

    const bindLongPress = useLongPress(function(){
        // Prevent non-deletable buckets from being deleted!
        if (bucket.metadata && bucket.metadata.includes("not-deletable") && bucket.completed < bucket.total) { return; }
        setShowModal(true)
    }, bind_settings)

    return (<>
        <div
            className={"bucket is-clickable"}
            key={bucket.id}
            onClick={() => {
                navigate(`/bucket/${bucket.id}`, { state: bucket, replace: false })
            }}
            {...bindLongPress()}
        >
            <BucketSVGBasic index={bucket.id} completion={(bucket.completed / bucket.total) * 100} bucket_name={bucket.name} />
        </div>
        { showModal
            ? <PromptDelete bucket={bucket} close={(deleted=false) => {
                setShowModal(false);
                if(deleted){
                    setRootState(s => {
                        let data = s.data.filter(a => a.id !== bucket.id)
                        return { ...s, data }
                    })
                }
            }}/>
            : <></>
        }
    </>)

}