import { Route } from 'react-router-dom'

import AllSavings  from './AllSavings.jsx'
// import ViewSavings from './ViewSavings.jsx'

export default (
    <>
        <Route path="savings" element={<AllSavings />} />
        {/* <Route path="savings">
            <Route path=":id" element={<ViewSavings />} />
        </Route> */}
    </>
)
