import { Route } from 'react-router-dom';

import AllChallenges from './index.jsx'
import Challenge100Days from "./100DayChallenge.jsx"
import Challenge52Weeks from "./52WeekChallenge.jsx";
import Challenge26Weeks from "./26WeekChallenge.jsx";

export default <>
    <Route path="/challenges" element={<AllChallenges />} />
    <Route path="/challenge/100days" element={<Challenge100Days />} />
    <Route path="/challenge/26weeks" element={<Challenge26Weeks />} />
    <Route path="/challenge/52weeks" element={<Challenge52Weeks />} />
</>