import { forwardRef, useState } from 'react';

export default forwardRef((props, ref) => {

    const [inputValue, setInputValue] = useState(props.value||'');

    function onChange(event){
        
        let { target:{ value } } = event

        // Ensure numbers only
        value = value.replaceAll(/[^0-9.]/gmi, '')

        // Ensure only one period at a time
        if((String(value).match(/\./g) || []).length === 2){
            value = value.slice(0,-1)
            console.log("Only allowing one period")
        }
        
        // Limit to only two characters after a .
        if(String(value).includes('.')){
            value = value.substring(0, String(value).indexOf('.') + 3)
            // console.log("Limited to fixed(2)")
        }

        if(props.wholeNumbersOnly && String(value).includes('.')){
            value = value.substring(0, String(value).indexOf('.'))
            // console.log("Limited to whole number")
        }

        setInputValue(value);

        if(props.onChange){
            props.onChange( value )
        }

    }

    function onKeyDown(event){
        if(props.onKeyDown){
            props.onKeyDown(
                inputValue,
                event.key,
                event
            )
        }
    }

    return (
        <input
            key={1}
            className={"input is-primary" + (props.extraClasses?' '+props.extraClasses:'')}
            autoFocus={props.autoFocus?true:false}
            ref={ref}
            value={inputValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            maxLength={props.maxLenth||100}
            placeholder={props.placeholder||''}
            onBlur={props.onBlur?props.onBlur:null}
        />
    )
})