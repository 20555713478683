import moment from 'moment'

export default function HistoryItem({ item:{ value, date }, onClick, children, active, id }){

    return (
        <div className={"dropdown payment-history-item" + (active?' is-active':'')} id={id}>
            <div className="dropdown-trigger" onClick={onClick}>
                <p>
                    <strong>+ {parseFloat(value).toFixed(2)}</strong>
                </p>
                <p>
                    { moment(date).format('MM/DD/YYYY') }
                </p>
            </div>
            { children ? children : <></> }
        </div>
    )

}