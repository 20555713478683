import { Route } from 'react-router-dom'

import AllLoans from './AllLoans.jsx'
import ViewLoan from './ViewLoan.jsx'

export default (
    <>
        <Route path="loans" element={<AllLoans />} />
        <Route path="loan">
            <Route path=":id" element={<ViewLoan />} />
        </Route>
    </>
)
