import React from "react"
import axios from "axios"

import Loader from "../common/Loader"

import s100 from "../images/StacksOfCash/100.png"
import s200 from "../images/StacksOfCash/200.png"
import s500 from "../images/StacksOfCash/500.png"
import s1000 from "../images/StacksOfCash/1000.png"
import s2000 from "../images/StacksOfCash/2000.png"
import s5000 from "../images/StacksOfCash/5000.png"
import s10000 from "../images/StacksOfCash/10000.png"

import { FontAwesomeIcon as FontAwesome6 } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import "../sass/StacksOfCash.sass"

export default class StacksOfCash extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: null,
            dragParent: null,
            dragTarget: null,
            allStacks: null,
        }

        this.calculateTotal = this.calculateTotal.bind(this)
    }

    calculateTotal() {
        let total = 0.0

        this.state.allStacks.forEach((stack) => {
            total += stack.value * stack.stacks
        })

        // Set from 1000 to 1000.00, then to string, then to 1,000.00
        total = total
            // .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        return total
    }

    componentDidMount() {
        axios
            .get("/api/stacks")
            .catch(() => {
                return { success: false, message: "Something went wrong, please try again later" }
            })
            .then(({ data }) => {
                if (!data.success) {
                    if (data.message) {
                        return this.setState({ error: data.message })
                    }
                }
                this.setState({
                    allStacks: [
                        { image: s100, name: "total_100", stacks: data["total_100"], value: 100 },
                        { image: s200, name: "total_200", stacks: data["total_200"], value: 200 },
                        { image: s500, name: "total_500", stacks: data["total_500"], value: 500 },
                        { image: s1000, name: "total_2000", stacks: data["total_2000"], value: 1000 },
                        { image: s2000, name: "total_1000", stacks: data["total_1000"], value: 2000 },
                        { image: s5000, name: "total_5000", stacks: data["total_5000"], value: 5000 },
                        { image: s10000, name: "total_10000", stacks: data["total_10000"], value: 10000 },
                    ],
                })
            })
            .then(() => {
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1000)
            })
    }

    stackDragMath({ targetData, parentData } = {}) {
        // Do the math
        let add_to_target_stack = (parentData.stacks * parentData.value) / targetData.value,
            taken_value = add_to_target_stack * targetData.value,
            remove_from_parent_stack = taken_value / parentData.value,
            save = true

        // Now put limits in place!
        // let overflow = 0

        // if(add_to_target_stack > 10){
        //     overflow = add_to_target_stack - 10
        //     add_to_target_stack = 10

        //     // Math.ceil
        //     let stacks_to_keep = ((overflow * targetData.value) / parentData.value)
        //     if(Math.round(stacks_to_keep) !== stacks_to_keep){ console.log({ stacks_to_keep:"add_to_stack is not even!" }); save = false }

        //     remove_from_parent_stack -= stacks_to_keep
        // }

        // If it can spill into the next one, do it!
        let overflow = 0

        if (add_to_target_stack !== Math.round(add_to_target_stack) && add_to_target_stack >= 1) {
            overflow = Math.abs(Math.round(add_to_target_stack) - add_to_target_stack).toFixed(2)

            let keep_stacks = (overflow * targetData.value) / parentData.value

            remove_from_parent_stack -= keep_stacks
            add_to_target_stack = Math.floor(add_to_target_stack)
        }

        // If it's not even rounding, then the data is not good!
        if (add_to_target_stack !== Math.round(add_to_target_stack) || remove_from_parent_stack !== Math.round(remove_from_parent_stack)) {
            console.log({ notPossible: "add_to_stack is not even!" })
            save = false
        }

        if (!save) {
            return { add_to_target_stack: 0, remove_from_parent_stack: 0 }
        }
        return { add_to_target_stack, remove_from_parent_stack }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="hero is-large stacksOfCash">
                    <div className="hero-body">
                        <Loader />
                        <div className="is-invisible hidden-loading-images">
                            <img src={s100} alt="Stack of cash" />
                            <img src={s200} alt="Stack of cash" />
                            <img src={s500} alt="Stack of cash" />
                            <img src={s1000} alt="Stack of cash" />
                            <img src={s2000} alt="Stack of cash" />
                            <img src={s5000} alt="Stack of cash" />
                            <img src={s10000} alt="Stack of cash" />
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.error) {
            return <h1>{this.state.error}</h1>
        }

        return (
            <section className={"section stacksOfCash pt-0" + (this.state.dragParent !== null ? " has-dragging-items" : "")}>
                <div className="container">
                    <div className="block has-text-centered is-hidden-touch stacksOfCashTitles">
                        <h1 className="title has-text-black is-size-1 is-size-3-mobile">Your Stacks Of Cash</h1>
                        <h2 className="subtitle has-text-primary is-size-4">Stacks on stacks!</h2>
                    </div>
                    <div className="">
                        <div className="columns is-mobile">
                            {this.state.allStacks.map((item, index) => {
                                return (
                                    <Stack
                                        key={item.name + "" + index}
                                        image={item.image}
                                        name={item.name}
                                        dragParent={this.state.dragParent}
                                        dragTarget={this.state.dragTarget}
                                        stacks={item.stacks}
                                        targetEstimate={this.state.target_estimate}
                                        onChange={(value) => {
                                            let newStack = [...this.state.allStacks]
                                            newStack.find((arr) => arr.name === item.name).stacks += value

                                            this.setState({ allStacks: newStack })
                                        }}
                                        onDragStart={(e) => {
                                            this.setState({ dragParent: item.name, target_estimate: null })
                                        }}
                                        // onDragOver={ (e) => { this.setState({ dragTarget: item.name }); e.nativeEvent.preventDefault(); }}
                                        // onDragEnter={(e) => {
                                        //     let parentData = this.state.allStacks.find(a => a.name === this.state.dragParent),
                                        //         targetData = item

                                        //     let { add_to_target_stack:target_estimate } = this.stackDragMath({ targetData, parentData })

                                        //     this.setState({ target_estimate });
                                        // }}
                                        onDragEnter={(e) => {}}
                                        onDragOver={(e) => {
                                            let parentData = this.state.allStacks.find((a) => a.name === this.state.dragParent),
                                                targetData = item

                                            let { add_to_target_stack: target_estimate } = this.stackDragMath({ targetData, parentData })

                                            this.setState({ target_estimate, dragTarget: item.name })
                                            e.nativeEvent.preventDefault()
                                        }}
                                        onDragLeave={(e) => {
                                            this.setState({ dragTarget: null, target_estimate: null })
                                        }}
                                        onDragEnd={(e) => {
                                            let newState = { dragParent: null, dragTarget: null, target_estimate: null }

                                            console.log({ dragTarget: this.state.dragTarget })

                                            if (this.state.dragTarget && this.state.dragTarget !== this.state.dragParent) {
                                                // Get the items
                                                let newStack = [...this.state.allStacks]

                                                let parentDataIndex = newStack.findIndex((a) => a.name === this.state.dragParent),
                                                    targetDataIndex = newStack.findIndex((a) => a.name === this.state.dragTarget),
                                                    parentData = newStack[parentDataIndex],
                                                    targetData = newStack[targetDataIndex]

                                                let { add_to_target_stack, remove_from_parent_stack } = this.stackDragMath({ targetData, parentData })

                                                console.log({ add_to_target_stack, remove_from_parent_stack })

                                                // Set the items
                                                targetData.stacks += add_to_target_stack
                                                parentData.stacks -= remove_from_parent_stack

                                                newStack[parentDataIndex] = parentData
                                                newStack[targetDataIndex] = targetData

                                                newState.allStacks = newStack

                                                // Save to database
                                                if (add_to_target_stack !== 0) {
                                                    axios
                                                        .put("/api/stacks", { item: targetData.name, amount: add_to_target_stack })
                                                        .catch(console.log)
                                                        .then(({ status }) => {
                                                            return console.log("Successfully saved add_to_target_stack with status: " + status)
                                                        })
                                                        .then(() => {
                                                            if (remove_from_parent_stack !== 0) {
                                                                return axios.put("/api/stacks", { item: parentData.name, amount: remove_from_parent_stack * -1 })
                                                            } else return { status: "409 (value was 0)" }
                                                        })
                                                        .catch(console.log)
                                                        .then(({ status }) => {
                                                            console.log("Successfully saved remove_from_parent_stack with status: " + status)
                                                        })
                                                }
                                            }
                                            this.setState(newState)
                                        }}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div className="block myVault">
                        <div className="columns is-vcentered">
                            <div className="column is-hidden-touch">
                                <div className="box is-primary is-left">
                                    <p>
                                        Click the <strong className="has-text-primary">bottom</strong> of the stack to <strong className="has-text-primary">add</strong>.<br />
                                        Click the <strong className="has-text-primary">top</strong> of the stack to <strong className="has-text-primary">remove</strong>.
                                    </p>
                                </div>
                            </div>
                            <div className="column is-vault-text">
                                <div className="has-text-centered">
                                    <p className="is-size-5 is-size-4-mobile">Vault:</p>
                                    <p className="title is-size-1 is-size-4-mobile has-text-primary">${this.calculateTotal()}</p>
                                </div>
                            </div>
                            <div className="column is-hidden-touch"></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

function Stack({ image, name, dragParent, dragTarget, stacks, onChange, onDragStart, onDragEnd, onDragLeave, onDragOver, onDragEnter, targetEstimate }) {
    // let [ stacks, setStacks ] = useState(defStacks)

    function moveStack(amount = 1) {
        // Limits
        if (amount === -1 && stacks <= 0) {
            return
        }
        if (amount === 1 && stacks >= 10) {
            return
        }

        // Update with server
        axios.put("/api/stacks", { item: name, amount }).catch(console.log)

        // setStacks(stacks += amount)
        onChange(amount)
    }

    let images = []
    for (let i = 0; i < 10; i++) {
        let extra = {}
        if (i === 9) {
            extra.onDragStart = onDragStart
            extra.onDragEnd = onDragEnd
            extra.draggable = "true"
        }
        extra.onDragEnter = onDragEnter
        extra.onDragLeave = onDragLeave
        extra.onDragOver = onDragOver

        let isActive = i >= 10 - stacks,
            targetEstimateClassname = ""

        if (targetEstimate && dragTarget === name && dragParent !== name) {
            isActive = i >= 10 - (targetEstimate + stacks)
            if (i < 10 - stacks && i >= 10 - (stacks + targetEstimate)) {
                targetEstimateClassname = " is-estimate"
            }
            //  && i<= targetEstimate
        }

        images.push(
            <figure
                key={i}
                className={"image is-cash" + (isActive ? " is-active" : "") + targetEstimateClassname + (i === 9 ? "" : " is-hidden-touch")}
                onClick={() => {
                    moveStack(i === 9 ? 1 : -1)
                }}
                {...extra}
            >
                <img src={image} alt="Stack Of Cash" />
            </figure>,
        )
    }

    return (
        <div className="column">
            <div className={"cash-images" + (dragParent === name ? " is-disabled" : "") + (dragTarget === name && dragTarget !== dragParent ? " is-drag-target" : "")}>{images}</div>
            <p className="title has-text-centered is-size-3 is-hidden-touch">x{stacks}</p>
            <div className="level is-mobile mt-4 is-manualStack-button">
                <div className="level-item">
                    <button
                        className="button is-primary is-small is-fullwidth"
                        type="button"
                        onClick={() => {
                            moveStack(-1)
                        }}
                    >
                        <span className="icon">
                            <FontAwesome6 icon={faMinus} />
                        </span>
                    </button>
                </div>
                <div className="level-item">
                    <p className="title has-text-centered is-size-4">x{stacks}</p>
                </div>
                <div className="level-item">
                    <button
                        className="button is-primary is-small is-fullwidth"
                        type="button"
                        onClick={() => {
                            moveStack(1)
                        }}
                    >
                        <span className="icon">
                            <FontAwesome6 icon={faPlus} />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}
