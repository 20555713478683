import { useState, useEffect, createContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Loader from 'common/Loader'
import NotFound from 'common/NotFound'

import OverviewPage from './Pages/OverviewPage'
import SettingsPage from './Pages/SettingsPage'
import CongratsPage from './Pages/CongratsPage'

import axios from 'axios'

export default function Bucket(){

    const { id } = useParams()

    const [ state, setState ] = useState({
        page: "",
        bucket: null, // location.state ? location.state : null,
        loading: true, // !(location.state && location.state.id)
    });

    const setBucket = useMemo(() => {
        return function(new_bucket){
            setState(s => {
                return {
                    ...s,
                    bucket: Object.assign(state.bucket, new_bucket)
                }
            })
        }
    }, [state.bucket])

    useEffect(() => {
        axios
            .post('/api/buckets/get', { id })
            .then(({ data:bucket, status }) => {
                if(status === 204){ console.log("Status 204: Bucket not found!") }
                setState(s => {
                    return { ...s, loading: false, bucket, page:'' }
                })
            })
    }, [id])

    if(state.loading ){ return <Loader /> }
    if(!state.bucket ){ return <NotFound text="Bucket not found." title="Whoops..." /> }
    
    const { page="" } = state;

    return <Context.Provider value={[state.bucket, setBucket, setState]}>
        { state.bucket.completed > state.bucket.total
            ? <CongratsPage />
            : <section className="section bucketView">
            <div className="container">
                { page === ''         ? <OverviewPage /> : <></> }
                { page === 'settings' ? <SettingsPage /> : <></> }
            </div>
            {/* <Portal node={document.querySelector("#page-name")}>
                <div className="icon-text is-clickable has-text-black is-hidden-desktop" onClick={() => { setState({ ...state, page: "settings" }) }}>
                    <span className="is-size-4 m-0 has-text-weight-bold">{bucket.name}</span>
                </div>
            </Portal> */}
        </section>
        }
    </Context.Provider>

}

export const Context = createContext()