import { useState, useEffect, useContext } from "react"

import PageMessage from "common/PageMessage.jsx"

import UserContext from "context/User.jsx"

// import { FontAwesomeIcon as FontAwesome6 } from "@fortawesome/react-fontawesome"
// import { faUnlock } from "@fortawesome/free-solid-svg-icons"

import useSound from "use-sound"

import Loader from "common/Loader.jsx"
import Confetti from "common/Confetti.jsx"
import Styles from "./_.module.sass"

// Image files
import envelope_closed from "images/icons/envelope-closed.png"

// Audio files
import confetti_sound from "audio/Money-Register.ogg"
import envelope_sound from "audio/Envelope.ogg"

import axios from "axios"

const defaultDoc = {
    checked: {},
    level: 1,
}

function getChallengeData() {
    return new Promise((acc) => {
        console.log("Getting data!")
        axios
            .post("/api/challenges/challenge_100_days/get")
            .catch(console.log)
            .then(({ data }) => {
                if (data && data.data) {
                    let d = data.data
                    if(!Object.keys(d).length){
                        d = { checked:{  }, level: 1 }
                    }
                    return acc(d)
                }
                return acc(null)
            })
            .catch(console.log)
    })
}
function setChallengeData(body = {}) {
    return axios.post("/api/challenges/challenge_100_days/edit", {
        data: body,
    })
}

export default function Envelope100DayChallenge({ ...props }) {
    const [state, setState] = useState({
        data: null,
        last_checked: null,
        shift_held: false,
        showCongrats: false,
    })
    const [{ user }] = useContext(UserContext)

    const [play_confetti_sound] = useSound(confetti_sound)
    const [play_envelope_sound] = useSound(envelope_sound)

    useEffect(() => {
        getChallengeData().then((data) => {
            if (!data) {
                return
            }
            setState((state) => ({ ...state, data }))
        })
    }, [])

    useEffect(() => {
        function onKeyDown({ key }) {
            if (key === "Shift") {
                setState((s) => {
                    return { ...s, shift_held: true }
                })
            }
        }
        function onKeyUp({ key }) {
            if (key === "Shift") {
                setState((s) => {
                    return { ...s, shift_held: false }
                })
            }
        }

        document.addEventListener("keydown", onKeyDown)
        document.addEventListener("keyup", onKeyUp)
        return () => {
            document.removeEventListener("keydown", onKeyDown)
            document.removeEventListener("keydown", onKeyUp)
        }
    }, [])

    if (!state.data) {
        return <Loader />
    }

    function toggleChecked(i) {
        if (state.shift_held && state.last_checked !== null) {
            // return markAll(true)
            return markRange(state.last_checked, i)
        }
        let is_checked = (state.data.checked[i] && state.data.checked[i].checked) || false

        let checked = { ...state.data.checked }
        checked[i] = { checked: !is_checked, date: new Date().toISOString() }

        // Set state
        setState({ ...state, data: { ...state.data, checked }, last_checked: i })

        // Set doc in cloud
        setChallengeData({ ...state.data, checked })

        if (state.data.level === 1 && total === 5050) {
            // play_confetti_sound()
        } else {
            play_envelope_sound()
        }
    }

    let envelopes = [],
        total = 0

    for (let i = 0; i < 100; i++) {
        let x = i
        if (state.data.level === 2) {
            x += 100
        }
        let is_checked = !!(state && state.data && state.data.checked && state.data.checked[x] && state.data.checked[x].checked)
        if (is_checked) {
            total += x + 1
        }
        envelopes.push(
            <div key={x} className={Styles.envelope} onClick={() => toggleChecked(x)}>
                <EnvelopeItem value={x + 1} active={!!is_checked} />
            </div>
        )
    }

    if (state.data && state.data.level === 2) {
        total += 5050
    }

    function goto_level_2() {
        // Set state
        setState({ ...state, data: { ...state.data, level: 2 }, showCongrats: false })

        // Set doc in cloud
        setChallengeData({ ...state.data, level: 2 })
    }

    async function reset_to_level_1() {
        // Set state
        setState({ ...state, data: defaultDoc, showCongrats: false })

        // Set doc in cloud
        await setChallengeData(defaultDoc)
        // await axios.post("/api/challenges/restart", { total }) // Creates a new bucket (Depreciated)
    }

    function markAll(as_checked = true) {
        let checked = {},
            total_iterations = 100 * state.data.level

        if (state.data.level === 1 && typeof as_checked !== "number") {
            play_confetti_sound()
        } else {
            play_envelope_sound()
        }

        for (let i = 0; i < total_iterations; i++) {
            if (typeof as_checked === "number" && i === as_checked) {
                break
            }
            checked[i] = { checked: !!as_checked, date: new Date().toISOString() }
        }
        // Set in state
        setState({ ...state, data: { ...state.data, checked } })

        // Set doc in cloud
        setChallengeData({ ...state.data, checked })
    }

    function markRange(start, end) {
        console.log("Marking ranges: " + start + " -> " + end)
        // let is_checked = (state.data.checked[i] && state.data.checked[i].checked) || false

        let checked = { ...state.data.checked },
            iterations = Math.abs(end - start),
            offset = start < end ? start : start - iterations

        for (let i = 0; i < iterations + 1; i++) {
            checked[i + offset] = { checked: true, date: new Date().toISOString() }
        }

        // Set state
        setState({
            ...state,
            data: { ...state.data, checked },
            last_checked: end,
        })

        // Set doc in cloud
        setChallengeData({ ...state.data, checked })

        if (state.data.level === 1 && total === 5050) {
            // play_confetti_sound()
        } else {
            play_envelope_sound()
        }
    }

    if (state.data.level === 1 && total === 5050) {
        play_confetti_sound()
        return (
            <>
                <Confetti time={4800} />
                <PageMessage title="Congratulations" text={<p className="is-size-5">Level 2 is now unlocked.</p>}>
                    <button
                        className="button is-info"
                        type="button"
                        onClick={() => {
                            reset_to_level_1()
                            // setState({ ...state, showCongrats: false })
                        }}
                    >
                        <span>Start Over</span>
                    </button>
                    <button
                        className="button is-primary"
                        type="button"
                        onClick={() => {
                            goto_level_2()
                            // setState({ ...state, showCongrats: false })
                        }}
                    >
                        <span>Go to level 2!</span>
                    </button>
                </PageMessage>
            </>
        )
    }

    return (
        <section className="section">
            <div className="container is-max-fullhd mb-6 pb-6">
                {["alex@navarrocity.com", "brandanfisher@gmail.com"].includes(user.email) ? (
                    <div className="buttons is-right">
                        <button className="button is-light is-fullwidth-mobile" type="button" onClick={() => markAll(false)}>
                            <span>Clear All</span>
                        </button>
                        <button className="button is-dark is-fullwidth-mobile" type="button" onClick={() => markAll(true)}>
                            <span>Check All</span>
                        </button>
                        <button
                            className="button is-dark is-fullwidth-mobile"
                            type="button"
                            onClick={() => {
                                if (state.data.level === 1) {
                                    return markAll(99)
                                }
                                markAll(199)
                            }}
                        >
                            <span>Check All But One</span>
                        </button>
                        <button className="button is-danger is-fullwidth-mobile" type="button" onClick={() => axios.delete("/api/envelopeChallenges/reset")}>
                            <span>Wipe Database</span>
                        </button>
                    </div>
                ) : (
                    <></>
                )}
                <div className={"block " + Styles.envelopes}>
                    {
                        envelopes
                    }
                </div>
                <div className="block columns is-vcentered pt-4 mt-2">
                    <div className="column"></div>
                    <div className="column">
                        <h1 className="title is-size-1 has-text-centered has-text-primary" onClick={() => console.log(state)}>
                            ${total}
                        </h1>
                    </div>
                    <div className="column">
                        {total === 20100 && state.data && state.data.level === 2 ? (
                            <div className="block">
                                <div className="block buttons is-right">
                                    <button className="button is-medium is-warning" type="button" onClick={reset_to_level_1}>
                                        <span>New Challenge</span>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {/* <div className="block">
                    <p>
                        The concept of the 100 envelope challenge has a bit of history attached to it. At a young age, famous mathematician and physics scholar Carl Friedrich Gauss (1777-1855) invented a new method of summing sequences. The story is told that his math teacher assigned his class to
                        add the numbers 1 to 100, which would normally take some time. However, Gauss quickly figured out that the answer was 5,050 by grouping the numbers into different patterns.
                    </p>
                </div> */}
            </div>
        </section>
    )
}

function EnvelopeItem({ value, active }) {
    if (!active) {
        return (
            <div className={Styles.wrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.45 149.56">
                    <path d="M142.33,146.33H11.12c-4.36,0-7.89-3.53-7.89-7.89V66.81c0-2.32,1.02-4.52,2.79-6.02L71.62,5.1c2.95-2.5,7.27-2.5,10.22,0l65.61,55.68c1.77,1.5,2.79,3.7,2.79,6.02v71.63c0,4.36-3.53,7.89-7.89,7.89Z" fill="#049dbf" stroke="#049dbf" style={{ strokeWidth: "6.45px" }} />
                    <polygon points="22.59 76.9 52.91 99.05 71.52 114.55 86.17 109.28 102.21 97.15 129.43 79.56 128.23 66.56 30.05 66.56 22.59 76.9" fill="#fff" />
                    <path d="M22.59,75.63V34.26c0-3.05,2.47-5.53,5.53-5.53H123.9c3.05,0,5.53,2.47,5.53,5.53v41.36" fill="#fff" stroke="#023859" style={{ strokeWidth: "6.45px" }} />
                    <text x="50%" y="45%" dominantBaseline="middle" textAnchor="middle">
                        {value}
                    </text>
                    <path
                        d="M149.53,58.33l-.63-.54-69.59,52.64c-1.57,1.18-3.69,1.21-5.27,.06L3.36,58.86c-1.6,1.56-2.68,3.54-3.13,5.71l54.59,39.88L1.35,143.74c1.06,1.95,2.7,3.55,4.68,4.57l54.27-39.87,9.95,7.27c1.92,1.39,4.16,2.09,6.39,2.09s4.61-.74,6.57-2.22l9.96-7.54,54.11,40.35c2-1,3.66-2.57,4.75-4.51l-53.5-39.89,54.24-41.02c-.65-1.78-1.75-3.38-3.23-4.64Z"
                        fill="#006c96"
                    />
                </svg>
            </div>
        )
    }
    return (
        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.45 91.95">
        //     <rect x="3.23" y="3.23" width="147" height="85.5" rx="10.47" ry="10.47" fill="#049dbf" stroke="049dbf" style={{ strokeWidth: "6.45px" }} />
        //     <path
        //         d="M151.22,85.72l-52.66-39.05L151.55,6.77c-1.12-1.9-2.67-3.5-4.53-4.67L79.31,53.09c-1.57,1.18-3.69,1.21-5.26,.07L5.45,2.79c-1.73,1.31-3.14,3.03-4.09,5L54.87,47.08,2.24,85.74c1.22,1.85,2.86,3.39,4.81,4.48L60.32,51.08l9.92,7.28c1.92,1.39,4.16,2.09,6.39,2.09s4.61-.74,6.56-2.21l9.99-7.53,53.24,39.49c1.94-1.09,3.58-2.63,4.79-4.48Z"
        //         fill="#006c96"
        //     />
        // </svg>
        <div className={Styles.wrapper}>
            <img src={envelope_closed} alt="Envelope" />
        </div>
    )
}
