import Styles from './CoachPhil.module.sass'

import Phil from '../images/favicon.svg'

export default function CoachPhil({ message, ...props }){

    return (
        <div className={'block box is-primary ' + Styles.CoachPhil}>
            <div className={Styles.message}>
                <p className="is-size-6">{message}</p>
            </div>
            <div className={Styles.phil}>
                <figure className="image">
                    <img src={Phil} alt="Coach Phil"/>
                </figure>
                <div className={"block " + Styles.philBox}>
                    <p>Coach Phil</p>
                </div>
            </div>
        </div>
    )

}