import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { Portal } from "react-portal"

import SortButtons from "./Components/SortButtons"
import BucketListItem from "./Components/BucketListItem"

import Loader from 'common/Loader'
import axios from "axios"

// TODO: Removed actionques, should move the actions to server side when login/signup auth is done.

export default function BucketList(){

    // State
    const [ state, setState ] = useState({
        sort: localStorage && localStorage.getItem ? (localStorage.getItem("sort_buckets_method") || "smallest") : 'smallest',
        prompt_delete: null,
        data: null,
        loading: true
    })

    useEffect(() => {
        axios
            .post('/api/buckets/list')
            .then(({ data }) => {
                setState(s => { return { ...s, loading:false, data }})
            })
    }, [])

    if (state.loading) { return <Loader /> }

    let { data:list=[] } = state
    list = list.sort((a, b) => (state.sort === 'smallest') ? a.total - b.total : b.total - a.total)
    
    const active_list    = list.filter(a => a.completion <  100)
    const completed_list = list.filter(a => a.completion >= 100)

    return (
        <div className="section pt-0">
            <div className="container">
                { list.length
                    ? <>
                        <div className="columns">
                            <div className="column"></div>
                            <div className="column is-8">
                                <div className="has-text-centered is-hidden-touch">
                                    <h1 className="title is-size-1 is-size-3-mobile has-text-black">Your Buckets</h1>
                                    <h1 className="subtitle is-size-5 is-size-6-mobile has-text-primary">Create and fill those buckets!</h1>
                                </div>
                            </div>
                            <div className="column">
                                { list.length > 1
                                    ? <SortButtons sort={state.sort} setSort={(sort) => {
                                        localStorage.setItem("sort_buckets_method", sort)
                                        setState({ ...state, sort })
                                    }}/> 
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className={"block box is-shadowless bucketList"}>
                            { active_list.map(bucket    => <BucketListItem key={bucket.id} setState={setState} bucket={bucket}/>) }
                        </div>
                        <div className={"block box is-shadowless bucketList"}>
                            { completed_list.map(bucket => <BucketListItem key={bucket.id} setState={setState} bucket={bucket}/>) }
                        </div>
                    </>
                    : <>
                        <p className="title is-size-2 has-text-centered mt-6">You don't have any buckets yet!</p>
                        <div className="buttons is-centered">
                            <Link className="button is-warning" to="/create">
                                <span>Create Bucket</span>
                            </Link>
                        </div>
                    </>
                }
            </div>
            {/* Create button on top navbar */}
            <Portal node={document.querySelector("#navbar-end-item")}>
                <Link className="button is-primary" to="/create">
                    <span>Create A New Bucket</span>
                </Link>
            </Portal>
        </div>
    )

}