import { useContext, Component } from "react"
import { Navigate, Link, NavLink, Outlet, useLocation } from "react-router-dom"

// Components
import Dropdown from "../common/Dropdown"
// import Calculator from "./Calculator.jsx"
// import { Portal } from "react-portal"

// Contexts
import UserContext from "../context/User.jsx"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown } from "@fortawesome/free-solid-svg-icons"

// Images
import EnvelopeActive from "images/icons/envelope.svg"

import SavingsActive from "images/icons/savings_active.svg"
import { ReactComponent as SavingsActiveComponent } from "images/icons/savings_active.svg"
import { ReactComponent as SavingsInactiveComponent } from "images/icons/savings_inactive.svg"

import BucketActive from "images/icons/bucket_active.svg"
import { ReactComponent as BucketActiveComponent } from "images/icons/bucket_active.svg"
import { ReactComponent as BucketInactiveComponent } from "images/icons/bucket_inactive.svg"

import CashActive from "images/icons/money_active.svg"
import { ReactComponent as CashActiveComponent } from "images/icons/money_active.svg"
import { ReactComponent as CashInactiveComponent } from "images/icons/money_inactive.svg"

import userIcon from "images/userIcon.svg"
import { ReactComponent as UserActiveComponent } from "images/icons/person_active.svg"
import { ReactComponent as UserInactiveComponent } from "images/icons/person_inactive.svg"

import LoanActive from "images/icons/loans_active.svg"
import { ReactComponent as LoanActiveComponent } from "images/icons/loans_active.svg"
import { ReactComponent as LoanInactiveComponent } from "images/icons/loans_inactive.svg"

import { ReactComponent as EnvelopeActiveComponent } from 'images/icons/envelope.svg'
import { ReactComponent as EnvelopeInactiveComponent } from 'images/icons/envelope_inactive.svg'

import CoachPhil from "common/CoachPhil"

import Logo from "images/logo.svg"
import Favicon from "images/favicon.svg"

export function Dashboard() {
    const [user] = useContext(UserContext)

    if (!(user && user.authorized)) {
        return <Navigate to="/login" replace={false} />
    }
    return (
        <DashboardComponent />
    )
}

class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false }
    }
    componentDidCatch() {
        // Display fallback UI
        this.setState({ hasError: true });
    }
    render() {
        return <>
            <SideBar />
            <div className="content-wrapper">
                <Navbar />
                { !this.state.hasError 
                    ? <Outlet />
                    : <>
                        <div className="has-text-centered mt-6 pt-6">
                            <h1 className="title is-size-1 has-text-primary">An error has occured</h1>
                            <CoachPhil message="This will be fixed soon! Thank you for your patience."/>
                        </div>
                    </>
                }
            </div>
        </>
    }
}

// Interface
function SideBar() {

    const location = useLocation()

    return (
        <aside className="sidebar">
            <div className="mobileNav is-hidden-tablet">
                {/* Loans */}
                <NavLink to="/loans" className={({ isActive }) => "nav-item" + (isActive ? " is-active" : "")}>
                    {({ isActive }) => <p className="icon">{isActive ? <LoanActiveComponent /> : <LoanInactiveComponent />}</p>}
                </NavLink>
                {/* Savings */}
                <NavLink to="/savings" className={({ isActive }) => "nav-item" + (isActive ? " is-active" : "")}>
                    {({ isActive }) => <p className="icon">{isActive ? <SavingsActiveComponent /> : <SavingsInactiveComponent />}</p>}
                </NavLink>
                {/* Buckets */}
                <NavLink to="/buckets" className={({ isActive }) => "nav-item" + (isActive ? " is-active" : "")}>
                    {({ isActive }) => <p className="icon">{isActive ? <BucketActiveComponent /> : <BucketInactiveComponent />}</p>}
                </NavLink>
                {/* Stacks of cash */}
                <NavLink to="/cash" className={({ isActive }) => "nav-item" + (isActive ? " is-active" : "")}>
                    {({ isActive }) => <p className="icon">{isActive ? <CashActiveComponent /> : <CashInactiveComponent />}</p>}
                </NavLink>
                {/* Account */}
                {/* <NavLink to="/profile" className={({ isActive }) => "nav-item" + (isActive ? " is-active" : "")}>
                    {({ isActive }) => <p className="icon">{isActive ? <UserActiveComponent /> : <UserInactiveComponent />}</p>}
                </NavLink> */}
                <NavLink to="/challenges" className={({ isActive }) => "nav-item" + (isActive ? " is-active" : "")}>
                    {({ isActive }) => <p className="icon">{isActive ? <EnvelopeActiveComponent /> : <EnvelopeInactiveComponent />}</p>}
                </NavLink>
            </div>
            <div className="menu is-hidden-mobile">
                <a className="image sidebar-brand" href="/buckets">
                    <img src={Logo} alt="Fill Your Buckets" />
                </a>
                <ul className="menu-list">
                    <li>
                        <NavLink to="/loans" className={({ isActive }) => "desktop-sidebar-item " + (isActive ? "is-active" : "")}>
                            <span className="icon is-large">
                                <img src={LoanActive} alt="Loans" />
                            </span>
                            <p>Loans</p>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/savings" className={({ isActive }) => "desktop-sidebar-item " + (isActive ? "is-active" : "")}>
                            <span className="icon is-large">
                                <img src={SavingsActive} alt="Savings" />
                            </span>
                            <p>Savings</p>
                        </NavLink>
                    </li>
                    <li id="bucket-submenu"></li>
                    <li>
                        <NavLink to="/buckets" className={({ isActive }) => "desktop-sidebar-item " + (isActive ? "is-active" : "")}>
                            <span className="icon is-large">
                                <img src={BucketActive} alt="Buckets" />
                            </span>
                            <p>Buckets</p>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/cash" className={({ isActive }) => "desktop-sidebar-item " + (isActive ? "is-active" : "")}>
                            <span className="icon is-large">
                                <img src={CashActive} alt="Stacks Of Cash" />
                            </span>
                            <p>Stacks Of Cash</p>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/challenges" className={"desktop-sidebar-item " + (location && location.pathname.startsWith('/challenge') ? "is-active" : "")}>
                            <span className="icon is-large">
                                <img src={EnvelopeActive} alt="100 Day Challenge" />
                            </span>
                            <p>Challenges</p>
                        </NavLink>
                    </li>
                    <li>
                    { location && location.pathname.startsWith('/challenge')
                        ? <ul className="menu-list">
                            <li><NavLink className={({ isActive }) => "desktop-sidebar-item item" + (isActive?' is-active':'')} to="/challenge/100days">100 Days</NavLink></li>
                            <li><NavLink className={({ isActive }) => "desktop-sidebar-item item" + (isActive?' is-active':'')} to="/challenge/52weeks">52 Weeks</NavLink></li>
                            <li><NavLink className={({ isActive }) => "desktop-sidebar-item item" + (isActive?' is-active':'')} to="/challenge/26weeks">26 Weeks</NavLink></li>
                        </ul>
                        : <></>
                    }
                    </li>
                </ul>
            </div>
        </aside>
    )
}

function Navbar() {

    const location = useLocation()
    const [{ user }] = useContext(UserContext)

    function NameTag() {
        return (
            <div className="nameTag">
                <figure className="image is-48x48">
                    <img className="is-rounded" src={userIcon} alt="Profile" />
                </figure>
                <div className="titles">
                    <h1 className="title is-5">
                        {user.first} {user.last}
                    </h1>
                    <h2 className="subtitle is-7">{user.email}</h2>
                </div>
                <div className="icon">
                    <FontAwesomeIcon icon={faSortDown} />
                </div>
            </div>
        )
    }

    let pagename = "";
    let { pathname } = location
    if(pathname.startsWith('/profile')){
        pagename = "My Profile"
    } else if (pathname.startsWith('/cash')){
        pagename = "Stacks Of Cash"
    } else if (pathname.startsWith('/bucket/')){
        pagename = "Viewing Bucket"
    } else if (pathname.startsWith('/buckets')){
        pagename = "Your Buckets"
    } else if (pathname.startsWith('/loans')){
        pagename = "Your Loans"
    } else if (pathname.startsWith('/loan/')){
        pagename = "Your Loan"
    } else if (pathname.startsWith('/savings')){
        pagename = "Your Savings Buckets"
    } else if (pathname.startsWith('/challenges')){
        pagename = "Challenges"
    } else if (pathname.startsWith('/challenge/100days')){
        pagename = "100 Day Challenge"
    } else if (pathname.startsWith('/challenge/52weeks')){
        pagename = "52 Week Challenge"
    } else if (pathname.startsWith('/challenge/26weeks')){
        pagename = "26 Week Challenge"
    }

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link className="navbar-item is-logo is-hidden-mobile" to="/calculator">
                    <img src={Logo} alt="Fill Your Buckets" />
                </Link>
                <Link className="navbar-item is-logo is-hidden-tablet" to="/calculator">
                    <img src={Favicon} alt="Fill Your Buckets" />
                </Link>
            </div>
            <div className="navbar-menu">
                <div className="navbar-start">
                    <div className="navbar-item">
                        { pagename
                            ? <div className="icon-text is-clickable has-text-black is-hidden-desktop">
                                <span className="is-size-4 m-0 has-text-weight-bold">{pagename}</span>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
                <div className="navbar-end is-hidden-mobile">
                    <div className="navbar-item" id="navbar-end-item"></div>
                    <Dropdown trigger={<NameTag />} isRight>
                        <Link to="/profile" className="dropdown-item">
                            My Account
                        </Link>
                        <hr className="dropdown-divider" />
                        <Link to="/logout" className="dropdown-item">
                            Logout
                        </Link>
                    </Dropdown>
                </div>
                <div className="navbar-end is-hidden-tablet">
                    <Link className="button is-ghost" to="/profile">
                        <span className="icon" style={{ transform: 'scale(1.5)' }}>
                        { location.pathname.startsWith('/profile')
                            ? <UserActiveComponent   />
                            : <UserInactiveComponent />
                        }
                        </span>
                    </Link>
                </div>
            </div>
        </nav>
    )
}

// export function CalculatorWrapper() {
//     return (
//         <section className="section pt-0">
//             <div className="block has-text-centered is-hidden-mobile">
//                 <h1 className="title has-text-black is-size-1">Calculator</h1>
//                 {/* <h2 className="subtitle has-text-primary is-size-4">Enter an amount to create a new bucket</h2> */}
//             </div>
//             <Calculator startFromHistory={false} />
//             <Portal node={document.querySelector("#page-name")}>
//                 <div
//                     className="icon-text is-clickable has-text-black is-hidden-desktop"
//                     onClick={() => {
//                         this.setState({ editor: true })
//                     }}
//                 >
//                     <span className="is-size-4 m-0 has-text-weight-bold">Calculator</span>
//                 </div>
//             </Portal>
//         </section>
//     )
// }
