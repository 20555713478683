import { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

import { Context as BucketContext } from '../Bucket.jsx'

import { AmoritizationCalculator } from "utility/tools.js"

import axios from 'axios'
import moment from 'moment'

export default function CongratsPage({ ...props }){

    // Router
    const navigate = useNavigate();
    const { id } = useParams();

    // Context
    const [ bucket ] = useContext(BucketContext);
    const { loan } = bucket;

    // State
    const [ state, setState ] = useState({
        days_saved: 0,
        interest_saved: 0,
        time_saved: null,
        new_bucket: null,
        loading: false
    })

    useEffect(() => {
        if(!loan){ return }

        let Nexium = loan ? new AmoritizationCalculator(loan) : null,
            days_saved = 0,
            extraPayments = [],
            interest_saved,
            time_saved

        // Refetch the loan, because we need the list of buckets
        axios
            .post('/api/loans/get', { id: loan.id })
            .then(({ data:loan }) => {
                if(!loan){ return; }
                let { buckets=[] } = loan

                buckets.forEach(b => {
                    // if(b.id === bucket.id){ return }
                    extraPayments.push(...b.history)
                })

                // console.log({ extraPayments })

                let [ defaultLoan ] = Nexium.getDailySchedule("default", { onlyReturnLast: true }),
                    [ currentLoan ] = Nexium.getDailySchedule("current", { onlyReturnLast: true, extraPayments })
        
                // console.log({ defaultLoan, currentLoan })

                if (defaultLoan && defaultLoan.running_totals && currentLoan && currentLoan.running_totals) {
                    days_saved = defaultLoan.running_totals.days_paid - currentLoan.running_totals.days_paid
                    interest_saved = defaultLoan.running_totals.interest - currentLoan.running_totals.interest
                }

                if (days_saved) {
                    time_saved = (
                        <>
                            , and you are on track to <strong className="has-text-primary">pay off your loan {moment.duration(days_saved, "days").humanize()} sooner.</strong>
                        </>
                    )
                }

                setState(s => { return {
                    ...s,
                    time_saved,
                    days_saved,
                    interest_saved
                } })
            })
    }, [loan, bucket])

    function createOverflowBucket(){
        setState({ ...state, loading: true })
        axios
            .post('/api/buckets/createoverflow', { id })
            .catch(console.log)
            .then(({ data:new_bucket=null }={}) => {
                if(!new_bucket){ return }
                navigate(`/bucket/${new_bucket.id}`, { replace: false, state: new_bucket })
            })
    }

    return (
        <div className="container is-max-fullhd has-text-centered">
            <div className="fixedAlertFullscreen mx-auto">
                <div className="block">
                    <h1 className="title">Way to go!</h1>
                </div>
                <div className="block">
                    <p className="is-size-5">
                        Congratulations! You finished <br className="is-hidden-mobile" />
                        filling your bucket.
                    </p>
                </div>
                { loan && state.interest_saved 
                    ? <div className="block">
                        <p className="is-size-5">
                            You have <strong className="has-text-primary">saved ${parseFloat(state.interest_saved).toFixed(2)} in interest</strong> on your loan{state.time_saved ? state.time_saved : "!"}
                        </p>
                    </div>
                    : <></>
                }
                <div className="block buttons is-centered">
                    <button className={"button is-primary is-fullwidth" + (state.loading ? " is-loading" : "")} type="button" onClick={() => createOverflowBucket()}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span>Start a new bucket!</span>
                    </button>
                </div>
            </div>
        </div>
    )

}