import { Component, createRef } from "react"
import { Link, Navigate } from "react-router-dom"

import axios from "axios"
import { AmoritizationCalculator } from "../utility/tools.js"
import { pushToActionQue } from "../utility/actionQue.js"

import { FontAwesomeIcon as FontAwesome6 } from "@fortawesome/react-fontawesome"
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons"

import favicon from "../images/favicon.svg"
import artwork from "../images/login-screen-final.png"
import Styles from "../sass/Create.module.sass"
import NumbersOnlyInput from "../common/NumbersOnlyInput"
import moment from "moment"

import { ReactComponent as BucketIcon } from "../images/icons/bucket_for_button.svg"

export default class Create extends Component {

    constructor(props) {
        super(props)

        let { page = "", reason = "", type = "", amount = "", name = "", interest = "", term = 30, term_display = "months", start_years = moment().format("YYYY"), start_months = "january" } = props

        let backHistory = [],
            exitProtocol = this.props.onCancel
        
        if(window.location.search){
            let search = window.location.search
            if(search.includes('s=loan')){
                page = "loantype"
                reason = "Pay off a loan"
                backHistory = ["",]
                exitProtocol = () => { this.setState({ redirect: '/loans' }) }
            }
            if(search.includes('s=savings')){
                page = "savingsOrChallenge"
                name = "My Savings Goal"
                type = "Savings"
                reason = "Save more money"
                backHistory = ["",]
                exitProtocol = () => { this.setState({ redirect: '/savings' }) }
            }
        }

        this.screenX = window && window.innerWidth ? window.innerWidth : 1000;

        this.state = {
            buttonLoading: false,
            redirect: false,
            savingsOrChallenge:'',
            page,
            reason,
            type,
            amount,
            name,
            interest,
            term,
            term_display,
            start_years,
            start_months,
        }

        // Bindings
        this.openingPage = this.openingPage.bind(this)
        this.loanstart = this.loanstart.bind(this)
        this.getamount = this.getamount.bind(this)
        this.onFinish = this.onFinish.bind(this)
        this.loantype = this.loantype.bind(this)
        this.loaninfo = this.loaninfo.bind(this)
        this.interest = this.interest.bind(this)
        this.finish = this.finish.bind(this)

        this.backHistory = backHistory

        // Refs
        this.continueButton = createRef()
        this.exit = exitProtocol
    }

    onFinish() {
        // Get ready to send inputed data back to caller
        let payload = { ...this.state }

        // Cleanup unnecessary data
        delete payload.buttonLoading
        delete payload.page

        // Ensure gaps are filled with assumed data
        if (payload.interest === "") {
            payload.interest = 0
        }

        // Ensure variables are the correct format
        payload.amount = parseFloat(payload.amount)
        payload.total = parseFloat(payload.amount)
        payload.interest = parseFloat(payload.interest)
        payload.start_years = parseInt(payload.start_years)
        payload.term = parseInt(payload.term)

        console.log({ payload })

        // const { name, interest, term, term_display, start_months, start_years, amount:total, type } = payload

        const Calculator = new AmoritizationCalculator(payload)

        payload.regular_payment = parseFloat(
            parseFloat(Calculator.getMonthlyPayment() || 0).toFixed(2)
        )

        // Auth check
        axios
            .post('/api/getSession')
            .then((session={}) => {
                let { data=null } = session
                if(data && data.authorized === true){
                    return axios.post("/api/loans/createFromSetupFlow", payload)
                } else {
                    pushToActionQue({
                        script: "create_loan",
                        data: payload,
                        options: {
                            solo: true, // There can be only one of this script in the que
                        },
                    })
                    return null
                }
            })
            .then((response) => {
                let redirect = '/login'
                if(!response){ return this.setState({ redirect }); }

                let { data:{ loan=null, bucket=null } } = response
                
                if(loan && bucket){
                    redirect = (loan.type === "Savings")
                        ? "/bucket/" + bucket.id 
                        : "/loan/" + loan.id
                }
                this.setState({ redirect })
            })
    }

    openingPage() {
        const content = (
            <div className={"control " + Styles.content}>
                <label className="radio is-huge is-block">
                    <input
                        key="payoff-loan"
                        type="radio"
                        name="reason"
                        onChange={() => {
                            this.setState({ reason: "Pay off a loan" })
                        }}
                        checked={this.state.reason === "Pay off a loan"}
                    />
                    Pay off a loan
                </label>
                <label className="radio is-huge is-block">
                    <input
                        key="save-more-money"
                        type="radio"
                        name="reason"
                        onChange={() => {
                            this.setState({ reason: "Save more money" })
                        }}
                        checked={this.state.reason === "Save more money"}
                    />
                    Save money
                </label>
            </div>
        )

        return {
            title: (
                <>
                    Welcome to
                    <br />
                    Fill Your Buckets!
                </>
            ),
            text: "What do you want to do?",
            onNext: () => {
                if (!this.state.reason) {
                    return
                }
                if (this.state.reason === "Pay off a loan") {
                    this.backHistory.push("")
                    this.setState({ page: "loantype" })
                    return
                }
                this.backHistory.push("")
                this.setState({ page: "savingsOrChallenge", name: "My Savings Goal", type: "Savings" })
            },
            nextDisabled: this.state.reason === "",
            content,
        }
    }

    getamount() {
        const content = (
            <div className="field">
                {/* <label className="label">Total amount you want to save</label> */}
                <div className="control has-icons-left">
                    <NumbersOnlyInput
                        autoFocus={this.screenX > 769}
                        extraClasses="is-medium"
                        type="number"
                        wholeNumbersOnly={this.state.type === "Savings"}
                        placeholder={this.state.type === "Savings" ? "Total amount you want to save" : "Total amount"}
                        value={this.state.amount}
                        onChange={(amount) => this.setState({ amount })}
                        onKeyDown={(e, key) => {
                            if (key === "Enter") {
                                if (this.continueButton.current) {
                                    this.continueButton.current.click()
                                }
                            }
                        }}
                    />
                    <span className="icon is-left">
                        <FontAwesome6 icon={faDollarSign} />
                    </span>
                </div>
            </div>
        )

        const progressbars =
            this.state.type === "Savings" ? (
                <div className={Styles.progressChunks}>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress}></div>
                </div>
            ) : this.state.interest === "" ? (
                <div className={Styles.progressChunks}>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress}></div>
                </div>
            ) : (
                <div className={Styles.progressChunks}>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress}></div>
                </div>
            )

        const title = this.state.type === "Home loan" ? "Awesome, you have a house!" : this.state.type === "Car loan" ? "Awesome, you have a car!" : "Great, you have a loan!"

        return {
            title: this.state.reason === "Save more money" ? "Great job saving money!" : title,
            text: this.state.reason === "Save more money" ? "By entering an amount you want to save, we will help you track it by creating a bucket." : "What's your loan amount?",
            onNext: () => {
                if (!this.state.amount) {
                    return
                }
                if (this.state.reason === "Save more money") {
                    this.backHistory.push("getamount")
                    return this.setState({ page: "finish" })
                }
                this.backHistory.push("getamount")
                this.setState({ page: "loaninfo" })
            },
            progressbars,
            nextDisabled: this.state.amount === "",
            content,
        }
    }

    finish() {
        const title = this.state.type === "Savings" ? "Let's give your bucket a name!" : this.state.type === "Home loan" ? "Let's give your home loan a name" : this.state.type === "Car loan" ? "Let's give your car loan a name" : "Let's give your loan a name"

        const placeholder = this.state.type === "Savings" ? "Vacation, College, Car, Wedding, Etc." : this.state.type === "Home loan" ? "Let's give your home loan a name!" : this.state.type === "Car loan" ? "Let's give your car loan a name!" : "Let's give your loan a name!"

        const content = (
            <div className="field">
                {/* <label className="label">Bucket Name</label> */}
                <div className="control has-icons-left">
                    <input
                        autoFocus={this.screenX > 769}
                        className="input is-primary is-medium"
                        type="text"
                        // placeholder="Bucket Name"
                        placeholder={placeholder}
                        value={this.state.name}
                        onChange={(event) => {
                            this.setState({ name: event.target.value })
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                if (this.continueButton.current) {
                                    this.continueButton.current.click()
                                }
                            }
                        }}
                    />
                    <span className="icon is-left">
                        {/* <FontAwesome6 icon={faLandmark}/> */}
                        <BucketIcon />
                    </span>
                </div>
            </div>
        )

        const progressbars =
            this.state.type === "Savings" ? (
                <div className={Styles.progressChunks}>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                </div>
            ) : this.state.interest === "" ? (
                <div className={Styles.progressChunks}>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress}></div>
                </div>
            ) : (
                <div className={Styles.progressChunks}>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                    <div className={Styles.progress + " " + Styles.isActive}></div>
                </div>
            )

        return {
            title,
            text: this.state.type === "Savings" ? "A bucket lets you track the progress you make when you start adding to it. It's a great way to see how much you have saved." : "",
            onNext: () => {
                if (!this.state.name) {
                    return
                }

                this.backHistory.push("finish")
                this.setState({ buttonLoading: true }, this.onFinish)
            },
            nextDisabled: this.state.name === "",
            progressbars,
            content,
        }
    }

    loantype() {
        const onChange = (type) => {
            this.setState({ type })
        }

        const content = (
            <div className={"control " + Styles.content}>
                <label className="radio is-huge is-block">
                    <input key="Home loan" type="radio" name="loantype" onChange={() => onChange("Home loan")} checked={this.state.type === "Home loan"} />
                    Home Loan
                </label>
                <label className="radio is-huge is-block">
                    <input key="Car loan" type="radio" name="loantype" onChange={() => onChange("Car loan")} checked={this.state.type === "Car loan"} />
                    Car Loan
                </label>
                {/* <label className="radio is-huge is-block">
                <input
                    key="Student loan"
                    type="radio"
                    name="loantype"
                    onChange={() => onChange('Student loan')}
                    checked={this.state.type === 'Student loan'}
                />
                Student Loan
            </label> */}
                <label className="radio is-huge is-block">
                    <input key="Other" type="radio" name="loantype" onChange={() => onChange("Other")} checked={this.state.type === "Other"} />
                    Other
                </label>
            </div>
        )

        return {
            title: (
                <span>
                    What kind of loan
                    <br />
                    do you have?
                </span>
            ),
            text: "",
            onNext: () => {
                if (!this.state.type) {
                    return
                }
                this.backHistory.push("loantype")
                this.setState({
                    page: "getamount",
                    name: this.state.type,
                    term_display: ["Car loan", "Other"].includes(this.state.type) ? "months" : "years",
                    term: ["Car loan", "Other"].includes(this.state.type) ? "36" : "30",
                })
            },
            nextDisabled: this.state.type === "",
            content,
        }
    }

    loaninfo() {
        const content = (
            <div className="field has-addons">
                <div className="control">
                    <NumbersOnlyInput
                        autoFocus={true}
                        key="loan-term"
                        extraClasses="is-medium"
                        type="number"
                        placeholder=""
                        value={this.state.term}
                        onChange={(term) => {
                            this.setState({ term })
                        }}
                        onKeyDown={(e, key) => {
                            if (key === "Enter") {
                                if (this.continueButton.current) {
                                    this.continueButton.current.click()
                                }
                            }
                        }}
                    />
                </div>
                <div className="control">
                    <div className="select is-medium is-primary">
                        <select
                            defaultValue={this.state.term_display}
                            onChange={(e) => {
                                this.setState({ term_display: e.target.value })
                            }}
                        >
                            <option value="years">Years</option>
                            <option value="months">Months</option>
                        </select>
                    </div>
                </div>
            </div>
        )

        const progressbars = (
            <div className={Styles.progressChunks}>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress}></div>
                <div className={Styles.progress}></div>
                <div className={Styles.progress}></div>
                <div className={Styles.progress}></div>
            </div>
        )

        const title = this.state.type === "Home loan" ? "What's the term of your home loan?" : this.state.type === "Car loan" ? "What's the term of your car loan?" : "What's the term of your loan?"

        return {
            title,
            text: "",
            onNext: () => {
                if (!this.state.term) {
                    return
                }
                if (this.state.type === "Home loan") {
                    this.backHistory.push("loaninfo")
                    this.setState({ page: "interest", term_display: "years" })
                }
                this.backHistory.push("loaninfo")
                this.setState({ page: "interest" })
            },
            nextDisabled: this.state.term === "",
            progressbars,
            content,
        }
    }

    interest() {
        const content = (
            <div className="field">
                <div className="control has-icons-right">
                    <NumbersOnlyInput
                        key="interest"
                        autoFocus={this.screenX > 769}
                        extraClasses="is-medium"
                        type="number"
                        placeholder="5.59"
                        value={this.state.interest}
                        onChange={(interest) => {
                            this.setState({ interest })
                        }}
                        onKeyDown={(e, key) => {
                            if (key === "Enter") {
                                if (this.continueButton.current) {
                                    this.continueButton.current.click()
                                }
                            }
                        }}
                    />
                    <span className="icon is-right">
                        <FontAwesome6 icon={faPercent} />
                    </span>
                </div>
            </div>
        )

        const progressbars = (
            <div className={Styles.progressChunks}>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress}></div>
                <div className={Styles.progress}></div>
                <div className={Styles.progress}></div>
            </div>
        )

        return {
            title: "What's your interest rate?",
            text: "If you don't know you can look on your bank statement.",
            onNext: () => {
                if (!this.state.interest) {
                    return
                }
                this.backHistory.push("interest")
                this.setState({ page: "loanstart" })
            },
            nextDisabled: this.state.interest === "",
            progressbars,
            content,
        }
    }

    loanstart() {
        const years = []
        for (let i = 0; i <= 50; i++) {
            years.push(moment().subtract(i, "years").format("YYYY"))
        }

        const content = (
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <div className="select is-medium is-fullwidth">
                                <select
                                    defaultValue="january"
                                    onChange={(e) => {
                                        this.setState({ start_months: e.target.value })
                                    }}
                                >
                                    <option value="january">January </option>
                                    <option value="february">February </option>
                                    <option value="march">March </option>
                                    <option value="april">April </option>
                                    <option value="may">May </option>
                                    <option value="june">June </option>
                                    <option value="july">July </option>
                                    <option value="august">August </option>
                                    <option value="september">September</option>
                                    <option value="october">October </option>
                                    <option value="november">November </option>
                                    <option value="december">December </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <div className="control is-expanded">
                            <div className="select is-medium is-fullwidth">
                                <select
                                    defaultValue={moment().format("YYYY")}
                                    onChange={(e) => {
                                        this.setState({ start_years: parseInt(e.target.value) })
                                    }}
                                >
                                    {years.map((year) => {
                                        return (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        const progressbars = (
            <div className={Styles.progressChunks}>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress + " " + Styles.isActive}></div>
                <div className={Styles.progress}></div>
                <div className={Styles.progress}></div>
            </div>
        )

        return {
            title: "When was your first payment?",
            text: "",
            onNext: () => {
                if (!this.state.start_years || !this.state.start_months) {
                    return
                }
                this.backHistory.push("loanstart")
                this.setState({ page: "finish" })
            },
            nextDisabled: this.state.start_years === "" || this.state.start_months === "",
            progressbars,
            content,
        }
    }

    savingsOrChallenge(){
        const content = (
            <div className={"control " + Styles.content}>
                <label className="radio is-huge is-block">
                    <input
                        key="select-savings"
                        type="radio"
                        name="savingsOrChallenge"
                        onChange={() => {
                            this.setState({ savingsOrChallenge: "savings" })
                        }}
                        checked={this.state.savingsOrChallenge === "savings"}
                    />
                    Create a savings goal
                </label>
                <label className="radio is-huge is-block">
                    <input
                        key="select-challenge"
                        type="radio"
                        name="savingsOrChallenge"
                        onChange={() => {
                            this.setState({ savingsOrChallenge: "challenge" })
                        }}
                        checked={this.state.savingsOrChallenge === "challenge"}
                    />
                    Do a challenge
                </label>
            </div>
        )

        return {
            title: (
                <>
                    Do you want to create a savings goal or do you want to do a challenge?
                </>
            ),
            text: "What do you want to do?",
            onNext: () => {
                if (!this.state.savingsOrChallenge) {
                    return
                }
                if(this.state.savingsOrChallenge === 'challenge'){
                    return this.setState({ redirect:'/100days' })
                }
                this.backHistory.push("savingsOrChallenge")
                this.setState({ page: "getamount" })
            },
            nextDisabled: this.state.savingsOrChallenge === "",
            content,
        }
    }

    render() {
        let { page } = this.state

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} replace={false} />
        }

        let data

        if (page === "") {
            data = this.openingPage()
        } else if (page === "loantype") {
            data = this.loantype()
        } else if (page === "loaninfo") {
            data = this.loaninfo()
        } else if (page === "interest") {
            data = this.interest()
        } else if (page === "loanstart") {
            data = this.loanstart()
        } else if (page === "getamount") {
            data = this.getamount()
        } else if (page === "savingsOrChallenge") {
            data = this.savingsOrChallenge()
        } else if (page === "finish") {
            data = this.finish()
        } else if (page === "exit") {
            if(this.exit){
                return this.exit()
            }
            page = ""
            data = this.openingPage()
        }

        return (
            <div className={Styles.Create}>
                <div className={Styles.main}>
                    <div className="block is-hidden-touch">
                        <Link className="image is-48x48" to="/loans">
                            <img src={favicon} alt="" />
                        </Link>
                    </div>
                    <div className={Styles.wrapper}>
                        <div className="block">{data && data.progressbars ? data.progressbars : <></>}</div>
                        <div className="block">
                            <h1 className="title is-size-2 is-size-3-mobile has-text-info">{data && data.title ? data.title : ""}</h1>
                        </div>
                        <div className="block">
                            <p className="is-size-4 is-size-6-mobile">{data && data.text ? data.text : ""}</p>
                        </div>
                        <div className={"block"}>{data && data.content ? data.content : ""}</div>
                        <div className="block buttons mt-6 is-left">
                            {page === "" ? (
                                this.props.onCancel ? (
                                    <button className="button is-medium is-normal-mobile is-light" type="button" onClick={this.props.onCancel}>
                                        {" "}
                                        <span>Cancel</span>{" "}
                                    </button>
                                ) : null
                            ) : (
                                <button
                                    className="button is-medium is-normal-mobile is-light"
                                    type="button"
                                    onClick={() => {
                                        let page = this.backHistory.pop() || "exit"
                                        this.setState({ page })
                                    }}
                                >
                                    {" "}
                                    <span>Back</span>{" "}
                                </button>
                            )}
                            <button ref={this.continueButton} className={"button is-medium is-normal-mobile is-primary" + (this.state.buttonLoading ? " is-loading" : "")} type="button" onClick={data && data.onNext ? data.onNext : () => {}} disabled={data && data.nextDisabled ? data.nextDisabled : false}>
                                <span>Continue</span>
                            </button>
                        </div>
                        {
                            this.props.showLogin // && page === ''
                                ? <p className="has-text-centered is-hidden-tablet">Already have an account? <Link to="/login">
                                    <span>Login</span>
                                </Link></p>
                                : <></>
                        }
                    </div>
                </div>
                <div
                    className={Styles.artwork}
                    style={{ backgroundImage: `url(${artwork})` }}
                    onClick={() => {
                        console.log(this.state)
                    }}
                >
                    {this.props.showLogin ? (
                        <div className="block buttons is-right p-5 is-hidden-touch">
                            <Link className="button is-light mb-0 pad-bottom" to="/login">
                                <span>Login</span>
                            </Link>
                            <Link className="button is-info mb-0 pad-bottom" to="/signup">
                                <span>Signup</span>
                            </Link>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        )
    }

}
