import { createContext, useState } from "react"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from "firebase/firestore"

const config = {
    apiKey: "AIzaSyD5FduKu9E6x77uFNoBqTNiiLTDA4Gae9w",
    authDomain: "fill-your-buckets.firebaseapp.com",
    projectId: "fill-your-buckets",
    storageBucket: "fill-your-buckets.appspot.com",
    messagingSenderId: "1070808657033",
    appId: "1:1070808657033:web:744ee0aac6dd710f412a58",
    measurementId: "G-YVEWZY275L",
}

const app = initializeApp(config)
const analytics = getAnalytics(app)
const database = getFirestore(app)

const Context = createContext()

export function FireBaseProvider({ children }) {
    const [state] = useState({
        app,
        config,
        analytics,
        database,
    })
    return <Context.Provider value={state}>{children}</Context.Provider>
}

export default Context

// export function FireBase({ children }){
//     return (
//         <Context.Consumer>
//             { children }
//         </Context.Consumer>
//     )
// }
