export function getQue(){
    return JSON.parse(
        (sessionStorage.getItem('actionQues') || "[]")
    );
}
export function saveQue(que=[]){
    sessionStorage.setItem('actionQues', JSON.stringify(que))
}

export function pushToActionQue(item){
    // {
    //     script:"create_bucket",
    //     data:{
    //         bucket_amount:this.state.input
    //     },
    //     options:{
    //         solo:true
    //     }
    // }
    let que = getQue()

    if(item){
        if(!item.script){ throw new Error("[ERROR] => ActionQues :: No script included in item") }
        if(item.options && item.options.solo){
            que = que.filter(i => i.script !== item.script)
        }
        que.push(item)
    }

    saveQue(que)
}