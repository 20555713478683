import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

import axios from 'axios'

export default function PromptDelete({ bucket, close }){

    return (<div className="modal is-active">
        <div className="modal-background" onClick={() => close()}></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Delete Bucket</p>
                <button className="delete" onClick={() => close()} />
            </header>
            <section className="modal-card-body">
                <p>
                    Are you sure you want to delete this bucket? <br />
                    <strong>This can't be undone!</strong>
                </p>
            </section>
            <footer className="modal-card-foot buttons is-right">
                <button className="button" type="button" onClick={() => close()}>
                    <span>Cancel</span>
                </button>
                <button className="button is-danger" type="button" onClick={() => {
                    axios.post(`/api/buckets/delete`, { id: bucket.id }).catch(console.log);
                    close(true)
                }}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                    <span>Delete</span>
                </button>
            </footer>
        </div>
    </div>)

}