import { useState, useContext } from "react"
import { useParams } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons"

import { Context as BucketContext } from '../Bucket.jsx'

import NumbersOnlyInput from "common/NumbersOnlyInput.jsx"

import { nanoid } from 'nanoid'
import axios from "axios"

export default function AddHistory({ close, ...props }){

    // Context
    const [ bucket, setBucket ] = useContext(BucketContext)
    const { id } = useParams()

    // State
    const [ input, setInput ] = useState('')

    function addItem(){
        let value = parseFloat(parseFloat(input).toFixed(2))

        let history = [ ...bucket.history ]
        history.push({ id: nanoid(9), value, date: new Date().toISOString() })

        let { total } = bucket;

        let new_completed = history.reduce((prev, curr) => prev += parseFloat(curr.value), 0)

        let completion = parseInt((new_completed / total) * 100)
        if (completion > 100) { completion = 100 }
    
        let remaining = parseFloat(
            parseFloat(
                Math.abs(new_completed - total)
            ).toFixed(2)
        )

        setBucket({ id, history, completion, remaining, completed: new_completed })
        axios.post('/api/buckets/edit', { id, history, completed: new_completed })
        close()
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={close}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Add New Payment</p>
                    <button className="delete is-medium" aria-label="close" onClick={close}></button>
                </header>
                <section className="modal-card-body">
                    <div className="field">
                        <label className="label">Principal Payment</label>
                        <div className="control has-icons-left">
                            <NumbersOnlyInput
                                autoFocus
                                value={input}
                                onChange={(val) => setInput(val)}
                                onKeyDown={(value, key) => { if (key === "Enter") { addItem() }}}
                                placeholder="New Value"
                            />
                            <span className="icon is-left">
                                <FontAwesomeIcon icon={faDollarSign} />
                            </span>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot buttons is-right">
                    <button className="button is-light" onClick={close}>
                        <span>Cancel</span>
                    </button>
                    <button
                        className="button is-primary"
                        onClick={() => addItem()}
                    >
                        <span>Add Payment</span>
                    </button>
                </footer>
            </div>
            {/* { submenu_sidebar } */}
        </div>
    )

}