import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faUndo } from "@fortawesome/free-solid-svg-icons"

import UserContext from 'context/User'

import useSound from "use-sound"
import envelope_sound from "audio/Envelope.ogg"
import axios from "axios"

import Loader from "common/Loader.jsx"
import DifficultySelector from "./Pages/DifficultySelector"

import BucketSVGBasic from "routes/Buckets/Components/BucketSVGBasic"

import Styles from './_.module.sass'

function getTotal(data){
    let total = Object
        .values(data)
        .reduce((prev, curr) => (curr.checked?curr.value:0) + prev, 0)

    return total
}

function shuffle(array) {
    let currentIndex = array.length, randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

function initializeData(difficulty, amount){
    let data = {}

    if(!difficulty || !amount){ return data }

    let l = []
    for (let i = 0; i < 26; i++) { l.push(i) }
    
    if(difficulty === 'medium'){
        l = shuffle(l)
    }
    else if(difficulty === 'hard'){
        l = l.reverse()
    }

    l.forEach((i, index) => {
        data[index] = { value: (amount * (i+1)) }
    })

    return data;
}

export default function YearChallenge({ ...props }) {

    const [ context ] = useContext(UserContext)
    const [state, setState] = useState({ data: null, total: 0 })

    const [play_envelope_sound] = useSound(envelope_sound)

    // Get the data!
    useEffect(() => {
        axios
            .post("/api/challenges/challenge_26_weeks/get")
            .catch(console.log)
            .then(({ data:payload }={}) => {
                if(!payload || !payload.data){ return; }

                let { data={} } = payload

                // This means that the data needs initialized!
                if(Object.keys(data).length === 0){
                    data = initializeData(payload.difficulty, payload.amount)
                }

                setState((state) => ({ ...state, ...payload, data }))
            })
    }, [])

    if (!state.data) { return <Loader /> }

    // First the user selects the amount
    if(state.amount === 0){
        return <DifficultySelector mode="amount" onSelect={(amount) => {
            let data = initializeData(state.difficulty, amount)
            setState(s => { return { ...s, data, amount, week:0 } });
            axios.post('/api/challenges/challenge_26_weeks/edit', { data, amount, week:0 })
        }}/>
    }

    // Then they select the difficulty level
    if(state.difficulty === ''){
        return <DifficultySelector mode="difficulty" amount={state.amount} onSelect={(difficulty) => {
            let data = initializeData(difficulty, state.amount);
            setState(s => { return { ...s, data, difficulty, week:0 } });
            axios.post('/api/challenges/challenge_26_weeks/edit', { data, difficulty, week:0 })
        }}/>
    }

    function next(restart=false, goBack=false){
        play_envelope_sound()
        let { data={}, week=0, difficulty='', amount=0 } = state;

        if(restart){
            week = 0
            difficulty = ''
            amount = 0
            data = {} // initializeData(state.difficulty, state.amount);
        }
        else if (goBack){
            data[week - 1].checked = true
            data[week - 1].date    = ''
            week = week - 1
        } else {
            data[week].checked = true
            data[week].date    = new Date().toISOString()
            week = week + 1
        }
        
        setState({ ...state, data, week, difficulty, amount })
        axios
            .post('/api/challenges/challenge_26_weeks/edit', { data, restart, week, difficulty, amount})
            .then(({ data:latest_doc }) => {
                if(latest_doc && latest_doc.linked_bucket && !state.linked_bucket){
                    setState(s => { return { ...s, linked_bucket: latest_doc.linked_bucket, linked_goal: latest_doc.linked_goal } })
                }
            })
            .catch(console.log)
    }

    const { data, week, difficulty } = state;
    const total = getTotal(data)

    // Finished screen ("Congratulations")
    if(week === 26){
        return <section className={`section ${Styles.ChallengeComplete}`}>
            <div className="container is-max-fullhd mb-6 pb-6">
                <div className="block has-text-centered">
                    <h1 className="title is-size-2 is-size-4-mobile has-text-weight-normal">Congratulations {context.user.first},</h1>
                    <h2 className="subtitle is-size-1 is-size-3-mobile has-text-weight-bold" onClick={() => console.log(state)}>
                        You saved: <span className="has-text-primary"><span className="underlined">${total}</span>!</span>
                    </h2>
                </div>
                <Link className={`block ${Styles.BucketPreview}`} to={`/bucket/${state.linked_bucket}`}>
                    <BucketSVGBasic index={1} bucket_name={"26 Week Challenge"} completion={100}/>
                </Link>
                <div className="block buttons is-centered">
                    <button className="button is-medium is-normal-mobile is-warning" type="button" onClick={() => next(true)}>
                        <span>Start A New Challenge</span>
                    </button>
                </div>
            </div>
        </section>
    }

    return (
        <div className="container is-max-fullhd mb-6 pb-6">
            <div className="block columns is-vcentered pt-4 mt-2">
                <div className="column is-6 is-offset-3">
                    <div className="block has-text-centered">
                        <h1 className="has-text-weight-bold is-size-2 is-size-3-mobile">Add ${data[week] ? data[week].value : ''} this week</h1>
                    </div>
                    { week !== 0
                        ? <div className={`block box has-text-centered ${Styles.WeekBox}`}>
                            <p className="is-size-2 is-size-3-mobile">Week:</p>
                            <p className={`has-text-primary has-text-weight-bold ${Styles.WeekSlot}`}>{ week+1 }</p>
                            <p className="is-size-3 is-size-5-mobile is-capitalized">({difficulty} difficulty)</p>
                        </div>
                        : <></>
                    }
                    <div className="block buttons is-centered">
                        <button className="button is-primary is-medium is-normal-mobile pad-bottom mb-0" type="button" onClick={() => next()}>
                        { week === 0
                            ? <span>Start Challenge</span>
                            : week === 25
                            ? <span>Finish Challenge</span>
                            : <span>Mark As Complete</span>
                        }
                        <span className="icon">
                            <FontAwesomeIcon icon={faCheck}/>
                        </span>
                    </button>
                    { week > 0
                        ? <button className="button is-info is-outlined is-medium is-normal-mobile is-fullwidth-mobile pad-bottom mb-0" type="button" onClick={() => next(false, true)}>
                            <span>Go Back</span>
                            <span className="icon">
                                <FontAwesomeIcon icon={faUndo}/>
                            </span>
                        </button>
                        : <></>
                    }
                    </div>
                    { week !== 0
                        ? <h1 className="title is-size-2 is-size-4-mobile has-text-centered" onClick={() => console.log(state)}>
                            Current Total <span className="has-text-primary">${total}</span>
                        </h1>
                        : <></>
                    }
                </div>
            </div>
        </div>
    )
// }

}
