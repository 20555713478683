import Styles from '../_.module.sass'

export default function DifficultySelector({ mode="difficulty", amount=0, onSelect, ...props }){

    if(mode === "difficulty"){
        return (<section className="section">
            <div className="container is-max-fullhd">
                <h1 className="title has-text-centered is-size-2 is-size-5-mobile pt-4">Select Your Difficulty:</h1>
                <div className={`block columns ${Styles.DifficultySelector}`}>
                    
                    <div key="easy" className="column">
                        <div className="box has-text-centered is-clickable" onClick={() => onSelect('easy')}>
                            <h1 className="title is-size-2 is-size-4-mobile is-size-5-tablet-only">Easy</h1>
                            <p className="is-size-5 is-size-6-mobile">Start with ${amount * 1} per week, then ${amount * 2}, then ${amount * 3}...</p>
                        </div>
                    </div>
                    <div key="medium" className="column">
                        <div className="box has-text-centered is-clickable" onClick={() => onSelect('medium')}>
                            <h1 className="title is-size-2 is-size-4-mobile is-size-5-tablet-only">Medium</h1>
                            <p className="is-size-5 is-size-6-mobile">Every week will be a random amount to choose from</p>
                        </div>
                    </div>
                    <div key="hard" className="column">
                        <div className="box has-text-centered is-clickable" onClick={() => onSelect('hard')}>
                            <h1 className="title is-size-2 is-size-4-mobile is-size-5-tablet-only">Hard</h1>
                            <p className="is-size-5 is-size-6-mobile">Start at ${amount * 26} per week, then ${amount * 25}, then ${amount * 24}</p>
                        </div>
                    </div>
                    <div key="invisible" className="column is-hidden-tablet">
                        
                    </div>
                    
                </div>
            </div>
        </section>)
    }

    return (<section className="section">
        <div className="container is-max-fullhd">
            <h1 className="title has-text-centered is-size-2 is-size-5-mobile pt-4">Select Your Amount:</h1>
            <div className={`block columns ${Styles.DifficultySelector}`}>
                
                <div key="1" className="column">
                    <div className="box has-text-centered is-clickable" onClick={() => onSelect(1)}>
                        <h1 className="title is-size-2 is-size-3-mobile">$1</h1>
                    </div>
                </div>
                <div key="5" className="column">
                    <div className="box has-text-centered is-clickable" onClick={() => onSelect(5)}>
                        <h1 className="title is-size-2 is-size-3-mobile">$5</h1>
                    </div>
                </div>
                <div key="10" className="column">
                    <div className="box has-text-centered is-clickable" onClick={() => onSelect(10)}>
                        <h1 className="title is-size-2 is-size-3-mobile">$10</h1>
                    </div>
                </div>
                <div key="20" className="column">
                    <div className="box has-text-centered is-clickable" onClick={() => onSelect(20)}>
                        <h1 className="title is-size-2 is-size-3-mobile">$20</h1>
                    </div>
                </div>
                
            </div>
        </div>
    </section>)

}