export function prettyNumber(value){
    value = String(value)

    // Remove ending with periods
    if (value.endsWith(".")) {
        value = value.slice(0, -1)
    }
    // Ensure only numbers and periods
    // https://stackoverflow.com/a/57440813/9951599
    value = String(value).replace(/[^0-9.]/g, "")

    try {
        value = parseFloat(value)
    } catch (e) {
        value = 1.0
    }
    if (value === 0) {
        value = 0.0
    }

    value = value.toFixed(2)

    return value
}