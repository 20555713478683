import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export default function PromptDelete({ close, finish, title="Delete", finishText="Delete Loan", message="Are you sure you want to delete this loan?", ...props }){

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={close}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <button className="delete is-medium" onClick={close}></button>
                </header>
                <section className="modal-card-body">
                    <div className="block has-text-centered">
                        <p className="is-size-5">{message}</p>
                        <p className="is-size-5">
                            <strong>This can't be undone!</strong>
                        </p>
                    </div>
                </section>
                <footer className="modal-card-foot buttons is-right">
                    <button className="button" type="button" onClick={close}>
                        <span>Cancel</span>
                    </button>
                    <button className="button is-danger" type="button" onClick={finish}>
                        <span>{finishText}</span>
                        <span className="icon">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                    </button>
                </footer>
            </div>
        </div>
    )

}